import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getThemeColor,getSecondaryThemeColor } from "../../../components/src/CommonPlatte";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start

  accountProfileStatusModal: boolean;
  clientTab: boolean;
  usersAccountData: any[];
  types:string[];
  isChecked:boolean;
  token:string;
  error:boolean,
  clientName:string,
  clientId:string,
  contactNo:string,
  addressL1:string,
  addressL2:string,
  email: string,
  account: string,
  accountstatus: string,
  accountNumber: string,
  accountDate: string,
  idNumber:string,
  dob:string,
  primaryColor:string;
  secondaryColor:string;
  showAlert:boolean,
  alertMessage:string,
  showFeildDialog:boolean,
  showContactDialog:boolean,
  redirectToLogin:boolean,

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PeopleWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUsersAccoountAPI: any;
  deleteUserAccountAPI: any;
  filterAccountByAPI: any;
  showClientDataAPI: any;
  getClientListingAPI: any;
  createClientContactAPI:any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationProfileMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  const tabtypes=["Progress Tracker","Documents","Communication","Invoices","Notes","Projects"]
  const someData = JSON.parse(localStorage.getItem("loginData")||"{}");
  const token = someData.token;

    this.state = {
      // Customizable Area Start

      usersAccountData: [],

      accountProfileStatusModal: false,
      clientTab: true,
      types:tabtypes,
      token:token,
      isChecked:false,
      clientName:"",
      error:false,
      clientId:"",
      contactNo:"",
      addressL1:"",
      primaryColor:getThemeColor(),
      secondaryColor:getSecondaryThemeColor(),
      addressL2:"",
      email: "",
      account: "",
      accountstatus: "",
      accountNumber: "",
      accountDate: "",
      idNumber:"",
      dob:"",
      showAlert:false,
      alertMessage:"",
      showFeildDialog:false,
      showContactDialog:false,
      redirectToLogin:false

    };
  }

  async componentDidUpdate() {
    if(this.state.showAlert){
      setTimeout(()=>{
        this.setState({showAlert:false})
      },4000)
    }    
  }

handleToggleChange = () => {
  this.setState({isChecked:!this.state.isChecked});
};
openFeildDialog=()=>{
  this.setState({showFeildDialog:true})
}
closeFeildDialog=()=>{
  this.setState({showFeildDialog:false})
}
openContactDialog=()=>{
  this.setState({showContactDialog:true})
}
closeContactDialog=()=>{
  this.setState({showContactDialog:false})
}
////////////////


  getClientListing = async () => {
    this.getClientListingAPI = await this.apiCall({
      method: "GET",
      endPoint: `/account_block/contact_us`,
      token: this.state.token,
    });
  };

  handleClientClick=()=> {
    this.setState({ redirectToLogin: true });
  }
  
  formDataFormat = (values: any) => {
    const formData = new FormData();
    const formattedDate = new Date(values.dateofBirth).toLocaleDateString("en-us");
    const [month, day, year] = formattedDate.split('/');
    const europeanFormat = `${day}-${month}-${year}`;

    formData.append(`contact_us[full_name]`, values.fullName)
    formData.append(`contact_us[contact_name]`, values.contactName)
    formData.append(`contact_us[contact_number]`, values.contactNumber)
    formData.append(`contact_us[company_name]`, values.companyName)
    formData.append(`contact_us[dob]`, europeanFormat)
    formData.append(`contact_us[email]`, values.emailId)
    formData.append(`contact_us[address]`, values.address)

    return formData;
  }
  createClientContact = async (values:any) => {
    const formValues= this.formDataFormat(values)
    this.createClientContactAPI = await this.apiCall({
      method: "POST",
      endPoint: `/account_block/contact_us`,
      body:formValues,
      token: this.state.token,
    });
  };
  showClientData = async (id:any) => {
    this.showClientDataAPI = await this.apiCall({
      method: "GET",
      endPoint: `/account_block/account_creations/${id}`,
      token: this.state.token,
    });
  };
  
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestId === this.getClientListingAPI) {
        this.setState({ usersAccountData: responseJson.data });
      }
      if (apiRequestId === this.createClientContactAPI) {
        if(!responseJson.meta){
          this.setState({alertMessage:responseJson.messages,error:false,showContactDialog:false,showAlert: true})
          this.getClientListing()
        }else{
          this.setState({alertMessage:responseJson.meta.errors,showContactDialog:false,error:true,showAlert: true})
        }
      }
      if(apiRequestId === this.showClientDataAPI){
        if(responseJson){
          const clientDetails = await responseJson.data.attributes;
          this.setState({
            clientId:responseJson.data.id,
            clientName:clientDetails.full_name,
            contactNo:clientDetails?.contactNo,
            addressL1:clientDetails?.address,
            addressL2:clientDetails?.address,
            account:clientDetails?.address,
            accountstatus: clientDetails?.accountstatus,
            accountNumber: clientDetails?.accountNumber,
            accountDate: clientDetails?.accountDate,
            idNumber:clientDetails?.idNumber,
            dob:clientDetails?.dob,
            email:clientDetails.email
          });
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    if (getName(MessageEnum.NavigationProfileMessage) === message.id) {
        const params = message.getData(
          getName(MessageEnum.NavigationProfileMessage)
        );
        localStorage.setItem('ClientID', params)
            this.showClientData(params)
      }

    // Customizable Area End
  }


  // Customizable Area End
}