// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getSecondaryThemeColor, getThemeColor } from "../../../components/src/CommonPlatte";


export const baseURL = require("../../../framework/src/config.js").baseURL;

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCancel: () => void;
  handleOk: () => void;
  headingText: string;
  subHeading: string;
  btnOkText: string;
  classes: any;
}

interface S {
  token: string;
  filesWeb: File[];
  files: any[];
  formikFiles: any[];
  uploadedFileList: any[];
  filesStatus: string;
  btnText: string;
  heading: string;
  clientEmailId:string;
  subHeading: string;
  showPopUp: boolean;
  btnColor: string;
  textColor: string;
  openDialog: boolean;
  showAlert:boolean;
  showInput:boolean;
  userChipSelected:string;
  alertMessage:string;
  errors:boolean;
  dialog: boolean;
  userType:string;
  emailError:string;
  emailErrorShow:boolean;
}

interface SS {
  id: any;
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  updateUserDataApi: any;
  uploadFileDataApi: any;
  headingText: string;
  tokens: string;
  btnOkText: string;
  btnCancelText: string;
  maxFileSize = 5e6;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [

      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationBulkUploadMessage)

    ];
    const storedData = localStorage.getItem("loginData");
    const userToken = storedData ? JSON.parse(storedData) : null;

    this.headingText = configJSON.headingText;
    this.btnOkText = configJSON.btnOkText;
    this.btnCancelText = configJSON.btnCancelText;
    this.tokens = userToken?.token
    this.state = {

      token: "",
      filesWeb: [],
      files: [],
      formikFiles: [],
      filesStatus: "",
      userType:userToken?.user_type,
      showInput:false,
      showPopUp: false,
      openDialog: true,
      btnColor: getThemeColor(),
      textColor: getSecondaryThemeColor(),
      dialog: true,
      userChipSelected:"",
      clientEmailId:"",
      btnText: "",
      heading: "",
      subHeading: "",
      emailError:"",
      emailErrorShow:false,
      showAlert:false,
      alertMessage:"",
      errors:false,
      uploadedFileList: []

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.uploadFileDataApi) {
        if (responseJson.data) {
          this.setState({ filesStatus: "success", openDialog: false, showPopUp: true, heading: "Successful", subHeading: "File upload successful", btnText: "Ok" });
        } else {
          this.setState({ filesStatus: "failed", openDialog: false,clientEmailId:"", showPopUp: true, heading: "Upload Failed", subHeading: responseJson.meta.errors =="client not present." ? "Client not present": "Document not uploaded correctly", btnText: "Re-upload" });
        }

        if(responseJson.meta){
          this.setState({
            showAlert:true,
            alertMessage:responseJson.errors,
            errors:true,
          })
        }

      }
    }
    if (getName(MessageEnum.NavigationBulkUploadMessage) === message.id) {
      const params = message.getData(
        getName(MessageEnum.NavigationBulkUploadMessage)
      );
      console.log("userChipSelecred",params);

          this.setState({userChipSelected:params})
    }


  }

  async componentDidMount() {
    this.handleUserType()    
  }  

  async componentDidUpdate() {
    if(this.state.showAlert){
      setTimeout(()=>{
        this.setState({showAlert:false,errors:false})
      },4000)
    }    
  }

  handleUserType=()=>{ 

    if(this.state.userType === "vincertax_employee" || this.state.userType === "white_lable_user"){      
      this.setState({showInput:true})
    }


  }

  getDynamicMessage() {
    let idFolder = localStorage.getItem("folderId")
    const folderId1 = idFolder ? JSON.parse(idFolder) : null
    let idSubfolder = localStorage.getItem("subFolderId")
    const subfolderId1 = idSubfolder ? JSON.parse(idSubfolder) : null
    if (folderId1 !== null && subfolderId1 !== null) {
      return getName(MessageEnum.NavigationPdfDocumentsMessage);
    } else if (folderId1 !== null) {
      return getName(MessageEnum.NavigationSubFoldersMessage);
    } else {
      return getName(MessageEnum.NavigationMessage);
    }
  }
  goTodocuments() {
    let activeChip = localStorage.getItem("defaultState")
    const msg: Message = new Message(
      this.getDynamicMessage()
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserLibrary");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationDocumentsMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationDocumentsMessage),
      activeChip
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }
  handleCancel = () => {
    this.setState({ openDialog: false })
    this.goTodocuments()
  }

  formDataFormat = (values: any) => {
    const formData = new FormData();
    values.forEach((item: any) => {
      formData.append('files[]', item.file);
      formData.append('client_email',this.state.clientEmailId)
      formData.append('type',this.state.userChipSelected)

    })
    return formData;
  }
  handleClientEmail = (event: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const email = event?.target?.value;

    if (!emailRegex.test(email) && email!="") {
        this.setState({ emailError: "Invalid email format",clientEmailId: email,emailErrorShow:true });
    } else {
        this.setState({ clientEmailId: email, emailError: "",emailErrorShow:false });
    }
}

  uploadFileData = async (values: any) => {
    this.setState({ formikFiles: values })
    const formData = this.formDataFormat(values)
    let idFolder = localStorage.getItem("folderId")
    const folderId1 = idFolder ? JSON.parse(idFolder) : null
    let idSubfolder = localStorage.getItem("subFolderId")
    const subfolderId1 =  idSubfolder? JSON.parse(idSubfolder) : null
    let uploadEndpoint = '/bulk_uploading/attachments';

    if (folderId1 !== null && subfolderId1 !== null) {
      uploadEndpoint += `?sub_folder_id=${subfolderId1}`;
     
    } else if (folderId1 !== null) {
      uploadEndpoint += `?folder_id=${folderId1}`;
    }
    
    this.uploadFileDataApi = await this.UploadApiCall({
      method: 'POST',
      endPoint: uploadEndpoint,
      body: formData,
      token: this.tokens
    })


  }
  uploadDone = (step: string) => {
    if (this.state.filesStatus == "failed" && step != "Cross") {
      // this.uploadFileData(this.state.formikFiles)
      this.setState({ showPopUp: false })
      this.setState({ openDialog: true })
    } else {
      this.setState({ showPopUp: false })
      this.goTodocuments()
    }

  }
  UploadApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const UploadApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    UploadApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    UploadApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    UploadApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      UploadApiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(UploadApiRequestMessage.id, UploadApiRequestMessage);
    return UploadApiRequestMessage.messageId;
  };
}
// Customizable Area End