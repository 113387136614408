// Customizable Area Start
import React from "react";
import {
  Typography,
  Container,
  Tab,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import ProjectTemplatesController, {
  Props,
} from "./ProjectTemplatesController.web";
import ProjectTemplate from "../../../components/src/ProjectTemplateForm";
import EmailTemplate from "../../../components/src/EmailTemplate";
import TaskTemplate from "./TaskTemplates.web";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import EmailTemplates from "./EmailTemplates.web";
import { imgSuccess } from "./assets";
import FolderTemplates from "./FolderTemplates.web";
import {ContractTemplate} from "./ContractTemplates.web";
import InvoiceTemplate from "./InvoiceTemplate.web";

const projectStyle =(theme:Theme)=>createStyles ({
  usergrouptabs:{ 
    width: '100%',
    "& .MuiTabPanel-root": {
    padding: "30px 0"
  }
},
  tabStyle:{
    borderBottom:"1px solid #94A3B8",
    "& .MuiTabs-indicator": {
      backgroundColor: "#E57727",
      height: "2.5px",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
    },
    "& .MuiTab-textColorInherit": {
      opacity: 1,
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#E57727",
    },
    [theme.breakpoints.down('xs')]: {
      borderBottom:"none",
      "& .MuiTabs-flexContainer": {
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      },
    } 
  },
  messageDivs: { display: "flex", gap: "8px" },
  alertDivs: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    borderRadius: "8px",
    background: "#FFF",
    postion:"absolute" as const,
    boxShadow: "0px 6px 15px -3px",
  },
  editor: {
    display: "grid",
    "& .ql-toolbar.ql-snow": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "& .ql-container.ql-snow": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    "& .ql-toolbar.ql-snow + .ql-container.ql-snow": {
      height: "256px"
    }
  },
  radioH: {
    "& .MuiTypography-body1": {
      fontSize: "14px !important",
      color: "#0F172A",
      fontFamily: "Inter",
      lineHeight: "24px",
    },
    '& .MuiRadio-root.Mui-checked': {
      color: '#e87242',
      fontWeight: 700
    },
    '& .MuiRadio-root:not(.Mui-checked)': {
      color: 'grey !important',
    },
  },
  mainDiv:{display: "flex",
  width: "auto",
  height: "60px",
  padding: "0px 24px",
  alignItems: "center",
  marginTop: "24px",
  marginBottom: "24px",
  borderRadius: "8px",
  border: "1px solid #F0E5FF",
  gap: "30px",
  flexShrink: 0,},
  titleStyle:{
    color: "#E57727",
    textAlign: "center" as const,
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px", 
  },
  //ContractStyles
  mainDivContract:{ 
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
     gap: "20px", // smaller gap for small screens
   },
   [theme.breakpoints.down('xs')]: {
    display:"grid",
     gap: "15px", // even smaller gap for extra small screens
   }
   },
  
   createStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "17px",
     }
  },
  btnTxt:{ 
    display: "flex", alignItems: "center", justifyContent: "center", color: "#396178",
   [theme.breakpoints.down('xs')]: {
    justifyContent: "start",
    fontSize:"14px"
   } 
  },
  textArea:{
    [theme.breakpoints.down('xs')]: {
      height: "120px !important"// even smaller gap for extra small screens
     }
  },
  btnGroup:{
    display: "flex", gap: "12px", 
    [theme.breakpoints.down('xs')]: {
    display: "grid"// even smaller gap for extra small screens
    } 
  },
  primaryBtnStyle: {
    padding: '16px',
    width: '118px',
    fontWeight: 700,
    borderRadius: '8px',
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white"
  },
  toggleBtnss: {
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      background: "#E57727",
    },
  },
  label:{
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px", 
  },
   btnDiv:{display:"flex",gap:"10px",
   [theme.breakpoints.down('xs')]: {
    display: "grid",
    } },
  //FolderStyle
  topWraper: {
    display: "flex",
    width: "100%",
    padding: "8px 12px 8px 24px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "8px",
    border: "0.5px solid #94A3B8",
    background: "#FFF",
    height: "44px",
    [theme.breakpoints.down('xs')]: {
      display: "grid",
      height: "156px", 
      padding: "6px",
      } 
  },
  formInput:{
     display: "flex", width: "32.5rem", height: "5.13rem", flexDirection: "column",
    justifyContent: "center", alignItems: "flex-start",
    [theme.breakpoints.down('xs')]: {
      width: "16.5rem"
      } 
  }
});

export class ProjectsTemplate extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);


  }

  render() {
    const {classes}=this.props
    const tabs = ['Invoices', 'Task/Projects', 'Emails',"Folders","Contracts"];
    return (
  
      <Container maxWidth={"xl"} style={{marginTop:"20px"}}>
 <div className={classes.mainDiv}>
  <Typography className={classes.titleStyle}>Templates</Typography>
</div>

<div className={classes.usergrouptabs}>
						<TabContext value={this.state.value}>
							<div style={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList className={classes.tabStyle} onChange={this.handleChange} aria-label="lab API tabs example">
									{tabs.map((tabType, index) => (
										<Tab key={index} label={tabType} value={tabType} />
									))}
								</TabList>
							</div>
							<TabPanel value="Task/Projects"><TaskTemplate classes={classes} handleOpen={this.handleTaskOpen} List={this.state.projectList}/></TabPanel>
							<TabPanel value="Emails"><EmailTemplates classes={classes} handleOpen={this.handleEmailOpen} List={this.state.emailList}/></TabPanel>
              <TabPanel value="Folders"><FolderTemplates classes={classes} /></TabPanel>
							<TabPanel value="Invoices"><InvoiceTemplate classes={classes} teamEmails={this.state.usersEmailData} /></TabPanel>
              <TabPanel value="Contracts"><ContractTemplate classes={classes} handleOpen={this.handleEmailOpen}/></TabPanel>
						</TabContext>
					</div>


      <EmailTemplate
        openDialog={this.state.isTemplateOpen}
        headingText= "Create Email Template"
        btnOkText="Create"
        vtEmailId={this.state.usersEmailData}
        handleOk={this.createEmailTemplate}
        handleCancel={this.handleCancel}
        modeTypeSelect={this.state.modeTypeSelect}
        emailTypeSelect={this.state.emailTypeSelect}
        handleEmailType={this.handleEmailType}
        handleMode={this.handleMode}
      />

              <ProjectTemplate
               openDialog={this.state.openProject}
               headingText= "Create Template"
               btnCancelText="Cancel"
               btnOkText="Create"
               handleOk={this.createTaskTemplate}
               handleCancel={this.cancelBtn}
               showDialogBtns={true}
               vtEmailId={this.state.usersEmailData}
               showCrossBtn={true}
               />
               {this.state.showAlertPop &&
              <Alert className={classes.alertDivs} icon={false} severity="success">
                <div className={classes.messageDivs} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  {console.log(this.state.alertMessage,"web web")}
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}

      </Container>
  
    );
  }
}
export default withStyles(projectStyle)(ProjectsTemplate)
// Customizable Area End