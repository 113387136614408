import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Papa from 'papaparse';
import { parseString } from 'xml2js';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  files:File[];
  importData:boolean;
  exportData:boolean;
  mapping:boolean;
  selectedFile:any;
  typeSelected:boolean;
  exportAsType:boolean,
  exportPageType:boolean,
  columnNames:string[],
  userToken:string;
  createFile:boolean,
  isCsv:boolean;
  xmlcsvList:any;
  showModal:boolean;
  primaryColor:string;
  Csv:boolean;
  Xml:boolean;
  allFiles:boolean;
  PopMessage:string;
  showPop:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jsonDataApiCallId: string = "";
  currentId = 0;
  importFileApi:string="";
  createFileApi:string="";
  importListApi:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingCSV: false,
      loadingJson: false,
      token: "",
      selectedFile:null,
      exportData:false,
      importData:false,
      exportAsType:false,
      exportPageType:false,
      typeSelected:false,
      mapping:false,
      files:[],
      userToken:"",
      PopMessage:"",
      showPop:false,
      isCsv:false,
      columnNames:["Id","name"],
      createFile:false,
      xmlcsvList:[],
      showModal:false,
      primaryColor:"#E57727",
      Csv:false,
      Xml:false,
      allFiles:true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.jsonDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors) {
        console.log(responseJson.errors);
        alert(configJSON.errorMsg);
        this.setState({ loadingJson: false });
      } else {
         this.exportFile(JSON.stringify(responseJson), ".json");
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.importFileApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({showPop:true,PopMessage:"File imported successfully",selectedFile:"",importData:false,mapping:false})
      } else {
        this.setState({showPop:true,PopMessage:responseJson.message})
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createFileApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({showPop:true,PopMessage:"File created successfully"})
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    let userData = localStorage.getItem("loginData");
    let someDataParsed = userData && JSON.parse(userData);
    this.setState({userToken:someDataParsed?.token})
  }

  async componentDidUpdate() {
    if (this.state.showPop) {
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.setState({ showPop: false });
    }
  }
  

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isJSON = (text: any) => {
    try {
      var json = JSON.parse(text);
      return typeof json === "object";
    } catch (error) {
      return false;
    }
  };

  exportFile = (data: any, type: string) => {
    const blob = new Blob([data], {
      type: type === ".json" ? "application/json" : "text/csv"
    });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${Date.now()}${type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ loadingCSV: false, loadingJson: false });
  };

  downloadCSVData = async () => {
    // This function uses fetch rather than RunEngine for the network request by design
    // It is done because the RestApiClientBlock does not handle CSV responses

    this.setState({ loadingCSV: true });
    try {
      const response = await fetch(
        `${configJSON.baseURL}/${configJSON.exportDataEndpoint}`,
        {
          headers: {
            "Content-Type": configJSON.csvApiContentType,
            token: this.state.token
          }
        }
      );
      const res = await response.text();
      let result = res && this.isJSON(res) ? JSON.parse(res) : null;
      if (result?.errors) {
        console.log(result.errors);
        this.showAlert('Error downloading CSV', configJSON.errorMsg);
        this.setState({ loadingCSV: false });
      } else {
        this.exportFile(res, ".csv");
      }
    } catch (e) {
      console.log("error: ", e);
      this.setState({ loadingCSV: false });
    }
  };

  openImortData=()=>{
    this.setState({importData:!this.state.importData})
  }
  openCreateFile=()=>{
    this.setState({createFile:!this.state.createFile})
  }
  handleRadio=()=>{
    this.setState({typeSelected:!this.state.typeSelected})
  }
  importFormData=(values:any)=>{
    const formData = new FormData();
    const ourCategories = values.map((item:any) => item.ourCategory);
    
    formData.append(`xmlcsv[content][]`, ourCategories);
    this.state.isCsv ? formData.append(`xmlcsv[csv]`, "true"):formData.append(`xmlcsv[csv]`, "false");
    formData.append(`xmlcsv[import]`, "true");
    return formData
  }

  handleImportFile= async(values:any)=>{
    const formData = this.importFormData(values)

    this.importFileApi = await this.ApiCall({
      method: 'POST',
      endPoint: '/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/import_file',
      body: formData,
      token:this.state.userToken
    })
  }

  createFormData=(values:any)=>{
    const formData = new FormData();

    values.inputColumns.forEach((item: string, index: number) => {
      formData.append(`column_names[]`, item);
    });

    this.state.typeSelected ? formData.append(`xmlcsv[xml]`, "true") : formData.append(`xmlcsv[xml]`, "false")
    this.state.typeSelected ? formData.append(`xmlcsv[csv]`, "false") : formData.append(`xmlcsv[csv]`, "true")

    return formData
  }

  handleCreateFile= async(values:any)=>{
    const formData = this.createFormData(values)    

    this.createFileApi = await this.ApiCall({
      method: 'POST',
      endPoint: '/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/',
      body: formData,
      token:this.state.userToken
    })
    this.setState({createFile:false})
  }

  handleExportPage=()=>{
    this.setState({exportPageType:!this.state.exportPageType})
  }
  handleExportAs=()=>{
    this.setState({exportAsType:!this.state.exportAsType})
  }
  handleMapping=()=>{
    this.setState({mapping:!this.state.mapping,importData:false})
  }

   handleFileChange = (event:any) => {
    const file = event.target.files[0] || event.dataTransfer.files[0];
    this.setState({selectedFile:file}); 
    this.handleImportFileUpload(event.target.files)
  };

   handleDragOver = (event:any) => {
      event.preventDefault();
  };
  IEListing =()=> {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationIEListingMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
    
  handleDrop = (event: any) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      for (const item of event.dataTransfer.items) {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          this.setState({ selectedFile: file });
          const fileArray= [file]          
          this.handleImportFileUpload(fileArray);
        }
      }
    } else {
      for (const file of event.dataTransfer.files) {
        this.setState({ selectedFile: file });
      }
    }
  };

  handleFileUpload(selectedFiles:any) {
    this.setState({ files: selectedFiles });
  }
  handleImportFileUpload = (files: File[]) => {
  
    return files[0].type ==="text/csv" ? this.parseCsv(files[0]) :this.parseXml(files[0])
  };

  parseCsv = (file:any) => {
  const reader = new FileReader();

  reader.onload = (event:any) => {
      const csvData = event.target.result;
      const parsedData = Papa.parse(csvData as string, { header: true });
      const extractedColumnNames = parsedData.meta.fields ?? [];
      this.setState({ columnNames: extractedColumnNames,isCsv:true });
       setTimeout(()=>{
        this.handleMapping()
       },2000)
  };

  reader.onerror = (error) => {
      console.error('Error reading file:', error);
  };

  reader.readAsText(file);
};

parseXml = (file: File) => {
  const reader = new FileReader();

  reader.onload = () => {
    const xmlData = reader.result;
    parseString(xmlData as string, (error, result) => {
      if (error) {
        console.error('Error parsing XML:', error);
        return;
      }
      const columnHeaders = Object.keys(result.root.row[0].column[0]);
      this.setState({ columnNames: columnHeaders });
      setTimeout(()=>{
        this.handleMapping()
       },2000)
    });
  };

  reader.readAsText(file);
}


  getNewId=()=> {
    return ++this.currentId;
  }
  onUpload=(file:any, url:any)=> {
    this.setState((prevState:any) => ({
      files: prevState.files.map((fw:any) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      }),
    }));
  }

  onDelete=(file:any)=> {
    this.setState((prevState) => ({
      files: prevState.files.filter((fw:any) => fw.file !== file),
    }));
  }
  ApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  downloadJsonData = () => {
    this.setState({ loadingJson: true });
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.state.token
    };

    const getJsonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jsonDataApiCallId = getJsonMessage.messageId;

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exportDataEndpoint
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exportDataMethod
    );

    runEngine.sendMessage(getJsonMessage.id, getJsonMessage);
    return true;
  };
  // Customizable Area End
}
