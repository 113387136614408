import { IBlock } from "../../../framework/src/IBlock";

import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
//@ts-ignore
import { DateTime } from 'luxon';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  showMOdal:boolean;
  selectedItem:any;
  notificationArray:any,
  deleteApiResponse:string;
  markAllReadApi:string;
  showNotification:any;
  notification_Count:any;
  showAlertPop:boolean;
  alertPopMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotifications: any;
  deleteNotifications: any;
  showNotifications: any;
  markAllReadApi:any;
  notificationCount:any;
  updateNotifications:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    let someData = localStorage.getItem("loginData");
    let someDataParsed = someData && JSON.parse(someData);
      // Customizable Area End
    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: someDataParsed?.token,
      showMOdal:false,
      selectedItem:null,
      notificationArray:[],
      deleteApiResponse:"",
      markAllReadApi:"",
      showNotification:"",
      notification_Count:"",
      showAlertPop:false,
      alertPopMessage:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }



 



  // Customizable Area Start

  async componentDidMount() {
    this.getNotificationsHandle()
  }
  async componentDidUpdate() {
    if(this.state.showAlertPop){
      setTimeout(()=>{
        this.setState({showAlertPop:false})
      },5000)
    }
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestId === this.getNotifications) {
        if (responseJson.data) {
          
          this.setState({ notificationArray: responseJson.data });
        }else{
          this.setState({ notificationArray:"" });
        }
      }
      if (apiRequestId === this.deleteNotifications) {
        this.setState({ showAlertPop:true,alertPopMessage: responseJson.message });
        this.getNotificationsHandle()
      }
      if (apiRequestId === this.updateNotifications) {
        this.setState({ showAlertPop:true,alertPopMessage: responseJson.meta.message });
        this.getNotificationsHandle()
      }

      if (apiRequestId === this.showNotifications) {
        this.setState({ showNotification: responseJson.data });
      }
      if(apiRequestId === this.markAllReadApi)
      {
        this.getNotificationsHandle()
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  getNotificationsHandle = async () => {

    this.getNotifications = await this.notificationApiCall({
      methods: "GET",
      endPoint: `/bx_block_notifications/notifications`,

      token: this.state.token,
    });

  };
  sender_Name=(data:any)=>{
    return data.full_name== null ?data.first_name.charAt(0):data.full_name.charAt(0)
  }
  Notification = (createdAt:any) => {
    const createdDateTime = DateTime.fromISO(createdAt);
    const now = DateTime.now();
  
    const diff = now.diff(createdDateTime, ['weeks', 'days', 'hours','minutes','seconds']).toObject();

    let relativeTime = '';
    if (diff.weeks >= 1) {
      relativeTime = `${Math.floor(diff.weeks)} w`;
    } else if (diff.days >= 1) {
      relativeTime = `${Math.floor(diff.days)} d`;
    } else if (diff.hours >= 1) {
      relativeTime = `${Math.floor(diff.hours)} h`;
    } else if (diff.minutes >= 1) {
      relativeTime = `${Math.floor(diff.minutes)} min`;
    } else {
      relativeTime = `${Math.floor(diff.seconds)} sec`;
    }
    return relativeTime;
  };

  getArray=()=>{
    return !!(this.state.notificationArray || this.state.notificationArray.length !== 0) ;
  }

  isProfile=(value:any)=>{
    return value!=null
  }

  deleteNotificationsHandle = async (id: string) => {

    this.deleteNotifications = await this.notificationApiCall({
      methods: "DELETE",
      endPoint: `/bx_block_notifications/notifications/${id}`,

      token: this.state.token,
    });

  };

  updateFormData=() => {
      const formData = new FormData(); 
  
          formData.append(`notification[is_read]`,"true" )
          
       return formData;
  }

  updateNotificationsHandle = async (id: string) => {

    const formData= this.updateFormData()

    this.updateNotifications = await this.notificationApiCall({
      methods: "PUT",
      endPoint: `/bx_block_notifications/notifications/${id}`,
      body:formData,
      token: this.state.token,
    });

  };


  countNotification = async () => {

    this.notificationCount = await this.notificationApiCall({
      methods: "GET",
      endPoint: `/bx_block_notifications/notifications/notification_count`,

      token: this.state.token,
    });

  };

  markAllReadHandle = async () => {

    this.markAllReadApi = await this.notificationApiCall({
      methods: "GET",
      endPoint: `/bx_block_notifications/notifications/mark_all_as_read`,

      token: this.state.token,
    });

  };


  notificationApiCall = async (data: any) => {
    const { ContentType, methods, endPoint, token, body } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforNotifications = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforNotifications.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforNotifications.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforNotifications.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    body &&
    apiRequestMessageforNotifications.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(
      apiRequestMessageforNotifications.id,
      apiRequestMessageforNotifications
    );
    return apiRequestMessageforNotifications.messageId;
  };
 
  handleClick=(item:any)=>{
    this.setState({showMOdal:!this.state.showMOdal ,selectedItem:item})
  }

  handleNavigate=(id:any)=>{
      localStorage.setItem("notificationID",JSON.stringify(id))
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "NotificationsDetails");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationNotificationDetailsMessage)
      );
      raiseMessage.addData(
        getName(MessageEnum.NavigationNotificationDetailsMessage),
        id
      );
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
  }
  // Customizable Area End
}
