import React from "react";

// Customizable Area Start
import {
 
  Box,
  Button,
  Typography,
 
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { imgSuccess, inbox } from "./assets";
// Customizable Area End

import NotificationsController, {
  Props,

} from "./NotificationsController.web";
import { Check, DeleteOutline, MoreVert } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainWrapper}>
          <Box sx={webStyle.itemWrapper1}>
            <Button onClick={this.markAllReadHandle} data-test-id = "markAllRead"> <Check style={{ width: "1.5rem", height: "1.5rem", flexShrink: 0,marginRight:'6px'}} /> <Typography style={webStyle.markReadText}>Mark all as read</Typography></Button>

          </Box>
          {this.getArray() ? (this.state.notificationArray.map((item:any,index:any)=>(
            <Box sx={webStyle.commonItemWraper} key={item}>
            <Box sx={webStyle.subWraper}>
            <Box style={{
              "display": "flex",
              "alignItems": "center",
              "gap": "24px"
            }}>
            {this.isProfile(item.attributes.sender_data.data.attributes.profile) ?  <img src={item.attributes.sender_data.data.attributes.profile.url} style={webStyle.profileimg} />:
            <div style={webStyle.imgAlt}>{this.sender_Name(item.attributes.sender_data.data.attributes)}</div>}
            
              <Box sx={webStyle.subItemPara} data-test-id="hndleNavigate" onClick={()=>this.handleNavigate(item.id)}>
                <Typography style={item.attributes.is_read ? webStyle.subItemPara2:webStyle.subItemPara2Bold}>{item.attributes.contents}</Typography>
              </Box>
            </Box>
            <Box sx={webStyle.notifyTimeBox}><Button onClick={()=>this.handleClick(item.id)}  data-test-id="hndleClick"><MoreVert style={webStyle.iconStyle} /></Button> <Typography style={webStyle.notifyTime}>{this.Notification(item.attributes.created_at)}</Typography></Box>
            </Box>

            {this.state.showMOdal && this.state.selectedItem ==item.id?
            <Box style={webStyle.actionModalBox}>
            <Button style={webStyle.inboxbtn} data-test-id = "markasRead" onClick={()=>this.updateNotificationsHandle(item.id)}><img src={inbox} style={{width:"18px",height:'18px',marginRight:"8px"}}/><Typography style={webStyle.replyText}>Mark as read</Typography></Button>
            <Button style={webStyle.deletebtn} data-test-id = "deleteById" onClick={()=>this.deleteNotificationsHandle(item.id)}> <DeleteOutline style={{color:"#DC2626",marginRight:"8px"}}/><Typography style={webStyle.deleteText}>Delete</Typography></Button>
          </Box>:null}
          </Box>
          ))
  ):(
    <Typography style={webStyle.emptyArray}>No  notifications yet</Typography>
   ) }   
        {this.state.showAlertPop &&
              <Alert style={{ ...webStyle.alertDivs, position: "absolute" }} icon={false} severity="success">
                <div style={webStyle.messages} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertPopMessage}</Typography>
                </div>
              </Alert>}
        </Box>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle: any = {
  mainWrapper: { "width": "95%", "height": "912px", "padding": "8px 24px 8px 24px", "flexDirection": "column", "justifyContent": "center", "alignItems": "center", "gap": "8px", "flexShrink": "0", "borderRadius": "8px 8px 32px 8px", "background": "#FFF", margin: 'auto' },
  itemWrapper1: {
    "display": "flex",
    "padding": "16px 8px",
    "justifyContent": "flex-end",
    "alignItems": "flex-end",
    "alignSelf": "stretch",
    "borderBottom": "1px solid #E2E8F0"
  },
  markReadText: {
    "color": "#334155",
    "fontFamily": "Inter",
    "fontSize": "14px",

    "fontWeight": 700,
    "lineHeight": "22px",
    textTransform: 'none'

  },
  dot:{height:"10px",width:"10px",backgroundColor:"blue",borderRadius:"50%"},
  commonItemWraper: {
    "display": "flex",
    "padding": "16px 8px",
    "flexDirection": "column",
    "justifyContent": "center",
    "alignItems": "center",
    "gap": "8px",
    "alignSelf": "stretch",
    "borderBottom": "1px solid #E2E8F0",
    "background": "#FFF",
    height: "4rem",
   position:'relative'
  },
  markReadbtn: {
    "display": "flex",
    "width": "138px",
    "height": "24px",
    "justifyContent": "center",
    "alignItems": "flex-start",
    "gap": "6px"
  },
  profileimg: {
    "display": "flex",
    "width": "42.872px",
    "height": "44px",
    "flexDirection": "column",
    "justifyContent": "center",
    "alignItems": "center",
    "borderRadius": "100px",
    // "background": "red"
  },
  subItemPara: {
    "display": "flex",
    "width": "628px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "4px",
    cursor: 'pointer'
  },
  subItemTextClr: {
    "color": "#6200EA",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "20px",
    // width: "628px"
  },
  subItemPara2: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "20px",
    width: "628px"
  },
  subItemPara2Bold: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "20px",
    width: "628px"
  },
  iconStyle:{
    "width": "24px",
    "height": "24px",
    "flexShrink": "0"
  },
  notifyTime:{
    "width": "auto",
    "color": "#64748B",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "20px"
  },
  subWraper:{
    "display": "flex",
    "justifyContent": "space-between",
    // "alignItems": "flex-start",
    "alignSelf": "stretch"
  },
  notifyTimeBox:{
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "center",
    "alignItems": "center",
    "gap": "12px"
  },
 
 
  replyText:{
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "22px",
    textTransform:"none"
  },
  messages: { display: "flex", gap: "8px" },
  alertDivs: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
 
  deleteText:{
    "color": "#DC2626",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "22px",
    textTransform:"none"
  },
  emptyArray:{display:"flex",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,50%)",fontSize:"24px",color:"#0F172A",fontWeight:700},
  imgAlt:{
    width:"42px",
    height:"44px",
    borderRadius:"50%",
    background:"#F6F2EF",
    color:"#E57727",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
    display: "flex",
    fontFamily:"Inter",
    alignItems: "center",
    justifyContent: "center"
  },
  actionModalBox:{
    "display": "flex",
    "width": "192px",
    "height": "82px",
    "padding": "4px",
    "flexDirection": "column",
    "justifyContent": "center",
    "alignItems": "flex-start",
    "gap": "2px",
    "flexShrink": "0",
    "borderRadius": "8px",
    "border": "1px solid #E2E8F0",
    "background": "#FFF",
    "boxShadow": "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    position:'absolute',
    top:'44px',
    right:"24px",
    zIndex:1

  },
  repltybtn:{
    "display": "flex",
    "height": "36px",
    "padding": "7px 105px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#F0E5FF"
  },
  inboxbtn:{
    "display": "flex",
    "height": "36px",
    "padding": "7px 48px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#FFF"
  },
  deletebtn:{
    "display": "flex",
    "height": "36px",
    "padding": "7px 99px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#FFF"
  }
};
// Customizable Area End
