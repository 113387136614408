// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Chip,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Delete, MoreVert } from "@material-ui/icons";
const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


import ImportExportListingController, {
  Props
} from "./ImportExportListingController.web";
import { imgDoc, imgSuccess } from "./assets";
import ExportDialog from "../../../components/src/ExportFile";
import AddData from "../../../components/src/AddData";
import { Alert } from "@material-ui/lab";


export default class ImportExportListing extends ImportExportListingController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    
    return (
      <ThemeProvider theme={theme}>
        <Container style={{padding:"25px 0px 0px 35px",margin:"0px",display:"flex",flexDirection:"column",gap:"20px"}}>
        <Box sx={listingStyle.topWraper1}>
            <Chip
              data-test-id="created"
              onClick={()=>this.handleFileFilter("created")}
              label="Created files"
              variant="outlined"
              style={{
                backgroundColor: this.state.created
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.created ? "#ffffff" : "",
                ...listingStyle.btnStyle
              }}
            />
            <Chip
              data-test-id="imported"
              onClick={()=>this.handleFileFilter("imported")}
              label="Imported files"
              variant="outlined"
              style={{
                backgroundColor: this.state.imported
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.imported ? "#ffffff" : "",
                ...listingStyle.btnStyle
              }}
            />
          </Box>
         <Box sx={listingStyle.topWraper1}>
            <Chip
              data-test-id="allFiles"
              onClick={()=>this.handleFilter("allFiles")}
              label="All files"
              variant="outlined"
              style={{
                backgroundColor: this.state.allFiles
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.allFiles ? "#ffffff" : "",
                ...listingStyle.innerbtnStyle
              }}
            />
            <Chip
              data-test-id="xml"
              onClick={()=>this.handleFilter("xml")}
              label="XML"
              variant="outlined"
              style={{
                backgroundColor: this.state.Xml
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.Xml ? "#ffffff" : "",
                ...listingStyle.innerbtnStyle
              }}
            />
            <Chip
              data-test-id="csv"
              onClick={()=>this.handleFilter("csv")}
              label="CSV"
              variant="outlined"
              style={{
                backgroundColor: this.state.Csv
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.Csv ? "#ffffff" : "",
                ...listingStyle.innerbtnStyle
              }}
            />


          </Box>
        <Box sx={listingStyle.secondTopWrapper} >
            <Box style={{ display: "flex", flexDirection: "column" }}>
            {this.state.xmlcsvList?.length > 0 ? (this.state.xmlcsvList?.map((item: any, index: any) => (
                <Box sx={listingStyle.rowField} key={item.id}  >
                  <Box sx={listingStyle.rowFieldLeft} data-test-id={`row-${index}`}>

                    <img src={imgDoc}/>
                    <Typography style={listingStyle.folderName}>{item.attributes.document.filename}</Typography>
                  </Box>
                  <div style={listingStyle.btnDiv}>
                  <Box>
                    <Button style={listingStyle.buttonSty} data-test-id={`exportData-${index}`} onClick={()=>this.openExportData(item.attributes.document.url,item.attributes.document.filename)}>Export</Button>
                  </Box>
                  <Box>
                    <Button style={listingStyle.buttonSty} data-test-id={`addData-${index}`} onClick={()=>{this.openAddData(item.id);this.fileParsing(item.attributes.document.filename,item.attributes.document.url)}} >Insert</Button>
                  </Box>
                  <Box style={{alignItems: "center",justifyContent: "center",display: "flex",}}>
                    <MoreVert style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => this.handleShowDialog(item)} data-test-id={`dialogShow-${index}`} />
                  </Box>
                  </div>

                  {this.state.showModal && this.state.selectedFile == item ?
                    <Box sx={listingStyle.modalEditDel}>
                      <Button style={listingStyle.delebtn} onClick={()=>this.DeleteFile(item.id)} data-test-id={`deleteXml-${index}`}><Delete style={{ width: '18px', height: "18px" }} /><Typography style={listingStyle.editText} >Delete file</Typography></Button>
                    </Box> : null}
                </Box>))):(<Typography>No documents</Typography>)}
          
            </Box>
            </Box>

            <ExportDialog
                 openDialog={this.state.exportData}
                 headingText="Export data"
                 handleCancel={this.closeExportData}
                 handleOk={this.exportFileAs}
                 handleExportAs={this.handleExportAs}
                 handleExportPage={this.handleExportPage}
                 exportAsType={this.state.exportAsType}
                 exportPageType={this.state.exportPageType}
                 
                />
                <AddData
                openDialog={this.state.addData}
                headingText="Add data"
                btnCancelText="Cancel"
                btnOkText="Add"
                columnNames={this.state.columnNames}
                handleOk={this.handleAddData}
                handleCancel={this.openAddData}
                btnColor="#E57727"
                />

              {this.state.showAlert &&
              <Alert style={listingStyle.aletDis } icon={false} severity="success">
                <div style={listingStyle.mesags} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertMes}</Typography>
                </div>
              </Alert>}
        </Container>
      </ThemeProvider>
    );
  }
}

const listingStyle = {
  rowField: {
    "display": "flex",
    "width": "calc(-269px + 100vw)",
    "padding": "20px 20px",

    "alignItems": "center",
    "gap": "10px",
    "borderRadius": "6px 6px 0px 0px",
    "border": "0.5px solid  #CBD5E1",
    height: "36px",
    justifyContent: 'space-between',
    position: "relative",
    '@media (max-width: 600px)': {
      "display": "list-item",
    },
  },
  rowFieldLeft: {
    display: "flex",
    alignItems: "center",
    gap: '30px',

  },
  ocrText:{
    "color": "#0F172A",
   
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": 400,
    "lineHeight": "22px",
    width:"100%"
 
  },
  buttonSty:{color:"#E57727",textTransform:"capitalize" as const,fontSize:"16px",fontWeight:700},
  secondTopWrapper: {
    "display": "flex",
    "width": "calc(100vw - 217px)",
    "height": "765px",
    // "padding": "30px 25px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "10px", overflowY: "auto",
    "flexShrink": "0",
    "borderRadius": "8px",
    // "border": "1px solid  #CBD5E1",
    "background": "#FFF",
    cursor: 'pointer',
   

  },
  message: { display: "flex", gap: "8px" },
  delebtn: {
    "display": "flex",
    "width": "184px",
    "height": "36px",
    // "padding": "7px 56px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "borderRadius": "8px",
    "background": "#FFF"
  },
  btnStyle:{
    fontSize: "1rem",
    fontWeight: 700, lineHeight: "1.5rem",
    display: "flex", width: "13.75rem",
    height: "3rem", padding: "0.625rem 1rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem", borderRadius: "3.125rem",
    border: "none", cursor: "pointer"
  },
  folderName: {
    "color": "#000",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": 600,
    "lineHeight": "normal"
  },
  topWraper1: {
    "display": "flex",
    "width": "calc(100vw - 279px)",
    "padding": "16px 24px 16px 24px",
    "alignItems": "center",

    "borderRadius": "8px",
    "border": "0.5px solid  #94A3B8",
    "background": "#FFF",
    height: "48px",
    gap: "1rem",
    '@media (max-width: 630px)': {
      display:"grid",
      height: "auto",
      justifyContent: "center",
    },

  },
  btnDiv:
  {display:"flex",gap:"80px",
  '@media (max-width: 600px)': {
    "gap": "5px",
  },
},
  innerbtnStyle:{
    fontSize: "1rem",
    fontWeight: 700, lineHeight: "1.5rem",
    display: "flex", width: "7.75rem",
    height: "3rem", padding: "0.625rem 1rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem", borderRadius: "3.125rem",
    border: "none", cursor: "pointer"
  },
  alert: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginTop:"10%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  modalEditDel: {
    "display": "flex",
    "width": "194px",
    "padding": "5px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "5px",
    "borderRadius": "8px",
    "background": "#FFF",
    "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)",
    position: "absolute",
    top: '12px',
    right: "20px",
    zIndex: 1
  },
  mesags: { display: "flex", gap: "8px" },
  aletDis: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    position: "absolute" as const,
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  editText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight":400,
    "lineHeight": "22px",
    textTransform: "none" as const
  },
};
// Customizable Area End
