import { Box, Button, TextField, Typography } from '@material-ui/core'
import React from 'react'

export const Signed_Engagement = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Signed Engagement</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Signed_Engagement"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Awaiting_Documents = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Awaiting Documents</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Awaiting_Documents"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Ready_to_Work = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Ready To Work</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Ready_to_Work"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Taxes_in_Progress = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Taxes in progress</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Taxes_in_Progress"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Ready_for_Review = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Ready For Review</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Ready_for_Review"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Final_Review_Complete = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Final Review Complete</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Final_Review_Complete"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Waiting_On_Sinature = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Waiting On Sinature</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Waiting_On_Sinature"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Waiting_On_Payment = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Waiting On Payment</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Waiting_On_Payment"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const Ready_To_File = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>Ready To File</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="Ready_To_File"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}
export const File_And_Completed = (props: any) => {
    return (
        <>
            <Box>
                <Typography variant="h4" style={childrenComponent.trackerContentHead}>File And Completed</Typography>
                <Box style={childrenComponent.trackerContentBox}>
                    <Typography variant="h4" style={childrenComponent.trackerText}>Document</Typography>
                    <TextField
                        data-test-id="input-test"
                        name="page-name"
                        variant="outlined"
                        onChange={(e) => { props.onChange(e) }}
                        placeholder="File_And_Completed"
                        style={childrenComponent.trackerContentInputField}
                    />
                    <Box style={childrenComponent.trackerContentButton}>
                        <Button
                            style={childrenComponent.postButton}
                            onClick={props?.postComment}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
                {props?.comments?.map((item: any) => {
                    return (
                        <Typography variant="h4" style={childrenComponent.trackerContentValue}>
                            {item?.attributes?.project_status_comments?.comment}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}


const childrenComponent: any = {
    trackerContentHead: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: 700,
        paddingBottom: 8,
        margin: 0,
    },
    trackerContentSubhead: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 500,
        color: '#64748B',
    },
    trackerContentInputField: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 400,
        width: '100%',
        color: '#64748B',
        borderRadius: '8px',
        padding: '4px 0 8px',
    },
    trackerContentBox: {
        padding: '16px',
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        margin: '24px 0',
    },
    trackerText: {
        fontSize: '16px',
        lineHeight: '28px',
        fontWeight: 700,
    },
    trackerContentButton: {
        textAlign: 'right',
        marginTop: '8px',
    },
    postButton: {
        backgroundColor: 'rgb(232, 116, 36)',
        color: 'rgb(255, 255, 255)',
        fontSize: '16px',
        fontWeight: 700,
        textAlign: 'center',
        padding: '6px 20px',
        textTransform: 'capitalize',
    },
    trackerContentValue: {
        marginBottom:"10px",
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        padding: '16px',
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
    }
};