import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent,  Button, makeStyles, OutlinedInput, Grid, Typography, Switch, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, } from '@material-ui/core';
import Select from "react-select";
import { Close, AddCircleOutline } from '@material-ui/icons';
import { Formik, useField } from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
  dialogWrapper: {
    "& [role='dialog']": {
      margin: '0',
      borderRadius: '8px',
      width: '624px',
      maxHeight: "calc(100% - 3px)",
    },
  },
  radioH:{
    "& .MuiTypography-body1": {
      color: "#0F172A",
      fontFamily: "Inter",
      lineHeight: "24px",     
  },
  '& .MuiRadio-root.Mui-checked': {
    color: '#e87242',
    fontWeight:700
  },
  '& .MuiRadio-root:not(.Mui-checked)': {
    color: 'grey !important',
  },
},
  headingStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  btnWrapper: {
    justifyContent: 'flex-end',
    padding: "24px 24px 24px 285px",
    borderTop: "1px solid #E2E6F0",
    display: "inline-flex",
    alignItems: "flex-start",
    gap: "16px",

    '& button': {
      padding: '16px',
      width: '176px',
      fontWeight: 700,
      borderRadius: '8px',
    }
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white"
  },
  cancelBtn: {
    color: '#E57727',
    background: "#F6F2EF",
    width:"105px !important",
    height:"56px",
    borderColor: '#F6F2EF',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    textTransform: "capitalize"
},
  textInputStyle: {
    margin: "0 !important",
    "& .MuiOutlinedInput-input": {
      textTransform: "uppercase",
    },
    "& .css-yk16xz-control": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
      height: "56px"
    },
    "& .css-1pahdxg-control": {
      height: "56px",
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },
    "& .css-2613qy-menu": {
      height: "106px",
    },
    "&:hover .css-1pahdxg-control": {
      height: "56px",
      borderColor: "#2d6f8f"
    }
  },
  labelStyle: { fontSize: "14px", fontWeight: 700, lineHeight: "22px", color: "#334155" },
  dialogTitle: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
  },
  confirmBtn: {
    borderRadius: '8px',
  },
  reasonsSection: {
    // marginBottom: '25px !important',
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "520px"
  },
  toggle: {
    width: '53px',
    height: '41px',
    "& .MuiSwitch-input": {
      width: "48px",
      height: "28px",
      left: "-41%"
    },
    "& .Mui-checked": {
      transform: "translateX(13px)",
      color: '#fff',
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#989898"
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      background: '#E57727',
      opacity: "1"
    },
    "&>span": {
      top: '4px !important',
      left: '4px !important',
      color: "#fff !important",
      right: "17px !important",
      "&>span": {
        "&>span": {
          width: '15px !important',
          height: '15px !important'
        }
      }
    }
  },
  downloaIcon: {
    cursor: "pointer"
  },
})
const enumType={
  OFFLINE: "offline",
  PROMOTIONAL: "promotional"
}
const validationSchema = Yup.object().shape({
  accountName: Yup.string().min(1, "Field is too short")
    .max(30, "Field is too long")
    .required("Field is required."),
  paymentMethod: Yup.string()
    .required('Select the payment method')
    .oneOf(['cash', 'debit_card'], 'Invalid method'),
    date: Yup.date()
    .required('Due date is required'),
    amount: Yup.string()
    // .matches(/^\d{10}$/, 'Amount should be 10 digits')
    .matches(/^(?!0+$)/, 'Invalid number')
    .required('Amount is required'),
  description: Yup.string().min(1, "Description is too short")
    .max(50, "Description is too long")
    .required('Description is required'),
});
type ManualPaymentProps = {
  openDialog: boolean,
  headingText: string,
  btnCancelText?: string,
  btnOkText: string,
  handleOk: any,
  handleCancel: any,
  TypeSelect?: any,
  handleType?:any;
  productName:string
}




const ManualPaymentForm = (props: ManualPaymentProps) => {
  const classes = useStyles();
  const [clientCredits, setClientCredits] = useState(false);
  const [invoiceEmail, setInvoiceEmail] = useState(false)

  const paymentMethods=[
  {
    value: 0,
    label: "Select payment type",
    labelValue: ""
  },
  {
    value: 1,
    label: "Cash",
    labelValue: "cash"
  },
  {
    value: 2,
    label: "Debit card",
    labelValue: "debit_card"
  },
]

  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
          <div className={classes.dialogTitle}>
            <div>
              <span className={classes.headingStyle}>{props?.headingText}</span>
            </div>
            <Close
              className={classes.downloaIcon}
              // onClick={props?.handleCancel}
              onClick={() => {
                props?.handleCancel()
              }}
            />
          </div>
        </DialogTitle>
        <Formik
          initialValues={{ accountName: "", amount: "",date:"", paymentMethod: "", description: "", source: "",sendNotify:false ,productName:props.productName}}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnMount={true}
          validateOnBlur={true}
          onSubmit={(values, actions) => {
            props.handleOk(values);
            actions.setSubmitting(false)
            actions.resetForm();
          }}
        >
          {({
            handleSubmit,
            touched,
            handleBlur,
            errors,
            values,
            handleChange,
            setFieldValue
          }) => (
            <form style={{ margin: "0" }} onSubmit={handleSubmit}>

              <DialogContent style={{ display: "grid", padding: "40px", gap: "16px" }}>

                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Account name, id or Email</label>
                  <OutlinedInput

                    fullWidth
                    name="accountName"
                    id="accountName"
                    value={values.accountName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type here"
                  />
                  {touched.accountName && errors.accountName && (
                    <Typography color="error" >{errors.accountName}</Typography>
                  )}

                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>
                <label className={classes.labelStyle}>Date</label>
          <TextFieldControl
              data-test-id={`date`}
              id={`date`}
              className={classes.textInputStyle}
              name={`date`}
              type={"date"}
              onBlur={handleBlur}
              placeholder="DD-MM-YYY"
              style={{textTransform:"uppercase"}}
              onChange={handleChange}
          />
          {touched.date && errors.date && (
               <Typography color="error" >{errors.date}</Typography>
               )}
                </Grid>

                <Grid className={classes.reasonsSection} item xs={12}>
                  <label className={classes.labelStyle}>Amount</label>
                  <OutlinedInput
                  
                    fullWidth
                    name="amount"
                    id="amount"
                    value={values.amount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type here"
                />
                  {touched.amount && errors.amount && (
                    <Typography color="error" >{errors.amount}</Typography>
                  )}
                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Source</label>
                  <Select
                    name="source"
                    data-test-id="source"
                    className={classes.textInputStyle}
                    options={paymentMethods}
                    value={paymentMethods.find((option: any) => option.value === Number(values.source))}
                    onChange={(option: any) =>
                      setFieldValue('source', option?.labelValue)
                    }
                  />
                  {touched.source && errors.source && (
                    <Typography color="error" >{errors.source}</Typography>
                  )}

                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>
                <label className={classes.labelStyle}>Payment method</label>
                <Select
                name="paymentMethod"
                data-test-id="paymentMethod"
                className={classes.textInputStyle}
                options={paymentMethods}
                value={paymentMethods.find((option: any) => option.value === Number(values.paymentMethod))}
                onChange={(option: any) =>
                    setFieldValue('paymentMethod', option?.labelValue)
                }
                />
                {touched.paymentMethod && errors.paymentMethod && (
                <Typography color="error" >{errors.paymentMethod}</Typography>
                )}

                </Grid>
                <Grid>
                <FormControl>
                <RadioGroup aria-labelledby="demo-row-radiobutton-group-label" name={"format"}
                className={classes.radioH}
                data-test-id="radioBtn"
                  defaultValue={props.TypeSelect ? enumType.OFFLINE : enumType.PROMOTIONAL}
                  value={props.TypeSelect ? enumType.OFFLINE : enumType.PROMOTIONAL}
                  onChange={(event: any) => {
                    handleChange(event);
                    props.handleType()
                  }}
                >
    <FormControlLabel checked={props.TypeSelect === true} value={enumType.OFFLINE} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "left",
              fontWeight: 400 }}>
              Offline payment
            </Typography>
          } />
    <FormControlLabel checked={props.TypeSelect === false} value={enumType.PROMOTIONAL} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{ fontFamily: "Inter",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "left",fontWeight: 400 }}>
              Promotional credit
            </Typography>
          } />
  </RadioGroup>
</FormControl>
                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Description</label>
                  <OutlinedInput
                    fullWidth
                    multiline
                    rows={5}
                    name="description"
                    id="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    placeholder="Type here"
                    error={touched.description && Boolean(errors.description)}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{
                      color: "#E57727",
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "22px",
                    }}><AddCircleOutline style={{ marginRight: "8px", width: "20px", height: "20px" }} />Add shortcode</Typography>

                    <Typography style={{
                      color: "#334155",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px",
                    }}>{`${values.description.length} /5000`}</Typography>
                  </div>
                  {touched.description && errors.description && (
                    <Typography color="error">{errors.description}</Typography>
                  )}
                </Grid>
                
                <FormControlLabel
                    color="primary"
                    labelPlacement="end"
                    style={{alignItems:"flex-start"}}
                    control={<Checkbox color="primary" style={{padding:"0px 9px"}} checked={values.sendNotify} onChange={handleChange("sendNotify")} />}
                    label={<label>Send client email notification</label>}
                />
              </DialogContent>

              <Grid className={classes.btnWrapper}>
              <Button variant="outlined" className={classes.cancelBtn}
                        onClick={() => {
                            props.handleCancel()
                        }}
                    >
                        {props.btnCancelText}
                    </Button>
                <Button variant="contained" className={classes.primaryBtnStyle} type='submit'>
                  {props.btnOkText}
                </Button>
              </Grid>
            </form>
          )}
        </Formik>

      </Dialog>
    </div>
  )
}

const TextFieldControl = (props: any) => {
  const [field, meta] = useField(props);

  return (
      <OutlinedInput
          fullWidth
          type={props.type}
          {...field}
          {...props}
          error={meta.error && meta.touched}
      />
  );
};

export default ManualPaymentForm

