  // Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
//@ts-ignore
import { DateTime } from 'luxon';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

}

interface S {
  token:string
  sender_name:any;
  created_date:any;
  contents:string;
  designation:any;
  notificationID:any;
  profileImg:any;
  showloader:boolean;
}

interface SS {
  id: any;

}

export default class NotificationDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  deleteNotifications: any;
  showNotifications: any;
  updateNotifications:any;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationNotificationDetailsMessage),
    
    ];

    let someData = localStorage.getItem("loginData");
    let someDataParsed = someData && JSON.parse(someData);
    const userToken = someDataParsed?.token;

    this.state = {

      token:userToken,
      sender_name:"",
      created_date:"",
      contents:"",
      designation:"",
      notificationID:"",
      profileImg:null,
      showloader:false,
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);



  }



 
  async componentDidMount() {
    let someData = localStorage.getItem("notificationID");
    let someDataParsed = someData && JSON.parse(someData);

    if(someDataParsed != null){
      this.showNotificationsHandle(someDataParsed)
    }

  }



  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestId === this.deleteNotifications) {
        this.handleNavigateBack()
      }
      if (apiRequestId === this.updateNotifications) {
        this.handleNavigateBack()
      }

      if (apiRequestId === this.showNotifications) {
        this.setState({showloader:false})
        const respoData=responseJson.data
        const senderData = respoData.attributes
        this.setState({sender_name:this.senderName(senderData.sender_data.data.attributes),
        created_date:senderData.created_at,
        contents:senderData.contents,
        designation:senderData.sender_data.data.attributes.user_type,
        profileImg:senderData.sender_data.data.attributes.profile?.url,
        notificationID:respoData.id});
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    if (getName(MessageEnum.NavigationNotificationDetailsMessage) === message.id) {
        const params = message.getData(
          getName(MessageEnum.NavigationNotificationDetailsMessage)
        );
            this.showNotificationsHandle(params)
            this.setState({showloader:true});
      }

  }

  isProfile=(value:any)=>{
    return value!=null
  }
  designations=(name:string)=>{

    switch (name) {
        case "vincertax_employee":
           return "Vinceretax employee";
        case "white_lable_user":
            return "Financial advisor";
        case "client":
            return "Client";
        default:
          return "Customer support"
      }
  }

  deleteNotification = async (id: string) => {

    this.deleteNotifications = await this.notificationDetailsApiCall({
      methods: "DELETE",
      endPoint: `/bx_block_notifications/notifications/${id}`,

      token: this.state.token,
    });

  };
  senderName=(data:any)=>{    
    return data.full_name== null ?data.first_name:data.full_name
  }

  updateFotmData=() => {
      const formData = new FormData(); 

          formData.append(`notification[is_read]`,"false" )
          
       return formData;
  }

  updateHandle = async () => {

    const formData = this.updateFotmData()

    this.updateNotifications = await this.notificationDetailsApiCall({
      methods: "PUT",
      endPoint: `/bx_block_notifications/notifications/${this.state.notificationID}`,
      body:formData,
      token: this.state.token,
    });

  };

  showNotificationsHandle = async (id: any) => {

    this.showNotifications = await this.notificationDetailsApiCall({
      methods: "GET",
      endPoint: `/bx_block_notifications/notifications/${id}`,

      token: this.state.token,
    });

  };




  notificationDetailsApiCall = async (data: any) => {
    const { ContentType, methods, endPoint,body, token } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestforDetailsNotifications = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestforDetailsNotifications.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestforDetailsNotifications.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestforDetailsNotifications.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    body &&
    apiRequestforDetailsNotifications.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(apiRequestforDetailsNotifications.id, apiRequestforDetailsNotifications);
    return apiRequestforDetailsNotifications.messageId;
  };

    formatDate = (isoString:string) => {
    const dateTime = DateTime.fromISO(isoString);
    const isToday = dateTime.hasSame(DateTime.now(), 'day');
    const datePart = isToday ? 'Today' : dateTime.toFormat('yyyy-MM-dd');
    const timePart = dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  
    return `${datePart}, ${timePart}`;
  };
  handleNavigateBack=()=>{
    localStorage.removeItem("notificationID")
    const msg: Message = new Message(
        getName(MessageEnum.NavigationNotificationMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
  }

}
// Customizable Area End