// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  classes?:any;
  List?:string[]
}
interface Task {
  id: number;
  text: string;
}
interface S {
  token:string;
  showAlertPop:boolean;
  alertMessage:string;
  createFolder:boolean;
  createSubFolder:boolean;
  showCreateFolderModal:boolean;
  openFolderTemplate:boolean;
  //folder code
  folders:string[];
  showDialog:boolean;
  showPdfDialog:boolean; 
  selectedPdf:any;
  showSubPdfDialog:boolean;
  selectedSubPdf:any;
  selectedDoc:any;
  createFolders:boolean;
  createSubFolders:boolean;
  subfolders:any;
  showREnameBox:boolean;
  renameFolderName:string;
  renameSubFolderName:string;
  showRenameDialog:boolean;
  searchFolder:string;
  folderName:string;
  subFolderName:string;
  selectedFolder:string;
  subFolderSelected:string;
  filesStatus:string;
  openDialog:boolean,
  showPopUp:boolean,
  heading:string;
  subHeading:string;
  btnText:string;
  selectedFolderId:string;
  methods:string;
  createsubFolderName:string;
  showCreateSubModal:boolean;
  documents:string[];
  openDialogDoc:boolean;
  showSubPdfFileDialog:boolean;
  selectedSubPdfFile:any;
  validDocuments:string[];
  isDocument:boolean;
  folderNameErr:boolean;
  subFolderNameErr:boolean;
  createSubFolderNameErr:boolean;
  RenameFolderNameErr:boolean;
  RenameSubFolderNameErr:boolean;
  selectedFolderErr:boolean;
  errorMessage:any;
  folderSelected:string;
  selectedSubFolderId:string
}

interface SS {
  id: any;

}

export default class ContractTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {

  createfolderTemplateApi:string="";
  createsubfolderTemplateApi:string="";
  showfolder:string="";
  showsubfolder:string="";
  folderList:string="";
  showSubFolderList:string="";
  uploadFilesTemplateApi:string="";
  uploadDocFilesTemplateApi:string="";
  renameSubFolderApiCallId:string="";
  searchfolderTemplateApi:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
  
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    
    ];
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);

    this.state = {
      token:Parsed?.token,
      createSubFolder:false,
      createFolder:false,
      showCreateFolderModal:false,
      subFolderSelected:"",
      searchFolder:"",
      showREnameBox:false,
      selectedSubFolderId:"",
      subfolders:[],
      createFolders:true,
      createSubFolders:false,
      folders:[],
      openDialogDoc:false,
      showDialog:false,
      folderSelected:"",
      selectedDoc:null,
      RenameFolderNameErr:false,
      RenameSubFolderNameErr:false,
      showPdfDialog:false,
      selectedPdf:null,
      showSubPdfDialog:false,
      selectedSubPdf:null,
      showRenameDialog:false,
      showSubPdfFileDialog:false,
      selectedSubPdfFile:null,
      renameSubFolderName:"",
      renameFolderName:"",
      folderName:"",
      subFolderName:"",
      selectedFolder:"",
      filesStatus:"",
      openDialog:false,
      showPopUp:false,
      heading:"",
      subHeading:"",
      btnText:"",
      selectedFolderId:"",
      methods:"",
      folderNameErr:false,
      subFolderNameErr:false,
      selectedFolderErr:false,
      showCreateSubModal:false,
      createSubFolderNameErr:false,
      createsubFolderName:"",
      isDocument:false,
      documents:[],
      validDocuments:[],
      errorMessage:"",
      //folder code
      showAlertPop:false,
      alertMessage:"",
      openFolderTemplate:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    runEngine.debugLog("API Message Recived", message);
  
    this.handleApiCall(apiRequestCallId, responseJson);
  }
  
  handleApiCall(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.createfolderTemplateApi:
        this.folderListTemplate();
        break;
      case this.createsubfolderTemplateApi:
        this.showSubfolderTemplate(this.state.selectedFolderId);
        break;
      case this.folderList:
        this.setState({ folders: responseJson.data });
        break;
      case this.showSubFolderList:
        this.setState({ subfolders: responseJson.data });
        break;
      case this.showsubfolder:
        this.setState({ documents: responseJson.data });
        this.filterDocuments(this.state.documents);
        break;
      case this.uploadFilesTemplateApi:
        this.handleUploadResponse(responseJson);
        break;
        case this.uploadDocFilesTemplateApi:
          this.handleDocUploadResponse(responseJson);
          break;
      default:
        console.log("Unknown API Request Call ID:", apiRequestCallId);
    }
  }
  
  handleUploadResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ filesStatus: "success", openDialog: false, showPopUp: true, heading: "Successful", subHeading: "File upload successful", btnText: "Ok" });
      this.state.selectedFolderId ? this.showSubfolderTemplate(this.state.selectedFolderId) : this.folderListTemplate();
    } else {
      this.setState({ filesStatus: "failed", openDialog: false, showPopUp: true, heading: "Upload Failed", subHeading: "Document not uploaded correctly", btnText: "Re-upload" });
    }
  }

  handleDocUploadResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ filesStatus: "success", openDialogDoc: false, showPopUp: true, heading: "Successful", subHeading: "File upload successful", btnText: "Ok" });
      this.showsubfolderData(this.state.selectedSubFolderId)
    } else {
      this.setState({ filesStatus: "failed", openDialogDoc: false, showPopUp: true, heading: "Upload Failed", subHeading: "Document not uploaded correctly", btnText: "Re-upload" });
    }
  }
  

  handleShowDialog = (item: any) => {    
    this.setState({ showDialog: !this.state.showDialog,showPdfDialog:false, selectedDoc: item })
    this.showRenameComp(item)
  }
  handlePdfDialog=(item:any)=>{
    this.setState({showPdfDialog:!this.state.showPdfDialog ,showDialog:false,selectedPdf:item})
  }
  handleSubPdfDialog=(item:any)=>{
    this.setState({showSubPdfDialog:!this.state.showSubPdfDialog ,showDialog:false,selectedSubPdf:item})
  }
  handleDocDialog=(item:any)=>{
    this.setState({showSubPdfFileDialog:!this.state.showSubPdfFileDialog ,selectedSubPdfFile:item})
  }
  handleopenRenameBox=()=>{
    this.setState({showREnameBox:true})
  }
  handleCloseSubFolderDialog = () => {
    this.setState({ showRenameDialog: false, showDialog: false })
  }
  FolderRenamFormdata = () => {
    const formdata = new FormData();

    if (this?.state?.renameFolderName !== null) {
      formdata.append('project_folder[folder_name]', this.state.renameFolderName);
      return formdata;
    }
  }
  RenameFolder = async (id: any) => {

    const formdata = this.FolderRenamFormdata()    

    this.createfolderTemplateApi = await this.folderApiCall({

      method: 'PUT',
      endPoint: `/bx_block_projecttemplates/project_folders/${id}`,
      body: formdata,
      token: this.state.token,


    })
    this.setState({ renameFolderName: "", showREnameBox: false ,showDialog:false })
    this.folderListTemplate()
  }
  formDataSubFolderRenameCall = () => {
    const formdata = new FormData();

      formdata.append('project_sub_folder[sub_folder_name]', this.state.renameSubFolderName);
      formdata.append('project_sub_folder[project_folder_id]', this.state.selectedFolderId);
      return formdata;

  }
  RenameSubFolder = async (id: any) => {

    const formdata = this.formDataSubFolderRenameCall()

    this.createsubfolderTemplateApi = await this.folderApiCall({

      method: 'PUT',
      endPoint: `/bx_block_projecttemplates/project_sub_folders/${id}`,
      body: formdata,
      token: this.state.token,


    })


    this.setState({ renameSubFolderName: "", showRenameDialog: false})
  }
  DeleteFolder = async (id: any) => {

    this.createfolderTemplateApi = await this.folderApiCall({

      method: 'DELETE',
      endPoint: `bx_block_projecttemplates/project_folders/${id}`,

      token: this.state.token,


    })

    const updatedItems = this.state.folders.filter(
      (item: any) => item.id !== id
    );
    this.setState({
      folders: updatedItems,
      showDialog:false
    });

  }
  handleFieldError = () => {
    const { folderName, subFolderName, selectedFolder, createFolder } = this.state;
  
    const folderNameErr = folderName?.trim() === "";
    const subFolderNameErr = subFolderName?.trim() === "";
    const selectedFolderErr = selectedFolder?.trim() === "";
    let errorMessage = "";
  
    if (createFolder) {
      if (folderNameErr) {
        errorMessage = "Folder name is required";
      } else {
        this.handleCreateFolderSubFolder();
      }
    } else {
      if (subFolderNameErr) {
        errorMessage = "Subfolder name is required";
      } else if (selectedFolderErr) {
        errorMessage = "Selected folder is required";
      } else {
        this.handleCreateFolderSubFolder();
      }
    }
    this.setState({
      folderNameErr,
      subFolderNameErr,
      selectedFolderErr,
      errorMessage,
    });
  };
    
  handleSubFieldError = () => {
      const { createsubFolderName } = this.state;
    
      const createSubFolderNameErr = createsubFolderName?.trim() === "";
      let errorMessage = "";
    
        if (createSubFolderNameErr) {
          errorMessage = "Subfolder name is required";
        } else {
          this.createNewSubFolder();
        }
    
  
    this.setState({
      createSubFolderNameErr,
      errorMessage,
    });
  };

  handleRenameError = (id:any) => {
    const { renameFolderName,renameSubFolderName } = this.state;
  
    const RenameFolderNameErr = renameFolderName?.trim() === "";
    const RenameSubFolderNameErr= renameSubFolderName?.trim() === "";
    let errorMessage = "";
  
      if (RenameFolderNameErr) {
        errorMessage = "Folder name is required";
      } else {
        this.RenameFolder(id);
      }

      if (RenameSubFolderNameErr) {
        errorMessage = "Sub folder name is required";
      } else {
        this.RenameSubFolder(id);
      }
  

  this.setState({
    RenameFolderNameErr,
    RenameSubFolderNameErr,
    errorMessage,
  });
};
  
  DeleteSubFolder = async (id: any) => {

    this.createsubfolderTemplateApi = await this.folderApiCall({

      method: 'DELETE',
      endPoint: `/bx_block_projecttemplates/project_sub_folders/${id}`,
      token: this.state.token,
    })

    const updatedItems = this.state.subfolders.filter(
      (item: any) => item.id !== id
    );
    this.setState({
      subfolders: updatedItems, showRenameDialog:false});
  }

  DeleteFolderDoc = async (id: any,folderId?:any) => {

    this.createfolderTemplateApi = await this.folderApiCall({

      method: 'DELETE',
      endPoint: `/bx_block_projecttemplates/project_folders/remove_document?id=${id}`,
      token: this.state.token,
    })

    const updatedItems = this.state.folders.filter(
      (item: any) => item.id !== folderId
    );
    this.setState({
      folders: updatedItems, showPdfDialog:false,selectedSubPdfFile:false,selectedSubPdf:false});
  }

  handleNavigate = (id: any,name:any) => {
    this.showSubfolderTemplate(id)
    this.setState({createFolders:!this.state.createFolders,selectedFolderId:id,createSubFolders:!this.state.createSubFolders,folderSelected:name})
  }
  handleFolderNavigate = () => {
    this.setState({createFolders:true,selectedFolderId:"",createSubFolders:false,subfolders:[],folderSelected:""})
  }
  openUpload=(name:string)=>{
   this.setState({openDialog:true ,methods:name}) 
  }
  openDocUpload=(name:string)=>{
    this.setState({openDialogDoc:true ,methods:name}) 
   }
  handleFolderName = (e: any) => {
    this.setState({ folderName: e.target?.value })
  }
  handleSearchFolder = (e: any) => {
    this.setState({ searchFolder: e.target?.value })
  }
  showSubFolderDialog=(item:any)=>{
    return this.state.showDialog && this.state.selectedDoc == item
  }
  handleSubFolderName=(e:any)=>{
    this.setState({ createsubFolderName: e.target?.value })
  }
  handleCreateSubFolderName=(e:any)=>{
    this.setState({ subFolderName: e.target?.value })
  }
  handleSelectFolder=(event:any)=>{
    this.setState({selectedFolder:event.target.value})
  }
  handleCreateFolderSubFolder=()=>{
   this.state.createFolder ? this.createfolderTemplate():this.createsubfolderTemplate()
  } 
  handleCloseREnameBox=()=>{
    this.setState({showREnameBox:false,showDialog:false})
  }
  handleRenameFolder=(e:any)=>{
    this.setState({renameFolderName:e.target.value})
  }
  handleCancelRename=()=>{
    this.setState({renameFolderName:"",showRenameDialog:false,showREnameBox:false,errorMessage:"",renameSubFolderName:"",RenameFolderNameErr:false,RenameSubFolderNameErr:false})
  }
  handleShowRenameDialog = () => {
    this.setState({ showRenameDialog: true })
  }

  handleRenameSubFolder = (event: any) => {
    this.setState({ renameSubFolderName: event?.target.value })
  }
  filterDocuments=(data:any)=>{
    const validDoc = data.attributes?.sub_documents?.filter((document: any) => document && document !== '');
    this.setState({validDocuments:validDoc})
  }

  async componentDidMount() {
    this.folderListTemplate();
  }
  async componentDidUpdate(prevProps:any, prevState:S) {
    if (this.state.showAlertPop) {
      setTimeout(() => {
        this.setState({ showAlertPop: false });
      }, 5000);
    }
  }
heightFunc=()=>{
  return this.state.createFolder ? "5.13rem" : "11.25rem"
}
headingDialog=()=>{
  return this.state.createFolder ? "Create new folder" : "Create sub folder"
}
folderName=(data:any)=>{
  return data !==null
}
isValidFolder=(folder: any) =>{
  return folder.attributes.folder_name && folder.attributes.folder_name !== ""|| null;
}
isValidFolderDoc=(folder: any) =>{
  return folder.attributes.documents && folder.attributes.documents !== ""||null;
}

isValidSubFolder=(subfolder: any) =>{
  return subfolder.attributes && subfolder.attributes.sub_folder_name !== null ||"";
}
showRenameComp=(item:any)=>{
  return this.state.showDialog && this.state.selectedDoc == item
}
  folderFormData = () => {
    const formdata = new FormData();

    if (this.state.folderName !== null) {
      formdata.append('project_folder[folder_name]', this.state.folderName);
      return formdata;
    }
  }

  createfolderTemplate = async () => {
    const formData=this.folderFormData()

    this.createfolderTemplateApi=await this.folderApiCall({
      method: 'POST',
      endPoint: `/bx_block_projecttemplates/project_folders`,
      body:formData,
      token:this.state.token
    })
    this.setState({ folderName: "", showCreateFolderModal: false })
  };
  uploadDone=(step:string)=>{
    if(this.state.filesStatus=="failed" && step!="Cross"){
      // this.uploadFileData(this.state.formikFiles)
      this.setState({showPopUp:false})
      this.setState({openDialog:true})
    }else{
      this.setState({showPopUp:false})
    }
    
  }
  uploadDocDone=(step:string)=>{
    if(this.state.filesStatus=="failed" && step!="Cross"){
      // this.uploadFileData(this.state.formikFiles)
      this.setState({showPopUp:false})
      this.setState({openDialogDoc:true})
    }else{
      this.setState({showPopUp:false})
    }
    
  }
  handleCancel=()=>{
    this.setState({openDialog:false})
  }
  handleDocCancel=()=>{
    this.setState({openDialogDoc:false})
  }
  searchfolderTemplate = async () => {

    this.folderList=await this.folderApiCall({
      method: 'GET',
      endPoint: `/bx_block_projecttemplates/project_folders/search_project_folder?project_folder[folder_name]=${this.state.searchFolder}`,
      token:this.state.token
    })
    // this.setState({ folderName: "", showCreateFolderModal: false })
  };

  fileFormData=(values:any)=>{
      const formData = new FormData();
      values.forEach((item:any) => {
            formData.append('project_folder[documents][]', item.file);
        })
      return formData;
    }
    fileSubFormData=(values:any)=>{
      const formData = new FormData();
      values.forEach((item:any) => {
            formData.append('project_sub_folder[sub_documents][]', item.file);
        })
      formData.append('project_sub_folder[project_folder_id]', this.state.selectedFolderId);
      return formData;
    }

    handleFormData=(values:any)=>{
      switch(this.state.methods){
        case "folder":
          return this.fileFormData(values);
        case "subFolder":
          return this.fileSubFormData(values);
        case "subFolderData":
          return this.fileSubFormData(values)
      }
    }

  uploadFilesTemplate = async (data:any) => {
    const formData=this.handleFormData(data)

    this.uploadFilesTemplateApi=await this.folderApiCall({
      method: 'POST',
      endPoint: this.state.selectedFolderId ? `/bx_block_projecttemplates/project_sub_folders`:`/bx_block_projecttemplates/project_folders`,
      body:formData,
      token:this.state.token
    })
    this.setState({ methods: ""})
  };

  uploadSubFilesTemplate = async (data:any) => {
    const formData=this.handleFormData(data)

    this.uploadDocFilesTemplateApi=await this.folderApiCall({
      method: 'PUT',
      endPoint: `/bx_block_projecttemplates/project_sub_folders/${this.state.selectedSubFolderId}`,
      body:formData,
      token:this.state.token
    })
    this.setState({ methods: ""})
  };

  showfolderTemplate = async (id:any) => {
    this.showfolder=await this.folderApiCall({
      method: 'GET',
      endPoint: `/bx_block_projecttemplates/project_folders/${id}`,
      token:this.state.token
    })
  };
  showSubfolderTemplate = async (id:any) => {
    this.showSubFolderList=await this.folderApiCall({
      method: 'GET',
      endPoint: `/bx_block_projecttemplates/project_sub_folders?project_sub_folder[project_folder_id]=${id}`,
      token:this.state.token
    })
  };
  folderListTemplate = async () => {
    this.folderList=await this.folderApiCall({
      method: 'GET',
      endPoint: `/bx_block_projecttemplates/project_folders?page=1`,
      token:this.state.token
    })
  };
  subFormData = () => {
    const formdata = new FormData();

    if (this.state.subFolderName !== null) {
      formdata.append('project_sub_folder[sub_folder_name]', this.state.subFolderName);
      formdata.append('project_sub_folder[project_folder_id]',this.state.selectedFolder)
      return formdata;
    }
  }
  subcreateFormData = () => {
    const formdata = new FormData();

    if (this.state.createsubFolderName !== null) {
      formdata.append('project_sub_folder[sub_folder_name]', this.state.createsubFolderName);
      formdata.append('project_sub_folder[project_folder_id]',this.state.selectedFolderId)
      return formdata;
    }
  }
  createNewSubFolder = async () => {
    const formdata = this.subcreateFormData();
    
    this.createsubfolderTemplateApi = await this.folderApiCall({

      method: 'POST',
      endPoint: `/bx_block_projecttemplates/project_sub_folders`,
      body: formdata,
      token: this.state.token,


    })
    this.setState({ folderName: "", showCreateSubModal: false, createsubFolderName:""})  
  }

  createsubfolderTemplate = async () => {
    const formData=this.subFormData()

    this.createsubfolderTemplateApi=await this.folderApiCall({
      method: 'POST',
      endPoint: `/bx_block_projecttemplates/project_sub_folders`,
      body:formData,
      token:this.state.token
    })
    this.setState({ folderName: "", showCreateFolderModal: false, subFolderName:""})
  };
  NavigateSubfolder=(id:any,name:any)=>{
    this.setState({isDocument:!this.state.isDocument,createFolder:false,createSubFolders:!this.state.createSubFolders,selectedSubFolderId:id,subFolderSelected:name})
    this.showsubfolderData(id)    
  }
  NavigatetoSubfolder=()=>{
    this.setState({isDocument:!this.state.isDocument,createFolder:false,createSubFolders:!this.state.createSubFolders,selectedSubFolderId:"",subFolderSelected:"",showSubPdfFileDialog:false})
  }
  showFolderPdf=(id:any)=>{
    return this.state.showPdfDialog && this.state.selectedPdf == id 
  }
  showSubFolderPdf=(id:any)=>{
    return this.state.showSubPdfDialog && this.state.selectedSubPdf == id 
  }
  showSubFolderPdfFile=(id:any)=>{
    return this.state.showSubPdfFileDialog && this.state.selectedSubPdfFile == id 
  }
  showsubfolderData = async (id:any) => {
    this.showsubfolder=await this.folderApiCall({
      method: 'GET',
      endPoint: `/bx_block_projecttemplates/project_sub_folders/${id}`,
      token:this.state.token
    })
  };
  handleHideFolderModal=()=>{
    this.setState({ showCreateFolderModal: false,folderName: "", subFolderName:"",folderNameErr:false,subFolderNameErr:false,selectedFolderErr:false })
  }
  handleHideSubFolderModal=()=>{
    this.setState({ showCreateSubModal: false,createSubFolderNameErr:false,createsubFolderName:"" })
  }
  handleShowCreateFolder = () => {
    this.setState({ showCreateFolderModal: true, createFolder: true, createSubFolder: false })
  }
  openFolderTemp=()=>{
    this.setState({openFolderTemplate:true})
  }
  closeModal=()=>{
    this.setState({openFolderTemplate:false})
  }

  handleShowCreateSubFolder = () => {
    this.setState({ showCreateFolderModal: true, createFolder: false, createSubFolder: true })
  }
  handleCreateSubFolders = () => {
    this.setState({ showCreateSubModal: true, createFolder: false, subFolderName:"" })
  }
  
  folderApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const foldertemplateRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    foldertemplateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    foldertemplateRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    foldertemplateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      foldertemplateRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(foldertemplateRequestMessage.id, foldertemplateRequestMessage);
    return foldertemplateRequestMessage.messageId;
  };

}
// Customizable Area End