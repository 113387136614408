import React from "react";

// Customizable Area Start
import {

    Box,

    Button,

    Typography,

    Modal,
    Card,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { green, right } from "./assets"

const ref = React.createRef();

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import {SaveAsPdfInput} from "../../../components/src/SaveAsPdfInput";
// Customizable Area End

import SaveAsPdfController, { Props } from "./SaveAsPdf1Controller.web";
import { ArrowBack, } from "@material-ui/icons";

export default class SaveAsPdf extends SaveAsPdfController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount(): any {
        this.setState({
            attachment_id:this.props.data.convert_to_pdf_attachment_id,
            pdf_id:this.props.data.convert_to_pdf_pdfId
        })    
      }
      componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.data.convert_to_pdf_attachment_id !== prevProps.data.convert_to_pdf_attachment_id ||
            this.props.data.convert_to_pdf_pdfId !== prevProps.data.convert_to_pdf_pdfId) {
            this.setState({
                attachment_id: this.props.data.convert_to_pdf_attachment_id,
                pdf_id: this.props.data.convert_to_pdf_pdfId
            });
        }
    }
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Modal
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        width: "100%", maxWidth: "none"

                    }}
                    open={this.props?.data?.state1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={this.props?.data?.handleCloseSavepdf}


                >

                    <Card
                        style={{
                            width: "748px",

                            borderRadius: "12px",
                            padding: "2rem 2rem 2rem 2rem"
                        }}
                    >
                        <Typography style={{ "alignSelf": "stretch", "color": "#1E293B", "fontFamily": "Inter", "fontSize": "24px", "fontWeight": 700, "lineHeight": "32px", "letterSpacing": "-0.12px" }}>Convert Word to PDF</Typography>


                        <Box>
                            <Box sx={webStyle.pdfBoxsave}>

                                <Box style={{ display: 'flex', alignItems: "center", gap: '12px' }}>
                                    <Box style={webStyle.docimgBox}>
                                        <img src={green} style={{ width: "72px", height: '72px' }} />
                                    </Box>

                                </Box>
                                <Box style={{ display: "flex" }}>
                                    <img src={right} style={{ width: '24px', height: "24px" }} />
                                    <Typography style={{ color: "#059669", "textAlign": "center", "fontFamily": "Inter", "fontSize": "16px", "fontStyle": "normal", "fontWeight": 400, "lineHeight": "24px" }}>Word file has been converted to PDF</Typography>
                                </Box>
                                <SaveAsPdfInput      
                                    apiCall = {this.apiCall}                              
                                />
                            </Box>
                            <Button style={webStyle.convertAFile} data-test-id="convertAFile" onClick={this.props?.data?.convertOtherFile} ><ArrowBack style={{ width: "24px", height: "24px", color: "#E57727", marginRight: "8px" }} /><Typography style={{ "color": "#E57727", "fontFamily": "Inter", "fontSize": "16px", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "24px", textTransform: "none", }}> Convert another file</Typography></Button>
                        </Box>

                    </Card>
                </Modal>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    docimgBox: { "display": "flex", "padding": "12px", "alignItems": "flex-start", 
    "gap": "10px", "borderRadius": "20px", "border": "2px solid rgba(0, 122, 234, 0.20)" },
    selectFilebtn: {
        "display": "flex", "width": "155px", "height": "44px", "padding": "10px 16px",
         "justifyContent": "center", "alignItems": "center", "borderRadius": "8px", "background": "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
    },
    pdfBoxsave: { "display": "flex", "padding": "40px 115px", "flexDirection": "column", 
    "alignItems": "center", "gap": "32px", "alignSelf": "stretch", "borderRadius": "12px", "border": "2px dashed #CBD5E1", "width": "514px", "height": "346px", marginTop: "32px" },
    convertAFile: { "display": "flex", "height": "44px", "padding": "10px 16px",
     "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "8px", "background": "#F6F2EF", marginLeft: "261.5px", marginTop: "32px" },
    inputStyle: { "display": "flex", "height": "56px", "padding": "10px 8px", 
    "justifyContent": "center", "alignItems": "center", "gap": "8px", "alignSelf": "stretch", "borderRadius": "8px", "border": "1px solid  #CBD5E1", "background": "#FFF" }

};
// Customizable Area End
