import React from 'react'
import { Box, Button, ThemeProvider, Typography, createTheme } from '@material-ui/core'
import { Formik, useField } from 'formik';
import * as Yup from "yup";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export const SaveAsPdfInput = ({apiCall }: any) => {
    const initialstate = {
        file_name: "",
    }
    const validationSchema = Yup.object().shape({
        file_name: Yup.string()
            .min(1, "File Name is too short")
            .max(20, "File Name is too long")
            .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'File Name is invalid')
            .required("File Name is required."),
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <Formik
                    initialValues={initialstate}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnMount={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {
                        apiCall(values.file_name);
                    }}
                >
                    {({
                        handleSubmit,
                        touched,
                        handleBlur,
                        errors,
                        values,
                        handleChange,
                        setFieldValue
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                alignItems: "center"
                            }}
                        >
                            <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "4px" }}>
                                <label htmlFor="username" style={{ "color": "#334155", "fontFamily": "Inter", "fontSize": "14px", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "22px" }}>File name</label>
                                <input
                                    id="file_name"
                                    onChange={handleChange}
                                    name="file_name"
                                    type="text"
                                    value={values.file_name}
                                    onBlur={handleBlur}
                                    style={webStyle.inputStyle}
                                />
                                 {touched.file_name && errors.file_name && (
                                <Typography color="error" data-test-id="file_name_error">{errors.file_name}</Typography>
                            )} 
                            </Box>
                           

                            <Button
                                style={webStyle.selectFilebtn}
                                data-test-id="selectFilebtn"
                                type='submit'
                            >
                                <Typography
                                    style={{
                                        "color": "#FFF", "fontFamily": "Inter", "fontSize": "16px",
                                        "fontStyle": "normal", "fontWeight": 700,
                                        "lineHeight": "24px", textTransform: "none"
                                    }}>
                                    Save PDF
                                </Typography>
                            </Button>

                        </form>
                    )}
                </Formik>
            </ThemeProvider>
        </>
    )
}

const webStyle = {

    docimgBox: {
        "display": "flex", "padding": "12px", "alignItems": "flex-start",
        "gap": "10px", "borderRadius": "20px", "border": "2px solid rgba(0, 122, 234, 0.20)"
    },
    selectFilebtn: {
        "display": "flex", "width": "155px", "height": "44px", "padding": "10px 16px",
        "justifyContent": "center", "alignItems": "center", "borderRadius": "8px", "background": "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
    },
    pdfBoxsave: {
        "display": "flex", "padding": "40px 115px", "flexDirection": "column",
        "alignItems": "center", "gap": "32px", "alignSelf": "stretch", "borderRadius": "12px", "border": "2px dashed #CBD5E1", "width": "514px", "height": "346px", marginTop: "32px"
    },
    convertAFile: {
        "display": "flex", "height": "44px", "padding": "10px 16px",
        "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "8px", "background": "#F6F2EF", marginLeft: "261.5px", marginTop: "32px"
    },
    inputStyle: {
        "display": "flex", "height": "56px", "padding": "10px 8px",
        "justifyContent": "center", "alignItems": "center", "gap": "8px", "alignSelf": "stretch", "borderRadius": "8px", "border": "1px solid  #CBD5E1", "background": "#FFF"
    }

};