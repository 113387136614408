import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent,  Button, makeStyles, Typography, Box, DialogActions, } from '@material-ui/core';

import { Close, MoreVert, Delete } from '@material-ui/icons';



const useStyles = makeStyles({
  dialogWrapper: {
    "& [role='dialog']": {
      margin: '0',
      borderRadius: '8px',
      width: '624px',
      maxHeight: "calc(100% - 3px)",
    },
  },
  headingStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  btnWrapper: {
    justifyContent: 'flex-end',
    padding: "24px 24px 24px 285px",
    borderTop: "1px solid #E2E6F0",
    display: "inline-flex",
    alignItems: "flex-start",
    gap: "16px",

    '& button': {
      padding: '16px',
      width: '176px',
      fontWeight: 700,
      borderRadius: '8px',
    }
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white"
  },
  dialogTitle: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
  },
  secondTopWrapper: {
    "display": "flex",
    "width": "calc(100vw - 217px)",
    "height": "765px",
    // "padding": "30px 25px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "10px", overflowY: "auto",
    "flexShrink": 0,
    "borderRadius": "8px",
    // "border": "1px solid  #CBD5E1",
    "background": "#FFF",
    cursor: 'pointer',
   

  },
  delebtn: {
    "display": "flex",
    "width": "184px",
    "height": "36px",
    // "padding": "7px 56px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "borderRadius": "8px",
    "background": "#FFF"
  },
  folderName: {
    "color": "#000",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": 600,
    "lineHeight": "normal"
  },
  rowField: {
    "display": "flex",
    "width": "calc(-269px + 100vw)",
    "padding": "20px 20px",

    "alignItems": "center",
    "gap": "10px",
    "borderRadius": "6px 6px 0px 0px",
    "border": "0.5px solid  #CBD5E1",
    height: "36px",
    justifyContent: 'space-between',
    position: "relative"
  },
  rowFieldLeft: {
    display: "flex",
    alignItems: "center",
    gap: '30px',

  },
  alert: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginTop:"10%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  modalEditDel: {
    "display": "flex",
    "width": "194px",
    "padding": "5px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "5px",
    "borderRadius": "8px",
    "background": "#FFF",
    "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)",
    position: "absolute",
    top: '12px',
    right: "20px",
    zIndex: 1
  },
  editText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight":400,
    "lineHeight": "22px",
    textTransform: "none" as const
  },
  downloaIcon: {
    cursor: "pointer"
  },
})

type AccessDialogProps = {
  openDialog: boolean,
  headingText: string,
  paraText?: string,
  btnOkText: string,
  handleOk: any,
  handleCancel: any,
  deleteInvoice?: any,
  handleShowModal?:any;
  invoiceList?:any;
  showModal:boolean;
  selectedFile?:any;
}




const AccessDialog = (props: AccessDialogProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
          <div className={classes.dialogTitle}>
            <div>
              <span className={classes.headingStyle}>{props?.headingText}</span>
            </div>
            <Close
              className={classes.downloaIcon}
              // onClick={props?.handleCancel}
              onClick={() => {
                props?.handleCancel()
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography>{props.paraText}</Typography>
        </DialogContent>
        <Box className={classes.secondTopWrapper} >
            <Box style={{ display: "flex", flexDirection: "column" }}>
            {props.invoiceList?.length > 0 ? (props.invoiceList?.map((item: any, index: any) => (
                <Box className={classes.rowField} key={item.id}  >
                  <Box className={classes.rowFieldLeft} data-test-id={`row-${index}`}>

                    {/* <img src={imgDoc}/> */}
                    <Typography className={classes.folderName}>{item.attributes.document.filename}</Typography>
                  </Box>
                  <Box>
                    <MoreVert style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => props.handleShowModal(item)} data-test-id={`dialogueshow-${index}`} />
                  </Box>

                  {props.showModal && props.selectedFile == item ?
                    <Box className={classes.modalEditDel}>
                      <Button className={classes.delebtn} data-test-id={`deleteOcr-${index}`} onClick={() => props.deleteInvoice(item.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography className={classes.editText} >Delete folder</Typography></Button>
                    </Box> : null}
                </Box>))):(<Typography>No scanned documents</Typography>)}
          

            </Box>
            </Box>

            <DialogActions className={classes.btnWrapper}>
            <Button variant="contained" className={classes.primaryBtnStyle} type='submit'>
                  {props.btnOkText}
                </Button>
            </DialogActions>

      </Dialog>
    </div>
  )
}

export default AccessDialog

