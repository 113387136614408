import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Check } from "@material-ui/icons";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  pageNumber: number;
  totalDocs: string[];
  dataPerPage: any;

  createNewFolder: any;
  folderName: string;
  NavigateFolderName: string
  FilesInFolder: any;
  searchedDoc: string;
  showEditDilogue: boolean;
  selectedDocument: any;
  showRenameDialog: boolean;
  pdfdocRename: string;
  attatchmentId: string;
  pdfdocRenameErr:boolean;
  errorMessage:string;
  FilterDropdownshow: boolean;
  selectedSortOptionDoc: any;
  shouldUpdate:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserLibraryController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  renamePdfDOCapicall: any
  DeletePdfDocApiId: any
  getFilesInfolderApiCallId: any
  sortFilesInfolderApiCallId: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPdfDocumentsMessage)
      // Customizable Area Start
      // Customizable Area End
    ];


    this.state = {
      arrayHolder: [],
      token: "",




      pageNumber: 1,
      totalDocs: [],
      dataPerPage: this.dataPerPageOptions[0],
      pdfdocRenameErr:false,
      errorMessage:"",
      createNewFolder: [],
      folderName: "",
      NavigateFolderName: "",
      FilesInFolder: [],
      searchedDoc: "",
      showEditDilogue: false,
      selectedDocument: null,
      showRenameDialog: false,
      pdfdocRename: "",
      attatchmentId: "",
      FilterDropdownshow: false,
      selectedSortOptionDoc: "",
      shouldUpdate:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }




  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start

     this.getFilesInFolderlist()

    // Customizable Area End
  }


  componentDidUpdate(prevProps:any, prevState:any) {
  
    if (prevState.shouldUpdate !== this.state.shouldUpdate) {
      this.getFilesInFolderlist();
      this.setState({ shouldUpdate: false });
    }
  }

  token: string = "";
  folderId: string = "";
  subFolderId: string = ""


  getFilesInFolderlist = async () => {
    let userData = localStorage.getItem("loginData");


    let someDataParsed = userData && JSON.parse(userData);
    this.token = someDataParsed?.token;

    let FolderId = localStorage.getItem("folderId")
    this.folderId = FolderId ? JSON.parse(FolderId) : null

    let SubFolderId = localStorage.getItem("subFolderId")
    this.subFolderId = SubFolderId ? JSON.parse(SubFolderId) : null




    this.getFilesInfolderApiCallId = await this.folderAPIcall({
      methods: "GET",
      endPoint: `folder/${this.folderId}/sub_folder/${this.subFolderId}/bulk_uploading/attachments`,

      token: this.token,
    });

  };


  SortFilesInFolderlist = async () => {





    this.sortFilesInfolderApiCallId = await this.folderAPIcall({
      methods: "GET",
      endPoint: `bx_block_bulk_uploading/attachments/sort_attachments?sort_option=${this.state.selectedSortOptionDoc}&sub_folder_id=${this.subFolderId}`,

      token: this.token,
    });

  };







  handleClickSortBtn = () => {
    this.setState({ FilterDropdownshow: !this.state.FilterDropdownshow })
  }

  DeletePdfDoc1 = async (id: any) => {

    this.DeletePdfDocApiId = await this.folderAPIcall({

      methods: 'DELETE',
      endPoint: `bx_block_bulk_uploading/attachments/${this.state?.attatchmentId}/delete_file?file_id=${id}`,

      token: this.token,


    })

    this.setState({shouldUpdate:true})
  

  }

  handleFilterClick = (filter: string) => {
    this.setState((prevState) => {
      const newSortOption = prevState.selectedSortOptionDoc === filter ? '' : filter;

      // Update the state
      return { selectedSortOptionDoc: newSortOption };
    }, () => {
      // Callback function is called after the state is updated
      if (this.state.selectedSortOptionDoc === '') {
        this.getFilesInFolderlist();
      } else {
        this.SortFilesInFolderlist()
      }
    });
  };

  handleUploadDoc = () => {

    const msg: Message = new Message(
      getName(MessageEnum.NavigationBulkUploadMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }

  getStartIcon = (filter: string) => {
    return this.state.selectedSortOptionDoc === filter ? <Check /> : null;
  };

  formDataPdfDocRenameCall = () => {
    const formdata = new FormData();
    console.log(this.state.pdfdocRename, "::::::::::::::::rename")
    if (this.state?.pdfdocRename !== null) {
      formdata.append('name', this.state.pdfdocRename);
      return formdata;
    }
  }

  handlecancelRename = () => {
    this.setState({ pdfdocRename: "" })
  }
  handlepdfdocRenameError = (id: any) => {
    const { pdfdocRename } = this.state;

    const pdfdocRenameErr = pdfdocRename?.trim() === "";
    let errorMessage = "";

    if (pdfdocRenameErr) {
        errorMessage = "File name is required";
      }else{
        this.RenamePdfDoc1(id);
      }

    this.setState({
      pdfdocRenameErr,
      errorMessage,
    });
  };

  RenamePdfDoc1 = async (id: any) => {

    const formdata = this.formDataPdfDocRenameCall()

    this.renamePdfDOCapicall = await this.folderAPIcall({

      methods: 'PATCH',
      endPoint: `bx_block_bulk_uploading/attachments/${this.state.attatchmentId}/rename_file?file_id=${id}`,
      Body: formdata,
      token: this.token,


    })
    this.setState({ pdfdocRename: "", showRenameDialog: false, showEditDilogue: false ,shouldUpdate:true})
    await this.getFilesInFolderlist()
  }

  handlePdfDocRename = (e: any) => {
    this.setState({ pdfdocRename: e.target.value })
  }


  handleCloseREnameDilog = () => {
    this.setState({ showRenameDialog: false, showEditDilogue: false, pdfdocRename: "" })
  }
  handleopenRenameDilog = () => {
    this.setState({ showRenameDialog: true })
  }

  handleShowDialogPdfDoc = (item: any) => {



    this.setState((prevState) => ({
      showEditDilogue: item?.id !== prevState.selectedDocument || !prevState.showEditDilogue,
      selectedDocument: item?.id || prevState.selectedDocument
    }));

    // this.setState({ showEditDilogue: !this.state.showEditDilogue, selectedDocument: item?.id })

  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestId === this.getFilesInfolderApiCallId) {
        if (responseJson.data !== null) {

          this.setState({ FilesInFolder: responseJson?.data?.data.attributes.files, attatchmentId: responseJson?.data?.data.attributes.id }, () => { localStorage.setItem('attributes_id', responseJson.data.data.attributes.id) })

        } else {
          console.log(responseJson.data, "error");
        }
      }


      if (apiRequestId === this.sortFilesInfolderApiCallId) {
        if (responseJson.data !== null) {

          this.setState({ FilesInFolder: responseJson.data.attributes.files, attatchmentId: responseJson.data.id })

        }
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }


    // Customizable Area End
  }




  folderAPIcall = async (data: any) => {
    const { ContentType, methods, endPoint, token, Body } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforFilesInFolder = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforFilesInFolder.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforFilesInFolder.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforFilesInFolder.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    Body &&
      apiRequestMessageforFilesInFolder.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        Body
      );

    runEngine.sendMessage(
      apiRequestMessageforFilesInFolder.id,
      apiRequestMessageforFilesInFolder
    );
    return apiRequestMessageforFilesInFolder.messageId;
  };







  generateRandomData = () => {
    const data = [];
    for (let i = 1; i <= 80; i++) {
      data.push(`Item ${i}`);
    }
    this.setState({ totalDocs: data });
    return data;
  };

  totalData = this.generateRandomData()

  // dataPerPage = 14
  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ pageNumber: value });
  };
  dataPerPageOptions = [14, 28, 42, 50, 70];
  handleItemsPerPageChange = (event: any) => {
    const newValue = parseInt(event.target?.value, 10);
    this.setState({ dataPerPage: newValue })
    this.setState({ pageNumber: 1 })
  };



  handleNavigatePdfEdit = (id: any) => {
    localStorage.setItem('pdf_id', id);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPdfEditWebMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }


  SerachRequestPdfDoc = (searchedVal: any) => {
    const value = searchedVal.target.value
    this.setState({ searchedDoc: value }, () =>
      this.searchResultPdfDoc(this.state.searchedDoc)
    );
  };



  searchResultPdfDoc(value: any) {
    let filterPdfDoc = this.state.FilesInFolder.filter((row: any) => {
      return row.file_name
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value == "") {
      this.getFilesInFolderlist()
    } else {
      this.setState({ FilesInFolder: filterPdfDoc });
    }
  }



  // Customizable Area End
}
