import React, { useEffect, useState, useRef } from 'react';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import {Link as Redirect} from "react-router-dom"
import AppMenu from './AppMenu'
import { getThemeLogo } from '../CommonPlatte';



const useStyles = makeStyles((theme) => ({
    sidebarWrapper: {
        display:"grid",
        backgroundColor: '#fff',
        borderRight: "1px solid #E2E8F0",
        maxWidth: '175px',
        color: '#797979',
        minHeight: '100vh',
        padding: '15px',
        position: 'relative',
        zIndex: 2,
        overflowY: 'hidden',
        height: '100%',
        '& svg': {
            color: '#797979',
        },
        '& ::-webkit-scrollbar':{
            width: '5px'
          },
          
          /* Track */
          '& ::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
           
          /* Handle */
          '& ::-webkit-scrollbar-thumb': {
            background: '#888',
          },
          
          /* Handle on hover */
          '& ::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          }
        // '@media(min-width: 700px)': {
        //     width: '180px',
        // }
    },
    navMenuWrapper: {
        marginTop: '20%', 
        [theme.breakpoints.up('sm')]: {
          marginTop: '0px', 
        },
        [theme.breakpoints.up('md')]: {
          marginTop: '0px',
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: '10%', 
        },
      },
    imgWrap:{
        maxWidth: '100px',
        height: 'auto',
        margin: 'auto',
        display:"flex",
        justifyContent:"center",
        marginTop: '0px',
        '& img':{
            width: '100%',
        }
    },
    imgsWrap:{
        maxWidth: '100px',
        height: 'auto',
        margin: 'auto',
        display:"flex",
        justifyContent:"center",
        '& img':{
            width: '100%',
        }
    }
}));

const enumMenums:any = Object.freeze({
    "documents": "/UserLibrary",
    "search": '/Search',
    "analytics":"/VisualAnalytics",
    "task": '/HomePage',
    "people": '/PeopleWeb',
    "invoices": '/InvoiceBilling',
    "chat": '/Chat',
    "notification": '/Notifications',
    "settings": '/Settings2',
})
const enumMenumsIcons:any = Object.freeze({
    
    "documents": 'document',
    "search": 'search',
    "analytics":'analytics',
    "task": 'task',
    "people": 'people',
    "invoices": 'document',
    "chat": 'chat',
    "notification": 'notification',
    "settings": 'setting',
    // "admin_console": '/assets/images/admin-console'

    // "dashboard": IconDashboard,
    // "analytics": IconBarChart,
    // "customers": IconPeople,
    // "requests": IconLibraryBooks,
    // "policies": '',
    // "insurers": '',
    // "settings": '',
    // "admin_console": ''
})


const enumMenumsClient:any = Object.freeze({
    "documents": "/UserLibrary",
    "search": '/Search',
    "task": '/HomePage',
    "invoices": '/InvoiceBilling',
    "chat": '/Chat',
    "notification": '/Notifications',
    "settings": '/Settings2',
})
const enumMenuClientIcons:any = Object.freeze({
    
    "documents": 'document',
    "search": 'search',
    "task": 'task',
    "invoices": 'document',
    "chat": 'chat',
    "notification": 'notification',
    "settings": 'setting',
})

const enumMenumsFA:any = Object.freeze({
    "documents": "/UserLibrary",
    "search": '/Search',
    "people": '/PeopleWeb',
    "invoices": '/InvoiceBilling',
    "chat": '/Chat',
    "notification": '/Notifications',
    "settings": '/Settings2',
})
const enumMenuFAIcons:any = Object.freeze({
    
    "documents": 'document',
    "search": 'search',
    "people": 'people',
    "invoices": 'document',
    "chat": 'chat',
    "notification": 'notification',
    "settings": 'setting',
})

interface propsTypes {
    onClickOutside: (val:boolean) => void;
    show: boolean;
}

const SidebarMenu = (props: propsTypes) => {
    const classes = useStyles();
    const ref = useRef<any>({});
    // const { t, i18n } = useTranslation();
    const { onClickOutside } = props;
    const [menuListLocalstorage, setMenuListLocalstorage] = useState<any>([])
    const [appMenues, setAppMenues] = useState<any>([]);
    const [enumGlobal,setEnumGlobal]= useState<any>([])
    const [enumIconGlobal,setEnumIconGlobal]= useState<any>([])

    const tempMenus = ['dashboard', 'search','analytics', 'customers', 'requests', 'policies', 'insurers', 'settings'];

    useEffect(() => {
        const tempLocalStorage:any = localStorage.getItem('loginData')
        if(tempLocalStorage !== undefined && tempLocalStorage !== "undefined" && tempLocalStorage !== null && tempLocalStorage !== "" ){
            const temp = JSON.parse(tempLocalStorage)
            // setMenuListLocalstorage(temp?.navigation_menu)
        }else{
            window.location.href = '/EmailLogin'
            // window.location.href = '/'
        }
       const navList = ["documents",
       "search",
       'analytics',
       "task",
       "people",
       "invoices",
       "chat",
       "notification",
       "settings",]

                   setMenuListLocalstorage(navList)
    }, [])

    useEffect(() => {
        const tempLocalStorage:any = localStorage.getItem('loginData')
     
        if(tempLocalStorage !== undefined && tempLocalStorage !== "undefined" && tempLocalStorage !== null && tempLocalStorage !== "" ){
            const temp = JSON.parse(tempLocalStorage)
            const loginType:any=temp.user_type
            if(loginType == "client"){
                setEnumGlobal(enumMenumsClient)
                setEnumIconGlobal(enumMenuClientIcons)
                const navList = ["documents",
                "search",
                "task",
                "invoices",
                "chat",
                "notification",
                "settings",]
         
                setMenuListLocalstorage(navList)
            }else if(loginType == "white_lable_user"){
                setEnumGlobal(enumMenumsFA)
                setEnumIconGlobal(enumMenuFAIcons)
                const navList = ["documents",
                "search",
                "people",
                "invoices",
                "chat",
                "notification",
                "settings",]
         
                setMenuListLocalstorage(navList)
            }else{
                setEnumGlobal(enumMenums)
                setEnumIconGlobal(enumMenumsIcons)
            }
            // setMenuListLocalstorage(temp?.navigation_menu)
        }else{
            window.location.href = '/EmailLogin'
            // window.location.href = '/'
        }
    }, [])
    

    useEffect(() => {
        const handleClickOutside = (event:any) => {
          if (ref?.current && !ref?.current?.contains(event.target)) {
            if(window.innerWidth < 768){
                // onClickOutside && onClickOutside(false);
            }
          }
        };
           
           document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, [ onClickOutside ]);

    useEffect(() => {
        console.log("menuListLocalstorage :", menuListLocalstorage)
        const tempArr: any = [];
            if(menuListLocalstorage.length !== 0 ){
                menuListLocalstorage && menuListLocalstorage?.map((item: string) => {
                    console.log(item,"items");
                    
                const tempObj = {
                    name: item,
                    link: enumGlobal[item],
                    Icon: enumIconGlobal[item],
                }
            tempArr.push(tempObj)
            })

            }else{
            //     tempMenus && tempMenus?.map((item: string) => {
            //     const tempObj = { 
            //         name: t(item),
            //         link: enumMenums[item],
            //         Icon: enumMenumsIcons[item],
            //     }
            //     console.log("term data :",enumMenums['Analysics'])
            // tempArr.push(tempObj)
            // })
            
            // window.location.href = '/EmailLogin'
            }
      setAppMenues(tempArr)
    }, [menuListLocalstorage])

    if(!props.show) return null;
    
    const appMenuItems = [
        {
            name: 'Dashboard',
              link: '/Dashboard',
            Icon: IconDashboard,
        },
        {
            name: 'Analytics',
              link: '/VisualAnalytics',
            Icon: IconBarChart,
        },
        {
            name: 'Customers',
              link: '/Customers',
            Icon: IconPeople,
        },
        {
            name: 'Requests',
            Icon: IconBarChart,
        },
        {
            name: 'Policies',
            Icon: IconBarChart,
          
        },
        {
            name: 'Nested Pages',
            Icon: IconLibraryBooks,
            items: [
                {
                    name: 'Level 2',
                },
                {
                    name: 'Level 2',
                    items: [
                        {
                            name: 'Level 3',
                        },
                        {
                            name: 'Level 3',
                        },
                    ],
                },
            ],
        },
    ]

    console.log(appMenues,"appMenues");
    
    

    return (
        <>
            <div className={classes.sidebarWrapper} data-testId="cmpWrapper"  role="root" ref={ref}>

                <div className={classes.imgWrap}>
                    <img style={{width:"100px",height:"70px"}}src={getThemeLogo()} alt="" />
                </div>
                {/* {t('dashboard')} */}
                <List component="nav" disablePadding className={classes.navMenuWrapper} data-testid="parent">
                 
                    {appMenues.map((item:any, index:any) => (
                        <AppMenu {...item} key={index}  data-testid="child" />
                    ))}
                </List>

                
             
                <div className={classes.imgsWrap}>
                    <Redirect style={{display:"contents"}} to="/CustomisableUserProfiles">
                    <img style={{borderRadius:"50%",width:"60%"}} src="/assets/user.jpg" alt="" />
                    </Redirect>
                </div>
            </div>
        </>
    )
}

export default SidebarMenu
