import React from "react";
// Customizable Area Start
import {
  Button,
  Container,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ProgessTrackerController, { Props } from "./ProgessTrackerController.web";
// Customizable Area End
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import "./Notes.css";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Signed_Engagement, Awaiting_Documents, Ready_to_Work, Taxes_in_Progress, Ready_for_Review, Final_Review_Complete, Waiting_On_Sinature, Waiting_On_Payment, Ready_To_File, File_And_Completed } from "../../../components/src/ProgressTracker";
const theme = createTheme({
  palette: {
    primary: {
      main: "#396178",
      contrastText: "#fff",
    },
  },
});



export default class ProgessTracker extends ProgessTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    let clientID = localStorage.getItem("ClientID");
    let clientIdparse = clientID && JSON.parse(clientID);
    this.showProjectList(clientIdparse)
  }

  // Customizable Area End

  render() {
    const { activeStep } = this.state;
    return (
      /* Customizable Area Start */
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"} style={{ padding: "0", overflowX: "hidden" }}>
          <Grid container component="main" style={webStyle.mainContainer}>
            {!this.state.progressTracker ?
              (
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        height: "2.43rem",
                        borderRadius: " 0.375rem",
                        border: "1px solid #CBD5E1",
                      }}
                    >
                      <TableCell
                        style={{
                          color: "#073F59",
                          fontWeight: 400,
                          fontSize: "15px",
                          width: "30%",
                        }}
                      >
                        Project Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#073F59",
                          fontWeight: 400,
                          fontSize: "15px",
                          width: "30%",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#073F59",
                          fontWeight: 400,
                          fontSize: "15px",
                          width: "30%",
                        }}
                      >
                        Start Date
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#073F59",
                          fontWeight: 400,
                          fontSize: "15px",
                          width: "10%",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <br />
                  {this.state.projectData.length > 0 && this.state.projectData.map((item: any) => {
                    return (<>
                      <TableBody >
                        <React.Fragment >
                          <TableRow
                            style={{
                              borderRadius: "0.375rem",
                              border: "1px solid #CBD5E1",
                              padding: "0.625rem",
                              height: "3.5rem",
                              cursor: "pointer"
                            }}
                            onClick={() => this.showProgressTracker(item?.id)}
                            key={item?.id}
                          >
                            <TableCell
                              style={{
                                fontSize: "20px",
                                fontWeight: 600,
                                width: "30%",
                              }}
                            >
                              {item.attributes.project_name}
                            </TableCell>
                            <TableCell style={{ padding: "1rem", width: "30%" }}>
                              {item.attributes.status}
                            </TableCell>
                            <TableCell style={{ fontSize: "14px", width: "30%" }}>
                              {item.attributes.start_date}
                            </TableCell>
                          </TableRow>
                          <div style={{ marginBottom: "0.8rem" }}></div>
                        </React.Fragment>
                      </TableBody>
                    </>)

                  })}
                </Table>
              ) : (
                <Box style={webStyle.stepperMainDiv}>
                  <Box style={webStyle.stepperBackIconBox} onClick={() => { this.setState({ projectID: "", progressTracker: !this.state.progressTracker }) }}>
                    <ArrowBackIcon style={webStyle.stepperBackIcon} />
                  </Box>
                  <Box className="stepperDivision">
                    <Stepper className="stepperMain" activeStep={activeStep} orientation="vertical">
                      {this.steps.map((label, index) => (
                        <Step style={webStyle.stepWrapper} key={label}>
                          <StepLabel style={webStyle.stepLabel}>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  <Box className="stepperDivisionContent">
                    <Box style={webStyle.trackerWrapper}>
                      <Box style={webStyle.trackerContent}>
                        {/* component  */}
                        {activeStep === 0 && <Signed_Engagement comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 1 && <Awaiting_Documents comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 2 && <Ready_to_Work comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 3 && <Taxes_in_Progress comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 4 && <Ready_for_Review comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 5 && <Final_Review_Complete comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 6 && <Waiting_On_Sinature comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 7 && <Waiting_On_Payment comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 8 && <Ready_To_File comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}
                        {activeStep === 9 && <File_And_Completed comments={this.state.comments} onChange={this.onChange} postComment={this.postComment} />}

                        <Box style={webStyle.actionsContainer}>
                          {activeStep !== 0 ? <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            style={webStyle.backButton}
                          >
                            Back
                          </Button> : <Typography></Typography>}
                          <Button
                            variant="contained"
                            disabled={activeStep === this.steps.length - 1}
                            color="primary"
                            onClick={this.handleNext}
                            style={webStyle.stepButton}
                          >
                            {activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            }
          </Grid>
        </Container>
      </ThemeProvider >
      /* Customizable Area End */
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  mainContainer: {
    width: "97%",
    marginTop: "20px",
    marginLeft: "20px",
    display: "flex",
    gap: "15px"
  },
  allowed: { cursor: "pointer", width: "561px", height: "44px", padding: "10px 8px" },
  root: {
    height: '100vh',
  },
  main: {
    background: "#fff",
  },
  stepperMainDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  stepWrapper: {
    display: 'flex',
    width: '100%',
  },
  stepLabel: {
    borderBottom: '1px solid #CBD5E1',
    borderRadius: '0',
    padding: '10px 20px',
    width: '230px'

  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    width: '100%',
    marginTop: '24px',
  },
  trackerWrapper: {
    width: '100%',
  },
  trackerContent: {
    padding: '30px 25px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    color: '#0F172A'
  },
  stepButton: {
    backgroundColor: 'rgb(232, 116, 36)',
    color: 'rgb(255, 255, 255)',
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'center',
    padding: '6px 20px',
    textTransform: 'capitalize',
  },
  backButton: {
    border: '1px solid #CBD5E1',
    color: 'rgb(232, 116, 36)',
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'center',
    padding: '6px 20px',
    textTransform: 'capitalize',
    boxShadow: 'none'
  },
  stepperBackIconBox: {
    width: '100%',
  },
  stepperBackIcon: {
    border: '1px solid #CBD5E1',
    color: 'rgb(232, 116, 36)',
    borderRadius: '50%',
    padding: '10px',
    cursor: 'pointer',
  }
};
// Customizable Area End

