import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  clientTab: boolean;
  types:string[];
  activeStep: any
  projectData: any;
  progressTracker: any;
  projectID: any;
  comments: any;
  commentData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProgessTrackerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFilesApiCallId: any;
  EditSignatureApiCallId: any;
  showProjectApiCallId: string = "";
  listOfCommentsApiCallId: string = "";
  postCommentApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPdfEditWebMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationUserLiberaryMessage)
      // Customizable Area End
    ];
    const tabtypes=["Progress Tracker","Documents", "Communication", "Invoices", "Notes", "Projects"]

    this.state = {
      // Customizable Area Start
      clientTab: true,
      types: tabtypes,
      activeStep: 0,
      projectData: [],
      progressTracker: false,
      projectID: "",
      comments: [],
      commentData: ""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.showProjectApiCallId) {
        this.showProjectApiCallIdSQ(responseJson)
      }

      if (apiRequestCallId === this.listOfCommentsApiCallId) {
        this.listOfCommentsApiCallIdSQ(responseJson)
      }

      if (apiRequestCallId === this.postCommentApiCallId) {
        this.postCommentApiCallIdSQ(responseJson)
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  steps = ['Signed Engagement', 'Awaiting Documents', 'Ready to Work', 'Taxes in Progress', 'Ready for Review', 'Final Review Complete' ,'Waiting On Sinature' ,'Waiting On Payment' ,'Ready To File','File And Completed'];

  token: string = "";

  showProjectApiCallIdSQ = (responseJson: any) => {
    if(responseJson.data){
    this.setState({ projectData: responseJson.data })
    }
  }
  listOfCommentsApiCallIdSQ = (responseJson: any) => {
    if(responseJson.data){
    this.setState({ comments: responseJson.data })
    }
  }
  postCommentApiCallIdSQ = (responseJson: any) => {
    if(responseJson.data){
      this.listOfComments(this.state.activeStep);
      this.setState({commentData:""});
    }
  }

  showProgressTracker = (id: any) => {
    this.setState({ progressTracker: true, projectID: id }, () => { this.listOfComments(this.state.activeStep) });
  }

  onChange = (e: any) => {
    this.setState({ commentData: e.target.value })
  }

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }), () => { this.listOfComments(this.state.activeStep) });
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }), () => { this.listOfComments(this.state.activeStep) });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  showProjectList = async (clientID: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.showProjectApiCallId = await this.showProjectApiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `/bx_block_profile/progress_trackers?user_id=${clientID}`,
      token: this.token,
    });
  };

  listOfComments = async (statusID = this.state.activeStep) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.listOfCommentsApiCallId = await this.showProjectApiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `bx_block_profile/project_status_comments?project_id=${this.state.projectID}&status_id=${statusID}`,
      token: this.token,
    });
  };


  postComment = async () => {
    const data: any = {
      project_id: this.state.projectID,
      project: {
        comment: this.state.commentData,
        status_id: this.state.activeStep
      }
    }
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.postCommentApiCallId = await this.showProjectApiCall({
      contentType: 'application/json',
      methods: "POST",
      endPoint: `/bx_block_profile/project_status_comments`,
      token: this.token,
      body: data
    })
  }


  showProjectApiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      "Content-Type": "application/json",
      token,
    };
    const apiRequestMessageforProgressTracker = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforProgressTracker.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforProgressTracker.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    body &&
      apiRequestMessageforProgressTracker.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    apiRequestMessageforProgressTracker.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    runEngine.sendMessage(
      apiRequestMessageforProgressTracker.id,
      apiRequestMessageforProgressTracker
    );
    return apiRequestMessageforProgressTracker.messageId;
  }


  // Customizable Area End
}


