// Customizable Area Start
import React from "react";
import {
  Container,
  Button,
  Typography,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  Dialog,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Link,
  Breadcrumbs,
} from "@material-ui/core";
import ContractTemplatesController, {
  Props,
} from "./ContractTemplatesController.web";
import { Close, ControlPoint, Delete, Edit, FolderOpen, MoreVert, Search, NavigateNext } from "@material-ui/icons";
import { imgPdf, upload } from "./assets";
import BulkUpload from "../../../components/src/MultipleFilesUploads";
import FoldersTemplate from "../../../components/src/FolderTemplatesForm";
import { downloadFile } from "../../../components/src/CommonPlatte";


export default class FolderTemplates extends ContractTemplatesController {
  constructor(props: Props) {
    super(props);

  }

  renderDoc=()=>{
    const {validDocuments,folderSelected,subFolderSelected}=this.state
    const {classes}=this.props
    return(
      <div>
            <div style={{ display: "grid", gap: "20px" }}>
            <div className={classes.topWraper}>
              <Button style={folderStyle.buttonStyle} onClick={() => this.openDocUpload("subFolderData")} data-test-id="openSubDoc">
                <ControlPoint style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} />
                <Typography style={folderStyle.btnText}>Upload documents</Typography>
              </Button>
            </div>
            <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
            >
              <Typography key="3" style={{...folderStyle.redirection,cursor:"initial"}}>
            Folders
            </Typography>
           <Link underline="hover" key="1" color="inherit">
              <Typography onClick={this.NavigatetoSubfolder} style={folderStyle.redirection}>{folderSelected}</Typography>
            </Link>
          
            <Typography key="3" style={folderStyle.currentPage}>
            {subFolderSelected}
            </Typography>
        </Breadcrumbs>
          </div>
          <div style={{ display: "flex", marginTop:"20px", flexDirection: "column" }}>
            {validDocuments.map((document: any, indexs: any) => (
                    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} key={document.id}>
                      <div style={folderStyle.rowField} key={document.id}>
                        <div style={folderStyle.rowFieldLeft} data-test-id={`rowpdfdoc-${indexs}`}>
                          <img src={imgPdf} style={{ width: '1.5rem', height: '1.5rem' }} alt="PdfDoc" />
                          <Typography style={folderStyle.folderName} key={indexs}>
                            {document.filename}
                          </Typography>
                        </div>
                        <div>
    
                          <MoreVert onClick={()=>this.handleDocDialog(document.id)}  style={{ marginLeft: '20px', cursor: 'pointer' }} data-test-id={`dialogueshowDoc-${indexs}`} />
                        </div>
                        {this.showSubFolderPdfFile(document.id) ?
                    <div style={folderStyle.modalEditDel}>
                      <Button data-test-id={`dialogueDocDownload-${indexs}`} style={folderStyle.renameFolder} onClick={()=>downloadFile(document.url,document.filename)}><img src={upload} style={{ width: '18px', height: "18px",marginRight: "8px" }}></img><Typography style={folderStyle.editText}>Download file</Typography></Button>
                      <Button data-test-id={`deleteDocFolderPdf-${indexs}`} style={folderStyle.delebtn} onClick={() => this.DeleteFolderDoc(document.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography style={folderStyle.editText} >Delete file</Typography></Button>
                    </div> : null}
                      </div>
                    </div>
                  ))
                  }
          </div>
          </div>
    )
  }

  renderSubFolders=()=>{
    const {classes}=this.props
    return(
      <>
       <div style={{ display: "grid", gap: "20px" }}>
            <div className={classes.topWraper}>
              <Button style={folderStyle.buttonStyle} onClick={() => this.openUpload("subFolder")} data-test-id="openSubFoldersUpload" > <ControlPoint style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /><Typography style={folderStyle.btnText}>Upload documents</Typography></Button>
              <Button style={folderStyle.buttonStyle} onClick={this.handleCreateSubFolders} data-test-id="createSubFolders"> <FolderOpen style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /> <Typography style={folderStyle.btnText}>Create sub folder</Typography></Button>

            </div>
            <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
            >
           <Link underline="hover" key="1" color="inherit">
              <Typography onClick={this.handleFolderNavigate} style={folderStyle.redirection}>Folders</Typography>
            </Link>,
          
            <Typography key="3" style={folderStyle.currentPage}>
            {this.state.folderSelected}
            </Typography>
        </Breadcrumbs>
           
          </div>
            <div style={folderStyle.secondTopWrapper} >
            {this.state.subfolders.filter((folder: any) => this.isValidSubFolder(folder)).map((item: any, index: any) => (
                <div style={folderStyle.rowField} key={item.id}  >
                  <div style={folderStyle.rowFieldLeft} data-test-id={`rowSub-${index}`}
                   onClick={() => this.NavigateSubfolder(item.id,item.attributes.sub_folder_name)}
                  >

                    <FolderOpen style={{ color: "#D97706" }} />
                    <Typography style={folderStyle.folderName}>{item.attributes.sub_folder_name}</Typography>
                  </div>
                  <div>
                    <MoreVert style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => this.handleShowDialog(item)} data-test-id={`dialogueshowSub-${index}`} />
                  </div>

                  {this.showSubFolderDialog(item) ?
                    <div style={folderStyle.modalEditDel}>
                      <Button data-test-id={`dialoguesHide-${index}`} style={folderStyle.renameFolder} onClick={this.handleShowRenameDialog}><Edit style={{ width: '18px', height: "18px" }} /><Typography style={folderStyle.editText}>Rename Subfolder</Typography></Button>
                      <Button data-test-id={`deleteFolderSub-${index}`} style={folderStyle.delebtn} onClick={() => this.DeleteSubFolder(item.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography style={folderStyle.editText} >Delete Subfolder</Typography></Button>
                      {this.state.showRenameDialog ?
                        <Dialog
                          style={folderStyle.dialogStyles}
                          data-test-id="renameModal"
                          open={this.state.showRenameDialog}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Card
                            style={{
                              maxWidth: "37.5rem", borderRadius: "0.5rem", overflowY: "auto"
                            }}
                          >

                            <div
                              style={folderStyle.divBox}
                            >
                              <p
                                style={{
                                  fontSize: "1.5rem",
                                  fontWeight: 700, color: "#000000",
                                }}
                              >
                                Rename folder

                              </p>
                              <Close style={folderStyle.closeMOdalCross} onClick={this.handleCloseSubFolderDialog} data-test-id="closefolderrename" />

                            </div>
                            <Divider />
                            <div
                              style={folderStyle.divStyle}
                            >
                              <div>

                                <FormControl
                                  className={classes.formInput}
                                >
                                  <FormLabel style={{
                                    fontWeight: 700,
                                    color: "#64748B", fontSize: '14px',
                                    lineHeight: '22px'
                                  }}

                                  >
                                    Rename
                                  </FormLabel>
                                  <TextField type="text" variant="outlined"
                                    style={{
                                      "borderRadius": "8px", "border": "1px solid #CBD5E1",
                                      "background": "#FFF"
                                    }}
                                    name="sunFolderNmae"
                                    color="primary"
                                    data-test-id="renamesubFolder"
                                    onChange={this.handleRenameSubFolder}
                                    value={this.state.renameSubFolderName}
                                    InputProps={{

                                      style: {
                                        color: "#64748B", height: '56px',
                                        padding: '10px 8px'
                                      },
                                      placeholder: `${item.attributes.sub_folder_name}`
                                    }}
                                    fullWidth
                                  />
                                  {this.state.RenameSubFolderNameErr && (
                                      <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                                        Sub folder name is required
                                      </Typography>
                                    )}
                                </FormControl>
                              </div>
                            </div>
                            <Divider />
                            <div
                              style={{
                                display: "flex",

                                padding: "1.5rem 1.5rem 1.5rem 20rem",
                                justifyContent: "flex-end",
                                alignItems: "center", alignSelf: "stretch",
                              }}
                            >
                              <Button style={folderStyle.cancelbtn} onClick={this.handleCancelRename}><Typography
                                style={folderStyle.cancelbtnText}>Cancel</Typography></Button>
                              <Button

                                data-test-id="okSubrename" variant="contained" style={folderStyle.btnDialog} onClick={() => this.handleRenameError(item.id)}
                              >
                                <Typography style={{
                                  fontSize: '16px', fontWeight: 700,
                                  fontFamily: 'Inter', textTransform: 'none'
                                }}>Save</Typography>
                              </Button>
                            </div>

                          </Card>
                        </Dialog> : null}
                    </div> : null}
                </div>))}


            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.state.subfolders?.filter((folder:any) => folder.attributes.sub_folder_name === null).map((folder: any, index: any) => (
                <div style={{ display: "flex", flexDirection: "column", gap: "8px", }} key={folder.id}>
                  {folder.attributes?.sub_documents?.map((document: any, indexs: any) => (
                    <div style={folderStyle.rowField} key={document.id}>
                      <div style={folderStyle.rowFieldLeft} data-test-id={`rowpdfdoc-${indexs}`}>
                        <img src={imgPdf} style={{ width: '1.5rem', height: '1.5rem' }} alt="PdfDoc" />

                        <Typography style={folderStyle.folderName} key={indexs}>
                          {document.filename}
                        </Typography>

                      </div>
                      <div>
  
                        <MoreVert onClick={()=>this.handleSubPdfDialog(document.id)} style={{ marginLeft: '20px', cursor: 'pointer' }} data-test-id={`dialogueshowPdfDoc-${indexs}`} />
                      </div>
                      {this.showSubFolderPdf(document.id) ?
                    <div style={folderStyle.modalEditDel}>
                      <Button data-test-id={`dialogueSubDownload-${index}`} style={folderStyle.renameFolder} onClick={()=>downloadFile(document.url,document.filename)}><img src={upload} style={{ width: '18px', height: "18px",marginRight: "8px" }}></img><Typography style={folderStyle.editText}>Download file</Typography></Button>
                      <Button data-test-id={`deleteSubFolderPdf-${index}`} style={folderStyle.delebtn} onClick={() => this.DeleteFolderDoc(document.id,folder.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography style={folderStyle.editText} >Delete file</Typography></Button>
                    </div> : null}
                    </div>
                  ))}
                </div>
              ))}



            </div>

          </div>
      </>
    )
  }


  render() {
    const {classes}=this.props
    return (
      <Container maxWidth={"xl"}>
        <div style={{ display: "grid", gap: "30px" }}>


          <Dialog
            style={folderStyle.dialogBox}
            data-test-id="editmodal"
            open={this.state.showCreateFolderModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card
              style={{
                maxWidth: "37.5rem",

                borderRadius: "0.5rem",


              }}
            >

              <div
                style={{
                  display: "flex",
                  height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
                  justifyContent: "space-between",
                  alignItems: "center", alignSelf: "stretch",

                }}
              >
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700, color: "#000000",
                  }}
                >
                  {this.headingDialog()}

                </p>
                <Close style={folderStyle.closeMOdalCross} onClick={this.handleHideFolderModal} data-test-id="closefolder" />

              </div>
              <Divider />
              <div
                style={{
                  ...folderStyle.divStyle,
                  height: this.heightFunc(),
                }}
              >
                {this.state.createFolder ?
                  <FormControl
                  className={classes.formInput}
                  >
                    <FormLabel style={{
                      paddingBottom: "10px", fontWeight: 700,
                      color: "#64748B", fontSize: '14px',
                      lineHeight: '22px'
                    }}

                    >
                      Folder name
                    </FormLabel>
                    <TextField type="text" variant="outlined"
                      style={folderStyle.textFeild}
                      name="name"
                      color="primary"
                      onChange={this.handleFolderName}
                      value={this.state.folderName}
                      InputProps={{

                        style: {
                          color: "#64748B", height: '56px',
                          padding: '10px 8px'
                        },
                        placeholder: "Untitled folder"
                      }}
                      fullWidth
                    />
                 {this.state.folderNameErr && (
                  <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                    {this.state.errorMessage}
                  </Typography>
                )}
                  </FormControl> : <div>
                    <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                      <FormLabel id="template-type1" style={folderStyle.formLabel}

                      >
                        Select Folder
                      </FormLabel>

                      <Select
                        id="template"
                        variant="outlined"
                        name="templates"
                        displayEmpty
                        placeholder="Select"
                        value={this.state.selectedFolder}
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #CBD5E1"
                        }}
                        MenuProps={{
                          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                          transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null
                        }}
                        onChange={this.handleSelectFolder}
                      >
                        <MenuItem value="" disabled  >
                          <span style={{ opacity: 0.5, color: "#64748B" }}>Select template</span>
                        </MenuItem>
                        {this.state.folders?.map((folder: any) => 
                              <MenuItem value={`${folder.id}`} key={folder.id}>
                                <Typography style={folderStyle.selectOPtion}>
                                  {folder.attributes.folder_name}
                                </Typography>
                              </MenuItem>
                          )
                        }

                      </Select>
                      {this.state.selectedFolderErr && (
                    <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                    Select folder is required
                  </Typography>
                  )}

                    </FormControl>
                    <FormControl
                      className={classes.formInput}
                    >
                      <FormLabel style={folderStyle.formLabel}>
                        Sub folder name
                      </FormLabel>
                      <TextField type="text" variant="outlined"
                        style={folderStyle.textFeild}
                        name="subFolderName"
                        color="primary"
                        onChange={this.handleCreateSubFolderName}
                        value={this.state.subFolderName}
                        InputProps={{

                          style: {
                            color: "#64748B", height: '56px',
                            padding: '10px 8px'
                          },
                          placeholder: "Untitled folder"
                        }}
                        fullWidth
                      />
                      {this.state.subFolderNameErr && (
                    <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                      Subfolder name is required
                    </Typography>
                  )}
                    </FormControl>
                  </div>}


              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  padding: "16px 35.5px 16px 35.5px",
                  justifyContent: "flex-end",
                  alignItems: "center", alignSelf: "stretch",
                }}
              >
                <Button style={folderStyle.cancelbtn} onClick={this.handleHideFolderModal}><Typography
                  style={folderStyle.cancelbtnText}>Cancel</Typography></Button>
                <Button

                  data-test-id="okBtn" variant="contained" style={{
                    display: "flex", width: "7.5rem", height: "3.5rem",
                    padding: "1rem 0",
                    borderRadius: "0.5rem", backgroundColor: "#E57727",
                    color: "#FFF", fontWeight: 700,
                    fontSize: "1rem",
                    textTransform:"none"
                  }}
                  onClick={this.handleFieldError}
                >
                  Ok
                </Button>
              </div>

            </Card>
          </Dialog>

          {this.state.createFolders && <>
            <div style={{ display: "grid", gap: "20px" }}>
              <div className={classes.topWraper}>
                <Button style={folderStyle.buttonStyle} data-test-id="openUploadFolder" onClick={() => this.openUpload("folder")}> <ControlPoint style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /><Typography style={folderStyle.btnText}>Upload documents</Typography></Button>
                <Button style={folderStyle.buttonStyle} onClick={this.handleShowCreateFolder} data-test-id="createFolder"> <FolderOpen style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /> <Typography style={folderStyle.btnText}>Create folder</Typography></Button>
                <Button style={folderStyle.buttonStyle} onClick={this.handleShowCreateSubFolder} data-test-id="createSubFolder"> <FolderOpen style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /> <Typography style={folderStyle.btnText}>Create sub folder</Typography></Button>

              </div>
              <Button id="openEmailTemplate" style={folderStyle.createEmailBtn} onClick={this.handleShowCreateFolder}>Create Folder Template</Button>

            </div>
            <div style={{
              display: "flex",
              width: "100%",
              height: "56px",
              alignItems: "center",
              flexShrink: 0,
            }}>
              <TextField
                type="search"
                id="search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { height: "56px" },
                }}
                placeholder="Write folder name"
                onChange={this.handleSearchFolder}
                value={this.state.searchFolder}
                variant="outlined"
                style={{
                  width: "97%",
                  marginRight: '15px'
                }}

              />

              <Button variant="outlined" style={{ ...folderStyle.cancelBtn, width: "121px" }} onClick={this.searchfolderTemplate}>
                Search
              </Button>
            </div>
          <div style={folderStyle.secondTopWrapper} >
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              {this.state.folders?.filter((folder: any) => this.isValidFolder(folder)).map((item: any, index: any) => (
                  <div style={folderStyle.rowField} key={item.id}>
                    <div style={folderStyle.rowFieldLeft} onClick={() => this.handleNavigate(item?.id,item.attributes.folder_name)} data-test-id={`row-${index}`}>
                      <FolderOpen style={{ color: "#D97706" }} />
                      <Typography style={folderStyle.folderName}>{item.attributes.folder_name}</Typography>
                    </div>
                    <div>

                      <MoreVert style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => this.handleShowDialog(item.id)} data-test-id={`dialogueshow-${index}`} />
                    </div>

                    {this.showRenameComp(item.id) &&
                      <div style={folderStyle.modalEditDel}>
                        <Button data-test-id={`dialoguesRenameOPen-${index}`} style={folderStyle.renameFolder} onClick={this.handleopenRenameBox}><Edit style={{ width: '18px', height: "18px" }} /><Typography style={folderStyle.editText}>Rename folder</Typography></Button>
                        <Button style={folderStyle.delebtn} data-test-id={`deleteFolder-${index}`} onClick={() => this.DeleteFolder(item.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography style={folderStyle.editText} >Delete folder</Typography></Button>
                        {this.state.showREnameBox &&
                          <Dialog
                            style={{
                              display: "flex",
                              justifyContent: "center", alignItems: "center",
                              boxShadow:
                                " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                            }}
                            data-test-id="renameModal"
                            open={this.state.showREnameBox}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Card
                              style={{
                                maxWidth: "37.5rem", borderRadius: "0.5rem", overflowY: "auto"
                              }}
                            >

                              <div
                                style={{
                                  display: "flex", height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
                                  justifyContent: "space-between", alignItems: "center", alignSelf: "stretch",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: 700, color: "#000000",
                                  }}
                                >
                                  Rename folder

                                </p>
                                <Close style={folderStyle.closeMOdalCross} onClick={this.handleCloseREnameBox} data-test-id="closefolderrename" />

                              </div>
                              <Divider />
                              <div
                                style={{
                                  display: "flex",
                                  padding: "2.5rem 2.5rem",
                                  flexDirection: "column", alignItems: "flex-start", gap: "1rem",
                                  alignSelf: "stretch", width: "32.5rem",
                                }}
                              >
                                <div>

                                  <FormControl
                                    className={classes.formInput}
                                  >
                                    <FormLabel style={{
                                      fontWeight: 700,
                                      color: "#64748B", fontSize: '14px',
                                      lineHeight: '22px'
                                    }}

                                    >
                                      Rename
                                    </FormLabel>
                                    <TextField type="text" variant="outlined"
                                      style={{
                                        "borderRadius": "8px", "border": "1px solid #CBD5E1",
                                        "background": "#FFF"
                                      }}
                                      name="sunFolderNmae"
                                      color="primary"
                                      data-test-id="renameFolder"
                                      onChange={this.handleRenameFolder}
                                      value={this.state.renameFolderName}
                                      InputProps={{

                                        style: {
                                          color: "#64748B", height: '56px', padding: '10px 8px'
                                        },
                                        placeholder: `${item.attributes.folder_name}`
                                      }}
                                      fullWidth
                                    />
                                    {this.state.RenameFolderNameErr && (
                                      <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                                        Folder name is required
                                      </Typography>
                                    )}
                                  </FormControl>
                                </div>
                              </div>
                              <Divider />
                              <div
                                style={{
                                  display: "flex", padding: "1.5rem 1.5rem 1.5rem 20rem",
                                  justifyContent: "flex-end",
                                  alignItems: "center", alignSelf: "stretch",
                                }}
                              >
                                <Button style={folderStyle.cancelbtn} onClick={this.handleCancelRename}><Typography
                                  style={folderStyle.cancelbtnText}>Cancel</Typography></Button>
                                <Button

                                  data-test-id="okFolderrename" variant="contained" style={{
                                    display: "flex", width: "7.5rem", height: "3.5rem", padding: "1rem 0",
                                    borderRadius: "0.5rem", backgroundColor: "#E57727",
                                    color: "#FFF", fontWeight: 700,
                                    fontSize: "1rem",
                                  }} onClick={() => this.handleRenameError(item.id)}
                                >
                                  <Typography style={{
                                    fontSize: '16px', fontWeight: 700, fontFamily: 'Inter', textTransform: 'none'
                                  }}>Save</Typography>
                                </Button>
                              </div>

                            </Card>
                          </Dialog>}
                      </div>}
                  </div>
                
              ))}


            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.state.folders?.filter((folder: any) => this.isValidFolderDoc(folder)).map((folder: any, index: any) => (
                <div style={{ display: "flex", flexDirection: "column", gap: "8px",paddingBottom:"5px" }} key={folder.id}>
                  {folder.attributes?.documents?.map((document: any, indexs: any) => (
                    <div style={folderStyle.rowField} key={document.id}>
                      <div style={folderStyle.rowFieldLeft} data-test-id={`rowpdfdoc-${indexs}`}>
                        <img src={imgPdf} style={{ width: '1.5rem', height: '1.5rem' }} alt="PdfDoc" />
                        <Typography style={folderStyle.folderName} key={indexs}>
                          {document.filename}
                        </Typography>
                      </div>
                      <div>
  
                        <MoreVert data-test-id={`openPdfDialog-${indexs}`}onClick={()=>this.handlePdfDialog(document.id)} style={{ marginLeft: '20px', cursor: 'pointer' }} />
                      </div>
                      {this.showFolderPdf(document.id)&&
                    <div style={folderStyle.modalEditDel}>
                      <Button data-test-id={`dialogueDownload-${indexs}`} style={folderStyle.renameFolder} onClick={()=>downloadFile(document.url,document.filename)}><img src={upload} style={{ width: '18px', height: "18px",marginRight: "8px" }}></img><Typography style={folderStyle.editText}>Download file</Typography></Button>
                      <Button data-test-id={`deleteFolderPdf-${indexs}`} style={folderStyle.delebtn} onClick={() => this.DeleteFolderDoc(document.id,folder.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography style={folderStyle.editText} >Delete file</Typography></Button>
                    </div>}
                    </div>
                  ))}
                </div>
              ))}



            </div>


          </div>
          </>}


          {this.state.createSubFolders  && this.renderSubFolders()}

          {this.state.isDocument && this.renderDoc()}

          <Dialog
            style={folderStyle.dialogStyles}
            data-test-id="editmodal"
            open={this.state.showCreateSubModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card
              style={{
                maxWidth: "37.5rem", borderRadius: "0.5rem", overflowY: "auto"
              }}
            >

              <div
                style={folderStyle.divBox}
              >
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700, color: "#000000",
                  }}
                >
                  Create sub folder

                </p>
                <Close style={folderStyle.closeMOdalCross} onClick={this.handleHideSubFolderModal} data-test-id="closefolder" />

              </div>
              <Divider />
              <div
                style={folderStyle.divStyle}
              >
                <div>

                  <FormControl
                    className={classes.formInput}
                  >
                    <FormLabel style={folderStyle.formLabel}

                    >
                      Sub folder name

                    </FormLabel>
                    <TextField type="text" variant="outlined"
                      style={{
                        borderRadius: "8px", 
                        border: "1px solid #CBD5E1",
                        background: "#FFF"
                      }}
                      name="sunFolderNmae"
                      color="primary"
                      data-test-id="subFolderName"
                      value={this.state.createsubFolderName}
                      onChange={this.handleSubFolderName}

                      InputProps={{

                        style: {
                          color: "#64748B", height: '56px',
                          padding: '10px 8px'
                        },
                        placeholder: "Untitled folder"
                      }}
                      fullWidth
                    />
                    {this.state.createSubFolderNameErr && (
                    <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                      Subfolder name is required
                    </Typography>
                  )}
                  </FormControl>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",

                  padding: "1.5rem 1.5rem 1.5rem 20rem",
                  justifyContent: "flex-end",
                  alignItems: "center", alignSelf: "stretch",
                }}
              >
                <Button style={folderStyle.cancelbtn} onClick={this.handleHideSubFolderModal} data-test-id="cancel"><Typography
                  style={folderStyle.cancelbtnText}>Cancel</Typography></Button>
                <Button

                  data-test-id="ok" variant="contained" style={folderStyle.btnDialog}
                  onClick={this.handleSubFieldError}
                >
                  <Typography style={{
                    fontSize: '16px', fontWeight: 700,
                    fontFamily: 'Inter', textTransform: 'none'
                  }}>Ok</Typography>
                </Button>
              </div>

            </Card>
          </Dialog>
        </div>
        <BulkUpload
          openDialog={this.state.openDialog}
          headingText="Upload Files"
          btnCancelText="Cancel"
          btnOkText="Upload"
          btnColor="#E57727"
          textColor="#E57727"
          handleOk={this.uploadFilesTemplate}
          handleCancel={this.handleCancel}
          subHeading={this.state.subHeading}
          showPopUp={this.state.showPopUp}
          filesStatus={this.state.filesStatus}
          heading={this.state.heading}
          btnText={this.state.btnText}
          uploadDone={this.uploadDone}
        />
         <BulkUpload
          openDialog={this.state.openDialogDoc}
          headingText="Upload Files"
          btnCancelText="Cancel"
          btnOkText="Upload"
          btnColor="#E57727"
          textColor="#E57727"
          handleOk={this.uploadSubFilesTemplate}
          handleCancel={this.handleDocCancel}
          subHeading={this.state.subHeading}
          showPopUp={this.state.showPopUp}
          filesStatus={this.state.filesStatus}
          heading={this.state.heading}
          btnText={this.state.btnText}
          uploadDone={this.uploadDocDone}
        />
         <FoldersTemplate
        openDialog={this.state.openFolderTemplate}
        handleOk= {this.handleFieldError}
        folderNameErr={this.state.folderNameErr}
        errorMessage={this.state.errorMessage}
        handleCancel={this.closeModal}
        showDialogBtns= {true}
        showCrossBtn= {true}
        createFolder={this.state.createFolder}
        />
      </Container>
    );
  }
}

const folderStyle = {
  createEmailBtn: {
    display: "flex",
    width: "225px",
    height: "50px",
    padding: "10px 16px",
    justifyContent: "center",
    textTransform: "none" as const,
    borderRadius: "50px",
    background: "#396178",
    alignItems: "center",
    color: "#FFF",
    textAlign: "center" as const,
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    gap: "8px",
    flexShrink: 0,
  },
  dialogStyles:{
    display: "flex",
    justifyContent: "center", alignItems: "center",
    boxShadow:
      " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
  },
  btnDialog:{
    display: "flex", width: "7.5rem", height: "3.5rem",
    padding: "1rem 0",
    borderRadius: "0.5rem", backgroundColor: "#E57727",
    color: "#FFF", fontWeight: 700,
    fontSize: "1rem",
    textTransform:"none" as const,
  },
  buttonStyle: {
    display: "flex",
    height: "44px",
    // "padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center" as const,
    gap: "8px",
    borderRadius: "8px"
  },
  rowFieldLeft: {
    display: "flex",
    alignItems: "center",
    cursor:"pointer",
    gap: '30px',

  },
  btnText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none" as const,
    color: "#396178",
    marginLeft: "8px"
  },
  subFolder: {
    marginBottom: "0.94rem",
    display: "flex", width: "32.5rem",
    height: "5.13rem", flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "flex-start",

  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    padding: '16px',
    width: '118px',
    fontWeight: 700,
    borderRadius: '8px',
    "text-transform": "none",
    "color": "white"
  },
  selectOPtion: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": 700,
    "lineHeight": "24px"
  },
  //folder code
  modalEditDel: {
    "display": "flex",
    "width": "194px",
    "padding": "5px",
    "flexDirection": "column" as const,
    "alignItems": "flex-start",
    "gap": "5px",
    "borderRadius": "8px",
    "background": "#FFF",
    "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)",
    position: "absolute" as const,
    top: '12px',
    right: "34px",
    zIndex: 1
  },
  divBox:{
    display: "flex",
    height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
    justifyContent: "space-between",
    alignItems: "center", alignSelf: "stretch",
  },
  delebtn: {
    "display": "flex",
    "width": "184px",
    "height": "36px",
    // "padding": "7px 56px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "borderRadius": "8px",
    "background": "#FFF"
  },
  renameFolder: {
    "display": "flex",
    "width": "184px",
    "height": "36px",

    "alignItems": "center",
    "gap": "8px",
    "borderRadius": "8px",
    "background": "#F6F2EF"
  },
  redirection: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    cursor:"pointer",
    textDecoration: "none",
    letterSpacing: "-0.12px",
    '&:hover': {
      textDecoration: "none",
    },
  },
  currentPage: {
    color: "#E57727",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px"
  },
  editText: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: "none" as const
  },
  formLabel: {
    fontWeight: 700,
    color: "#64748B", fontSize: '14px',
    lineHeight: '22px'
  },
  cancelBtn: {
    color: '#E57727',
    background: "#F6F2EF",
    width: "105px !important",
    height: "56px",
    borderColor: '#F6F2EF',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    textTransform: "capitalize" as const
  },
  secondTopWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    // alignItems: "flex-start",
    gap: "10px",
    flexShrink: 0,
    background: "#FFF",
  },
  divStyle: {
    display: "flex",
    padding: "2.5rem 2.5rem",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    gap: "1rem", alignSelf: "stretch",
    width: "32.5rem"
  },
  folderName: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal"
  },
  dialogBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
  },
  rowField: {
    display: "flex",
    width: "100%",
    padding: "10px 10px",

    alignItems: "center",
    gap: "10px",
    borderRadius: "6px 6px 0px 0px",
    border: "0.5px solid  #CBD5E1",
    height: "36px",
    justifyContent: 'space-between',
    position: "relative" as const
  },
  cancelbtn: {
    display: "flex",
    width: "120px",
    height: "56px",
    padding: "16px 37.5px 16px 37.5px",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#F6F2EF",
    marginRight: "1rem"
  },
  cancelbtnText: {
    color: "#E57727",
    textAlign: "center" as const,
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none" as const
  },
  closeMOdalCross: {
    display: "flex",
    width: "32px",
    height: "32px",
    padding: "4px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    cursor: 'pointer'
  },
  textFeild: {
    borderRadius: "8px", border: "1px solid #CBD5E1", background: "#FFF"
  }
}

// Customizable Area End