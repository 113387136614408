// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import Chat from "../../blocks/chat/src/Chat1.web"
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit1.web";
import PdfEditorWrapper from "../../blocks/pdfedit/src/PdfEditorWrapper.web";
import PdfConvert from "../../blocks/pdfedit/src/PdfConvert.web";
import Cfprefillformswitheditconfirmationlogic2 from "../../blocks/cfprefillformswitheditconfirmationlogic2/src/Cfprefillformswitheditconfirmationlogic2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import CustomisableUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableUserProfile.web"
import Profile from "../../blocks/customisableuserprofiles/src/Profile.web"
import Notes from "../../blocks/customisableuserprofiles/src/Notes.web"
import UserGroup from "../../blocks/customisableuserprofiles/src/UserGroup.web"

import ProjectTemplates from "../../blocks/projecttemplates/src/ProjectsTemplates.web"
import ProgessTracker from "../../blocks/customisableuserprofiles/src/ProgessTracker.web"

import Cfconditionalquestionanswerforms from "../../blocks/cfconditionalquestionanswerforms/src/Cfconditionalquestionanswerforms";
import DigitalSignatures from "../../blocks/digitalsignatures/src/DigitalSignatures";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Documents from "../../blocks/navigationmenu/src/NavPage.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import AccountCreation from "../../blocks/mobile-account-registration/src/AccountCreation.web";
import PeopleWeb from "../../blocks/mobile-account-registration/src/PeopleWeb.web"
import ThemeBlock from "../../blocks/themeblock/src/Theme.web";
import CreateTheme from "../../blocks/themeblock/src/CreateTheme.web";
import Xmlcsvtemplatebuilder from "../../blocks/xmlcsvtemplatebuilder/src/Xmlcsvtemplatebuilder.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf1.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications1.web";
import NotificationsDetails from "../../blocks/notifications/src/NotificationDetails.web";
import Audittrail2 from "../../blocks/audittrail2/src/Audittrail2";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Cfsureprepapiintegrationtodigestreceiptsandtaxforms from "../../blocks/cfsureprepapiintegrationtodigestreceiptsandtaxforms/src/Cfsureprepapiintegrationtodigestreceiptsandtaxforms";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserLibrary from "../../blocks/catalogue/src/UserLibrary.web";
import PdfDocument from "../../blocks/catalogue/src/PdfDocument.web";
import SubFolder from "../../blocks/catalogue/src/SubFolder.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";

import Repeatingtasks2 from "../../blocks/repeatingtasks2/src/Repeatingtasks2";
import Settings2 from "../../blocks/settings2/src/Settings2.web";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Search from "../../blocks/search/src/Search1.web";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImortExportData1.web";
import ImportExportListing from "../../blocks/importexportdata/src/ImportExportListing.web";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling.web";
import InvoiceDetails from "../../blocks/invoicebilling/src/InvoiceDetails.web";
import BulkUpload from "../../blocks/bulkuploading/src/BulkUpload.web";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import TwoFactorAuthentication from "../../blocks/mobile-account-login/src/TwoFactorAuthentication.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import HomePage from "../../blocks/landingpage/src/Home.web";
import CreateprojectPage from "../../blocks/landingpage/src/CreateProject.web"
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailLogin from "../../blocks/email-account-login/src/EmailLogin.web";
import Projectnotes from "../../blocks/projectnotes/src/Projectnotes";
import Analytics from "../../blocks/analytics/src/Analytics";
import SidebarMenu from "../../components/src/Sidebar/SideMenu";
import TopBar from "../../components/src/Sidebar/TopBar";
import Breadcrumbnavigation2 from "../../blocks/breadcrumbnavigation2/src/Breadcrumbnavigation2.web";
import OcrDocuments from "../../blocks/ocropticalcharacterrecognition2/src/OcrListing.web";
import Ocropticalcharacterrecognition2 from "../../blocks/ocropticalcharacterrecognition2/src/Ocropticalcharacterrecognition2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Salesreporting from "../../blocks/salesreporting/src/SalesReporting";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Grid from '@material-ui/core/Grid';
import Audittrail from "../../blocks/audittrail2/src/Audittrail.web"
import {ModelProvider} from '../../components/src/Context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { createBrowserHistory } from 'history';
import { baseURL } from "../../framework/src/config"

const routeMap = {
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },

  CustomisableUserProfile: {
    component: CustomisableUserProfile,
    path: "/CustomisableUserProfiles"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  Audittrail: {
    component: Audittrail,
    path: "/Audittrail"
  },
  PdfEdit: {
    component: PdfEdit,
    path: "/PdfEdit"
  },
  PdfEditorWrapper: {
    component: PdfEditorWrapper,
    path: "/PdfEditorWrapper"
  },
  PdfConvert: {
    component: PdfConvert,
    path: "/PdfConvert"
  },
  Cfprefillformswitheditconfirmationlogic2: {
    component: Cfprefillformswitheditconfirmationlogic2,
    path: "/Cfprefillformswitheditconfirmationlogic2"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  Cfconditionalquestionanswerforms: {
    component: Cfconditionalquestionanswerforms,
    path: "/Cfconditionalquestionanswerforms"
  },
  DigitalSignatures: {
    component: DigitalSignatures,
    path: "/DigitalSignatures"
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: "/ProjectTemplates"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  ThemeBlock: {
    component: ThemeBlock,
    path: "/ThemeBlock"
  },
  CreateTheme: {
    component: CreateTheme,
    path: "/CreateTheme"
  },
  Xmlcsvtemplatebuilder: {
    component: Xmlcsvtemplatebuilder,
    path: "/Xmlcsvtemplatebuilder"
  },
  ProgessTracker: {
    component: ProgessTracker,
    path: "/ProgessTracker"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: "/SaveAsPdf"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  NotificationsDetails: {
    component: NotificationsDetails,
    path: "/NotificationsDetails"
  },
  Audittrail2: {
    component: Audittrail2,
    path: "/Audittrail2"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Cfsureprepapiintegrationtodigestreceiptsandtaxforms: {
    component: Cfsureprepapiintegrationtodigestreceiptsandtaxforms,
    path: "/Cfsureprepapiintegrationtodigestreceiptsandtaxforms"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  UserLibrary: {
    component: UserLibrary,
    path: "/UserLibrary"
  },
  PdfDocument: {
    component: PdfDocument,
    path: "/PdfDocument"
  },
  SubFolder: {
    component: SubFolder,
    path: "/SubFolder"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  AccountCreation: {
    component: AccountCreation,
    path: "/AccountCreation"
  },
  PeopleWeb: {
    component: PeopleWeb,
    path: "/PeopleWeb"
  },
  InvoiceDetails: {
    component: InvoiceDetails,
    path: "/InvoiceDetails"
  },
  Repeatingtasks2: {
    component: Repeatingtasks2,
    path: "/Repeatingtasks2"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Search: {
    component: Search,
    path: "/Search"
  },
  Profile: {
    component: Profile,
    path: "/Profile"
  },
  Notes: {
    component: Notes,
    path: "/Notes"
  },
  UserGroup: {
    component: UserGroup,
    path: "/UserGroup"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  ImportExportListing: {
    component: ImportExportListing,
    path: "/ImportExportListing"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  BulkUpload: {
    component: BulkUpload,
    path: "/BulkUploading"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Documents: {
    component: Documents,
    path: "/Documents"
  },
  HomePage: {
    component: HomePage,
    path: "/HomePage"
  },
  CreateprojectPage: {
    component: CreateprojectPage,
    path: "/Createproject"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailLogin: {
    component: EmailLogin,
    path: "/EmailLogin"
  },
  Projectnotes: {
    component: Projectnotes,
    path: "/Projectnotes"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  TwoFactorAuthentication:{
    component:TwoFactorAuthentication,
   path:"/TwoFactorAuthentication"},
  Breadcrumbnavigation2: {
    component: Breadcrumbnavigation2,
    path: "/Breadcrumbnavigation2"
  },
  Ocropticalcharacterrecognition2: {
    component: Ocropticalcharacterrecognition2,
    path: "/Ocropticalcharacterrecognition2"
  },
  OcrDocuments: {
    component: OcrDocuments,
    path: "/OcrDocuments"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Salesreporting: {
    component: Salesreporting,
    path: "/Salesreporting"
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const mq = window.matchMedia('(max-width: 768px)');
const mqMIN = window.matchMedia('(min-width: 768px)');

class App extends Component {
  constructor(props) {
    super(props);
    // this.handleStorage = this.handleStorage.bind(this);
    this.state = {
      sidebarHideShow: true,
       showModalPdf:false,
       saveAspdf1:false,
       convert_to_pdf_attachment_id:"",
       convert_to_pdf_pdfId:""

    };
  }

  componentWillUnmount() {
    mq.removeListener(this.toggle);
  }


  convertOtherFile=()=>{
    this.setState({showModalPdf:true,saveAspdf1:false})
  }

handleNavigate=()=>{
  this.setState({showModalPdf:true})
}

handleClose=()=>{
  this.setState({showModalPdf:false})
}

handleShwmodalSavepdf = (id, fileId) => {
  this.setState({
    saveAspdf1: true,
    showModalPdf: false,
    convert_to_pdf_attachment_id: id,
    convert_to_pdf_pdfId: fileId
  })
}

handleCloseSavepdf=()=>{
  this.setState({saveAspdf1:false})
}

  maxToggle = () => {
    if (mqMIN.matches) {
      // do something here
      this.setState({ sidebarHideShow: !this.state.sidebarHideShow })
    }
  }

  toggle = () => {
    if (mq.matches) {
      // do something here
      this.setState({ sidebarHideShow: !this.state.sidebarHideShow })
    } else {
      // do something here
    }
  };

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const showSideMenues = () => {
      const location = createBrowserHistory()
      const URLcheck = location.location.pathname;
      if ("/" === URLcheck ||"/EmailLogin" === URLcheck ||"/TwoFactorAuthentication" === URLcheck || "/TermsAndConditions" === URLcheck || "/XmlcsvtemplateBuilder"=== URLcheck ||"/EmailAccountRegistration" === URLcheck || "/ForgotPassword" === URLcheck || "/NewPassword" === URLcheck) {
        console.log("location.location.pathname :", "/EmailAccountLoginBlock" === location.location.pathname)
        return true;
      }
      if ("/AdminConsole" === URLcheck) {
        window.open(`${baseURL}/admin`, '_blank')
      }
      return false;
    }
    const getThemeColor = () => {
      let storedColor = JSON.parse(localStorage?.getItem("themeColor")||"");
      let primaryColor=storedColor?.primary_color
      if (primaryColor && primaryColor.startsWith('"') && primaryColor.endsWith('"')) {
        primaryColor = primaryColor.slice(1, -1);
      }      
      return primaryColor || "#E57727"; 
    };
    const getSecondaryThemeColor = () => {
      let storedColor = JSON.parse(localStorage?.getItem("themeColor")||"");
      let secondaryColor=storedColor?.secondary_color
      if (secondaryColor && secondaryColor.startsWith('"') && secondaryColor.endsWith('"')) {
        secondaryColor = secondaryColor.slice(1, -1);
      }      
      return secondaryColor || "#E57727"; 
    };

    return (
      <ModelProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {/* <SidebarMenu show={true} /> */}
        {/* {WebRoutesGenerator({ routeMap })} */}
        {/* <ModalContainer /> */}

        <Grid container style={{ overflow: 'hidden', flexWrap: 'nowrap', height: '100vh' }}>
          {showSideMenues() ? (
            <Grid item xs={12} className='SiteCmpWrapperWithoutNavbar backGroundImage'>
              {/* <TopNav /> */}
              {WebRoutesGenerator({ routeMap })}
              <ModalContainer />
            </Grid>
          ) : (
            <>
              {/* <ContextData.Provider value={this.state.LangToggle}> */}
              {window.location.pathname !=="/Search" && window.location.pathname !=="/ImportExportData"  &&window.location.pathname !=="/InvoiceBilling" &&window.location.pathname !=="/InvoiceDetails" && window.location.pathname !=="/ThemeBlock" && window.location.pathname !=="/CreateTheme" && window.location.pathname !== "/NotificationsDetails" && window.location.pathname !== "/PdfDocument"?
              <TopBar
              updateColor={getThemeColor()}
              updateColor1={getSecondaryThemeColor()}
              state={this.state.showModalPdf} handleNavigate={this.handleNavigate} handleClose={this.handleClose} state1={this.state.saveAspdf1}
              handleShwmodalSavepdf={this.handleShwmodalSavepdf} handleCloseSavepdf={this.handleCloseSavepdf} convertOtherFile={this.convertOtherFile} {...this.state}/>:null}
              {/* <Grid item xs={3}> */}
              <div className={`${this.state.sidebarHideShow ? 'sidebarShow' : 'd-show-hide'} test`}>
                <SidebarMenu show={this.state.sidebarHideShow} onClickOutside={(val) => { this.setState({ sidebarHideShow: val }) }} />
              </div>
              {/* </Grid> */}
              {/* <Grid item xs={9} className='SiteCmpWrapper'> */}
              {/* <TopNav /> */}
              <div className='SiteCmpWrapper'>
                {WebRoutesGenerator({ routeMap })}
                <ModalContainer />
              </div>
              {/* </Grid>  */}
              {/* </ContextData.Provider> */}
            </>
          )}
        </Grid>

      <ToastContainer />
      </View>
      </ModelProvider>
    );
  }
}

export default App;
