import React, { ChangeEvent, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, CircularProgress, OutlinedInput, Grid, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, TextField, Typography, } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Formik,useFormik } from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            gap: '40px',
            borderRadius: '8px',
            width: '600px'
        }
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        display:"flex",
        justifyContent: 'end',
        padding: "24px 37px 24px 381px",
        borderTop: "1px solid #E2E6F0",
        gap:"20px",
    
        '& button': {
            padding: '16px 20px',
            width: '182px',
            borderColor: "#E57727",
            fontWeight: 700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: "#E57727",
    },
      "text-transform": "none",
      "color": "white",
      cursor: "pointer"
    },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        background: "#757575",
        '&:hover': {
          backgroundColor: "#757575",
        },
        borderRadius: '8px',
    },
    reasonsSection: {
        marginBottom: '25px !important',
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary'
    },
    errorInputStyle:{
      border: '1px solid red'
    }
})
const enumXmlCsv={
    XMLCHECK:"xml",
    CSVCHECK:"csv"
}
const initialValues={ columnNum: "", newPassword: "",inputColumns:[] }

type CreateFileProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    btnColor?:string;
    subHeading?: string;
    typeSelected?: boolean;
    showCrossBtn?: boolean;
    handleRadio:any;
}




const CreateFile = (props:CreateFileProps) => {
    const classes = useStyles();
    const [dialogReason,setDialogReason]= useState("")
    const [inputValues,setInputValues]= useState<string[]>([])
    const [next,setNext]= useState(false)

   const handleInputChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.target.value;
        if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
            setInputValues(prevInputValues => {
                const newValues = [...prevInputValues];
                newValues[index] = value;
                return newValues;
              });
        }
    
      };
      const columnErrorMessage = (value: string, context: { index: number }) => {
        const { index } = context;
        return `Please enter column ${index + 1} name`;
      };
    const getValidationSchema = (columnCount:any) => {
     return Yup.object().shape ({
        columnNum: Yup.string()
            .required("Please enter column number")
            .matches(/^[1-9][0-9]*$/, "Column number should only contain digits")
            .test('valid-column', 'Invalid column number', (value?: string) => {
                if (!value || value === "" || value.startsWith("0")) {
                    return false;
                }
                return parseInt(value) <= 20;
            }),
            inputColumns: Yup.array()
            .of(
                Yup.string().test('is-empty', 'Column name is required', function(value) {
                    // In this custom test, `this` is the test context which includes path and more
                    if (!value) {
                        // You can access the path, which includes the array index
                        const path = this.path; // e.g., inputColumns[0]
                        const match = path.match(/\[(\d+)\]$/);
                        const index = match ? parseInt(match[1], 10) + 1 : ''; 
                        console.log(path,":PATH",match,":MATCH",index,":INDEX");
                        
                        return this.createError({ message: `Please enter column ${index} name` });
                    }
                    return true; // return true if validation passes
                })
            )

           });
        }

          
     const handleChangeReason = (value: string) => {
        // const inputValue = value;
        // const numericValue = inputValue === "" ? 0 : parseFloat(inputValue);
        setDialogReason(value)
      }
      const handleNext = (validateForm: () => Promise<any>, setTouched: (fields: any) => void) => {
        validateForm().then(errors => {
            if (!errors.columnNum) {
                setNext(true);
            } else {
                setTouched({ columnNum: true });
            }
        });
    };
      const handleBack=()=>{
        setNext(false)
      }
      
  return (
    <>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                <Close
                    className={classes.downloaIcon}
                    // onClick={props?.handleCancel}
                    onClick={() => {
                      props?.handleCancel()
                    }} 
                />
           </div>
        </DialogTitle>
        <Formik
                initialValues={{ columnNum: '', inputColumns: Array(dialogReason).fill("") }}
                validationSchema={getValidationSchema(parseInt(dialogReason))}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                    console.log("Form Values", values);
                    props.handleOk(values);
                // actions.resetForm();
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  values,
                  setFieldValue,
                  validateField,
                  handleChange,
                  validateForm, setTouched
                }) => (
                    console.log("INSIDE LOG 111",
                    touched,
                    errors,
                    values),
                  <form onSubmit={handleSubmit}>

       <DialogContent style={{ padding: "0px 40px"}}>

          {!next && <><Grid className={classes.reasonsSection} item xs={12}>
          
               <label style={{marginBottom:"7px"}}>Enter the number of columns</label>
               <OutlinedInput
                   className={classes.textInputStyle}
                   name='columnNum'
                   id="txtInput"
                   value={dialogReason}
                   placeholder='Enter number of columns'
                   fullWidth
                   color='primary'
                   onBlur={handleBlur}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = event.target.value;
                    handleChange(event); 
                    handleChangeReason(inputValue);
                  }}
               />

          
                    {touched.columnNum && errors.columnNum && (
                    <Typography color="error" >{errors.columnNum}</Typography>
                    )}
                
          </Grid>

          <Grid className={classes.reasonsSection} item xs={12}>
           <label>Select the type of file:-</label>
          <FormControl>
               <RadioGroup row aria-labelledby="demo-row-radiobutton-group-label" name={"typeSlected"}
                   defaultValue={props.typeSelected ? enumXmlCsv.XMLCHECK : enumXmlCsv.CSVCHECK}
                   value={props.typeSelected ? enumXmlCsv.XMLCHECK : enumXmlCsv.CSVCHECK}
                   onChange={(event:ChangeEvent<HTMLInputElement>) => {props.handleRadio?.(event.target.value);
                }}
               >
                   <FormControlLabel checked={props.typeSelected === true } value={enumXmlCsv.XMLCHECK} control={<Radio color="primary" />} label="XML" />
                   <FormControlLabel checked={props.typeSelected === false } value={enumXmlCsv.CSVCHECK} control={<Radio color="primary" />} label="CSV" />
               </RadioGroup>
           </FormControl>
          </Grid></>}

          {next && <Grid id="renderInput" className={classes.reasonsSection} item xs={12}>
            {console.log(dialogReason,"dialogReason", typeof dialogReason)}
        {Array.from({ length: parseFloat(dialogReason) }, (values, index) => (
        console.log(parseFloat(dialogReason),"dialogReason INSIDE", typeof parseFloat(dialogReason)),
          <div key={index} >
            <label style={{ fontSize: "16px", fontWeight: 700 }}>Column {index + 1} name</label>
            <OutlinedInput
              id={`input-${index}`}
              value={inputValues ?inputValues[index] :""}
              style={{
                marginTop: "10px",
                marginBottom: "20px"
              }}
              name={`inputColumns[${index}]`}
              type="text"
              fullWidth
              placeholder={`Enter column ${index + 1} name`}
              color='primary'
              onChange={(event:ChangeEvent<HTMLInputElement>) => {
                handleInputChange(event, index);
                handleChange(event)
              }}
              onBlur={(event) => setFieldValue(`inputColumns[${index}]`, event.target.value)}
              />
              {touched.inputColumns && errors.inputColumns && errors.inputColumns[index] && (
                  <Typography color="error">{errors.inputColumns[index]}</Typography>
              )}
          </div>
        ))}
          </Grid>  }
       </DialogContent>
        
    {/* <DialogActions className={classes.btnWrapper}> */}

<Grid className={classes.btnWrapper}>
{!next &&(<><Button  className={classes.cancelBtnStyle} style={{background:"#F6F2EF",color:"#E57727"}} onClick={props.handleCancel}>
                            Cancel
                        </Button>
<Button className={dialogReason =="" ?classes.disableBtn:classes.primaryBtnStyle} disabled={dialogReason ==""} style={{background:dialogReason=="" ? "#757575":props.btnColor,color:"#FFF"}} onClick={() => handleNext(validateForm, setTouched)}>
                            Next
                        </Button></>)}
    {next &&(<> 
        <Button className={classes.cancelBtnStyle} style={{background:"#F6F2EF",color:"#E57727"}} onClick={handleBack}>
        Back
    </Button>
    <Button
    variant="contained"
    className={classes.primaryBtnStyle}
    style={{ background: props.btnColor }}
    type='submit' 
>
    {props.btnOkText}
</Button></>)}
    </Grid>          
        
{/* 
        </DialogActions> */}
        </form>
                )}
        </Formik>

      </Dialog>
    </>
  )
}

export default CreateFile

