// Customizable Area Start
import React from "react";
import {
  Container,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from "@material-ui/core";
import ProjectTemplatesController, {
  Props,
} from "./ProjectTemplatesController.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


export default class EmailTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    return (
      <Container maxWidth={"xl"} >
  <div style={{display:"grid",gap:"30px"}}>
  <div className={this.props.classes.btnDiv}>
  <Button id="openEmailTemplate" style={emailStyle.createEmailBtn} onClick={() => this.props.handleOpen()}>Create Template</Button>
  <Button style={emailStyle.copyEmailBtn}>Copy from liberary</Button>

  </div>

  <Table>
      <TableHead>
        <TableRow
          style={{
            height: "2.43rem",
            borderRadius: " 0.375rem",
            border: "1px solid #CBD5E1",
          }}
        >
          <TableCell />
          <TableCell
            // style={webStyle.tabelCell}
          >
            Email Templates Name
          </TableCell>
        </TableRow>
      </TableHead>
      {/* <br /> */}
      {this.props.List !== undefined ? (
        <TableBody>
          {this.props.List.map((item: any, index: number) => (
            <React.Fragment key={item?.id}>
              <TableRow
                style={emailStyle.tableRow}
                key={item?.id}
              >
                <TableCell style={{
                    width: "4%",
                    padding:"0px 10px "
                  }}>
          <IconButton aria-label="expand row" size="small" onClick={() => this.OpenDropDown()}>
            {this.state.openDropDown ? <KeyboardArrowDownIcon />:<KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
                <TableCell
                  data-test-id={`project-${index}`}
                  style={emailStyle.tableCell}
                >
                  {item.attributes.template_name}
                </TableCell>
               
              </TableRow>

              {/* <div style={{ marginBottom: "0.8rem" }}></div> */}
              
            </React.Fragment>
          ))}
        </TableBody>
      ) : (
        <div
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#e87424",
          }}
        >
          Emails not found
        </div>
      )}
    </Table>
</div>
      </Container>
    );
  }
}

const emailStyle={
  createEmailBtn:{display: "flex",
  width: "210px",
  height: "50px",
  padding: "10px 16px",
  justifyContent: "center",
  textTransform:"none" as const,
  borderRadius: "50px",
  background: "#396178",
  alignItems: "center",
  color: "#FFF",
  textAlign: "center" as const,
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  gap: "8px",
  flexShrink: 0,},
  copyEmailBtn:{display: "flex",
  width: "210px",
  height: "50px",
  padding: "10px 16px",
  justifyContent: "center",
  textTransform:"none" as const,
  borderRadius: "50px",
  border: "1px solid #396178",
  alignItems: "center",
  color: "#396178",
  textAlign: "center" as const,
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  gap: "8px",
  flexShrink: 0,},
  tableRow:{
    borderRadius: "0.375rem",
    border: "1px solid #CBD5E1",
    padding: "0.625rem",
    height: "1.5rem",
  },
  tableCell:{
    width: "96%",
    padding:"10px",
    color: "#396178",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  }
}

// Customizable Area End