// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  List,
  ListItem, 
  ListItemText,
  Link,
  Breadcrumbs, 
} from "@material-ui/core";
import { AddCircleOutline, NavigateNext } from '@material-ui/icons';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Link as Redirect } from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

import ImportExportDataController, {
  Props,
} from "./ImportExportDataController.web";
import ImportDialog from "../../../components/src/ImportMapping";
import FileUploader from "../../../components/src/ImportCsvXml";
import { imgExport, imgImport, imgSuccess } from "./assets";
import CreateFile from "../../../components/src/CreateFile";
import { Alert } from "@material-ui/lab";

export class ImportExport extends ImportExportDataController {
  constructor(props: Props) {
    super(props);

  }


  render() {
    const {classes} = this.props
    const breadcrumbDataImport= [
      <Link underline="hover" key="1" color="inherit">
        <Redirect to="/UserLibrary" className={classes.redirection}>Documents</Redirect>
      </Link>,
    
      <Typography key="3" className={classes.currentPage} style={{textTransform:"capitalize"}}>
        Data Import
      </Typography>,
    ];

    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
        <Box className={classes.topPage}>
                        <Box className={classes.topDiv}>

                            <Breadcrumbs
                                    className={classes.breadCrumb}
                                    separator={<NavigateNext fontSize="small" />}
                                    aria-label="breadcrumb"
                                  >
                                    {breadcrumbDataImport}
                                  </Breadcrumbs>
                            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "12px", height: "44px", paddingRight: "48px" }}>
                            

                            <Button 
                                onClick={this.IEListing}
                                  data-test-id="fileListing" style={{
                                    "display": "flex", "height": "2.75rem", "padding": "10px 20px 10px 8px", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": "#FFF"

                                }}>
                                  <Typography style={{ "color": "#E57727", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Files</Typography></Button>

                            <Button 
                                onClick={this.openImortData}
                                  data-test-id="importCsv" style={{
                                    "display": "flex", "height": "2.75rem", "padding": "10px 8px", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": "#FFF"

                                }}><img src={imgImport} style={{marginRight:"8px",width:"24px",height:"24px"}}/>
                                  <Typography style={{ "color": "#E57727", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Import</Typography></Button>


                              <Button 
                                onClick={this.openCreateFile}
                                  data-test-id="searchbtn" style={{
                                    "display": "flex", "height": "2.75rem", "padding": ".625rem 1rem", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": "#E57727",

                                }}> <AddCircleOutline style={{color:"#FFF",marginRight:"8px"}}/>
                                  <Typography style={{ "color": "#FFF", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Create file</Typography></Button>


                            </div>

                        </Box>
         
          <Box sx={webStyle.mainWrapper}>
          <div style={{display:"flex",gap:"15px",flexDirection:"column",background:"#F1F4F9",paddingBottom:"20px"}}>
          <Typography style={{fontSize: "24px",
          fontWeight: 700,
          fontFamily: "Inter",}}>Import files</Typography>
        <Typography component="div">
          Files must be formatted as a .CSV or .XML file.
        </Typography>
          </div>
          <div style={{ width: "calc(100vw - 233px)", padding: '20px' }}>
        <List>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>1</Typography>
            <Typography>Import CSV or XML file</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>2</Typography>
            <Typography>Copy and paste your client details into the spreadsheet</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>3</Typography>
            <Typography>Remove any categories you don't require</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>4</Typography>
            <Typography>Save and re-upload your file</Typography>
            </ListItemText>
          </ListItem>
        </List>
        <div>
          
        <input
          type="file"
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
          id="fileInput"
          accept=".csv, text/csv, .xml, application/xml, text/xml"
        />

      <label
        htmlFor="fileInput"
        data-test-id="upload"
        tabIndex={0}
        onKeyPress={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            const fileInput = document.getElementById('fileInput');
            if (fileInput) fileInput.click();
          }
        }}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        style={{
          display: 'flex', 
          border: '2px dashed #5678B3',
          padding: '20px',
          cursor: 'pointer',
          width:"95%",
          height:"100px",
          alignItems:"center",
          justifyContent:"center",
          borderRadius: '8px', 
        }}
        aria-label="Drag and drop file upload area"
      >
        {this.state.selectedFile ? <p>{this.state.selectedFile.name}</p> : <Typography style={{color:"#5678B3",fontSize:"16px"}}>Click here or drag to upload</Typography>}
      </label>
    </div>
      </div>
          </Box>
          <ImportDialog
            openDialog={this.state.mapping}
            headingText= "Check before importing"
            handleCancel={this.handleMapping}
            yourCategory={this.state.columnNames}
            handleOk={this.handleImportFile}
          />
                <FileUploader onFileUpload={this.handleImportFileUpload} 
                accept=".csv, application/vnd.ms-excel, text/csv, application/xml, text/xml"
                openDialog={this.state.importData}
                headingText="Import Data"
                handleCancel={this.openImortData}
                />
                <CreateFile
                openDialog={this.state.createFile}
                headingText="Create File"
                btnCancelText="Cancel"
                btnOkText="Create"
                handleOk={this.handleCreateFile}
                handleCancel={this.openCreateFile}
                btnColor="#E57727"
                handleRadio={this.handleRadio}
                typeSelected={this.state.typeSelected}
                />
          </Box>
          {this.state.showPop &&
              <Alert className={ classes.alertDis } icon={false} severity="success">
                <div className={classes.messags} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.PopMessage}</Typography>
                </div>
              </Alert>}
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as const,
    marginTop:"30px",
    marginLeft:"30px",
    background: "#fff",
    borderRadius:"10px"
  },
  redirection: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px",
    '&:hover': {
      textDecoration: "none",
    },
    '@media (max-width: 530px)': {
      fontSize: "18px",
    },
  },
  currentPage: {
    color: "#E57727",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px",
    '@media (max-width: 768px)': {
      width:"237px"
    },
    '@media (max-width: 530px)': {
      fontSize: "16px",
      width:"137px"
    },
  },
  topPage:{ 
    display: "flex", flexDirection: "column" as const, 
    width: "calc(100vw - 135px)", position: 'fixed' as const,
    top: "0px", right: "0px", alignItems:"flex-start" as const ,background: "#F1F4F9",
    height:"100vh",
    '@media (max-width: 768px)': {
      overflowY: "auto",
    },
  },
  listItemText:{"& .MuiTypography-displayBlock":{display:"flex"}},
  buttonStyle: {
    marginTop: "30px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  redirectStyle:{
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px"
  },
  indexStyle:{
    marginRight: "10px",
    width: "25px",
    border: "1px solid gray",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    '@media (max-width: 768px)': {
      width:"34px",
      height:"27px"
    },
  },
  messags: { display: "flex", gap: "8px" },
  alertDis: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    position: "absolute" as const,
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  breadCrumb:{
    display:"flex",
    '@media (max-width: 768px)': {
      "& .MuiBreadcrumbs-ol":{
        flexWrap:"nowrap"
      }
    },
  },
  topDiv:{
      "display": "flex",background:"#fff",
       "height": "32px", "padding": "20px 1154px 20px 48px", "justifyContent": "space-between",
      "alignItems": "center", "flexShrink": 0,
      "borderBottom": "1px solid #CBD5E1", width: "calc(100vw - 193px)", position: "sticky" as const ,
    '@media (max-width: 600px)': {
      padding: "17px 396px 20px 13px",
    },
  }
};

export default withStyles(webStyle) (ImportExport)
// Customizable Area End
