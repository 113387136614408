import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, styled, OutlinedInput, Grid, Typography, } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            gap: '40px',
            borderRadius: '8px',
            width: '600px'
        }
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    btnWrapper:{
        display:"flex",
        justifyContent: 'end',
        padding: "24px 37px 24px 381px",
        borderTop: "1px solid #E2E6F0",
        gap:"20px",
    
        '& button': {
            padding: '16px 20px',
            width: '182px',
            borderColor: "#E57727",
            fontWeight: 700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: "#E57727",
    },
      "text-transform": "none",
      "color": "white",
      cursor: "pointer"
    },
    reasonSection: {
        margin: '10px 0'
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    btnClass:{
        color: '#757575',
        borderRadius: "8px",
        textTransform:"capitalize" as const,
        padding: '13px 0px',
        width: '100px',
        borderColor: '#3b8da2',
      },
    downloaIcon: {
        cursor: "pointer"
    },
})

type AddDataProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    btnColor?:string;
    subHeading?: string;
    columnNames?: any;
}




const AddData = (props:AddDataProps) => {
    const classes = useStyles();

      const StyledButton = styled(Button)({
        '&.primaryBtn': {
          background: "#25FA1",
          textTransform: "capitalize",
          color: "white",
          borderRadius: "8px",
        },
        '&.disableBtns': {
          color: "#fff",
          borderRadius: '8px',
          background: '#757575',
        },
      });

    const getValidationSchema = (columnCount:any) => {
     return Yup.object().shape ({

      inputColumns: Yup.array()
      .of(
        Yup.string()
          .required('Column value is required')
          .test(
            'no-special-char-or-space-start', 
            'Column value cannot start with a special character or space', 
            value => {
              // This regex checks if the string starts with a special character or a space
              return !value || !/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]/.test(value);
            }
          )
      )

           });
        }
      
      
  return (
    <>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                <Close
                    className={classes.downloaIcon}
                    onClick={() => {
                      props?.handleCancel()
                    }} 
                />
           </div>
        </DialogTitle>
        <Formik
                initialValues={{ inputColumns: Array(props.columnNames.length).fill("") }}
                validationSchema={getValidationSchema(props.columnNames.length)}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                const { validateForm } = actions;
                validateForm().then(errors => {
                  if (Object.keys(errors).length === 0) {
                    console.log("Form Values", values);
                    props.handleOk(values);
                  }
                });
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  errors,
                  values,
                  setFieldValue,
                  handleChange,
                }) => (
                  <form onSubmit={handleSubmit}>

       <DialogContent style={{ padding: "0px 40px"}}>

       <Grid id="renderInput" className={classes.reasonSection} item xs={12}>
          {props.columnNames?.map((name: string, index: number) => (
            <div key={index}>
              <label style={{fontSize: "16px", fontWeight: 700}}>{`Column name: ${name}`}</label>
              <OutlinedInput
                id={`inputColumns[${index}]`}
                name={`inputColumns[${index}]`}
                type="text"
                style={{marginTop: "10px", marginBottom: "20px"}}
                placeholder={`Enter value of column`}
                fullWidth
                color='primary'
                value={values.inputColumns[index] || ""}
                onChange={handleChange}
                onBlur={(event) => setFieldValue(`inputColumns[${index}]`, event.target.value)}
              />
              {touched.inputColumns && errors.inputColumns?.[index] && (
                  <Typography color="error">{errors.inputColumns[index]}</Typography>
              )}

            </div>
          ))}
        </Grid>
       </DialogContent>

       <DialogActions className={classes.btnWrapper}>
          <Button variant="outlined" id="Cancelbtn" className={classes.btnClass} 
          onClick={() => {
            props.handleCancel();
          }} 
          >
            {props.btnCancelText}
          </Button>
          
          <StyledButton
        variant="contained"
        id="submitBtn"
        type='submit' 
        disabled={values.inputColumns.length ==0}
        className={classes.primaryBtnStyle}
        color="primary"
        autoFocus
      >
        {props.btnOkText}
      </StyledButton>
        </DialogActions>
        </form>
                )}
        </Formik>

      </Dialog>
    </>
  )
}

export default AddData

