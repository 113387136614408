// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import Papa from 'papaparse';
import { parseString } from 'xml2js';
import { downloadFile } from "../../../components/src/CommonPlatte";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any

}

interface S {
  columnNames:string[],
  createFile:boolean,
  isCsv:boolean;
  dialogReason:number,
  typeSelected:boolean;
  xmlcsvList:any;
  showModal:boolean;
  selectedFile:any;
  primaryColor:string;
  Csv:boolean;
  Xml:boolean;
  allFiles:boolean;
  imported:boolean;
  created:boolean;
  exportPageType:boolean;
  exportAsType:boolean;
  exportData:boolean;
  URL:string;
  addData:boolean;
  fileName:string;
  file_Id:string;
  userToken:string;
  alertMes:string;
  showAlert:boolean
}

interface SS {
  id: any;

}

export default class ImportExportListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  jsonDataApiCallId: string = "";
  currentId = 0;
  insertXmlCsvApi:string="";
  deleteFileApi:string="";
  createdListApi:string="";
  importListApi:string="";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
  
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    
    ];
    let userData = localStorage.getItem("loginData");
    let DataParsed = userData && JSON.parse(userData);

    this.state = {
      userToken:DataParsed?.token,
      columnNames:["Id","name"],
      createFile:false,
      dialogReason:0,
      xmlcsvList:[],
      isCsv:false,
      selectedFile:"",
      showModal:false,
      primaryColor:"#E57727",
      Csv:false,
      Xml:false,
      addData:false,
      allFiles:true,
      typeSelected:false,
      created:true,
      imported:false,
      exportPageType:false,
      exportAsType:false,
      exportData:false,
      URL:"",
      fileName:"",
      file_Id:"",
      alertMes:"",
      showAlert:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
  
      if(apiRequestCallId === this.importListApi || apiRequestCallId === this.createdListApi ){
        if (responseJson.data) {
          this.setState({ xmlcsvList: responseJson.data,typeSelected:false,file_Id:"" });
        } 
      }
      if(apiRequestCallId === this.insertXmlCsvApi ){
        if (responseJson.data) {
          this.handleCreatedList()
          this.handleFilter("allFiles")
          this.handleFileFilter("created")
          this.setState({ alertMes: responseJson.message,showAlert:true });
        } 
      }
      if(apiRequestCallId === this.deleteFileApi ){
          this.setState({ alertMes: responseJson.message,showAlert:true });
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.handleCreatedList()
  }

  exportFileAs=()=>{
    downloadFile(this.state.URL,this.state.fileName)
    this.closeExportData()
  }

  handleShowDialog=(id:any)=>{
    this.setState({showModal:true,selectedFile:id})
  }
  openExportData=(url:string,fileName:string)=>{
    this.setState({exportData:!this.state.exportData,URL:url,fileName:fileName})
  }
  openAddData=(id:any)=>{
    this.setState({addData:!this.state.addData,file_Id:id})
  }
  closeExportData=()=>{
    this.setState({exportData:false,URL:"",fileName:""})
  }
  handleExportPage=()=>{
    this.setState({exportPageType:!this.state.exportPageType})
  }
  handleExportAs=()=>{
    this.setState({exportAsType:!this.state.exportAsType})
  }
  fileParsing=(fileName:string,fileUrl:any)=>{
    const fileExtension = fileName.slice(fileName.lastIndexOf('.'));

    switch (fileExtension) {
        case '.xml':
            this.setState({typeSelected:true},()=>this.parseXml(fileUrl));
            break;
        case '.csv':
            this.parseCsv(fileUrl);
            break;
    }
  }

  handleImportList= async()=>{

    this.importListApi = await this.ListApiCall({
      method: 'GET',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/import_file_list`,
      token:this.state.userToken
    })
  }

  handleImportFilteredList= async(type:string)=>{

    this.importListApi = await this.ListApiCall({
      method: 'GET',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/import_file_list?type=${type}`,
      token:this.state.userToken
    })
  }

  handleCreatedList= async()=>{

    this.createdListApi = await this.ListApiCall({
      method: 'GET',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts`,
      token:this.state.userToken
    })
  }
  handleCreateFilteredList= async(type:string)=>{

    this.createdListApi = await this.ListApiCall({
      method: 'GET',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts?type=${type}`,
      token:this.state.userToken
    })
  }

  verifyFile=(type:any)=>{
    switch(this.state.imported){
      case true:
        return this.handleImportFilteredList(type);
      case false:
        return this.handleCreateFilteredList(type);

    }
  }

  getAllFiles=()=>{
    if(this.state.imported){
      this.handleImportList()
    }else{
      this.handleCreatedList()
    }
  }

  handleFilter=(name:any)=>{
    switch(name){
      case "allFiles":
        return this.setState({allFiles:true,Xml:false,Csv:false},()=>this.getAllFiles())
      case "xml":
        return this.setState({allFiles:false,Xml:true,Csv:false},()=>this.verifyFile(name))
      case "csv":
        return this.setState({allFiles:false,Xml:false,Csv:true},()=>this.verifyFile(name))
    }
  }

  handleFileFilter=(name:any)=>{
    switch(name){
      case "created":
        return this.setState({created:true,imported:false},()=>this.handleCreatedList())
      case "imported":
        return this.setState({created:false,imported:true},()=>this.handleImportList())
    }
  }
  handleAddData = (values:any) => {
    this.setState({
      addData: false,
    })
    if (this.state.typeSelected) {
      this.InsertXmlFile(values)
    } else {
      this.InsertCsvFile(values)
    }
  }

  async InsertCsvFile(value:any) {
    let data_CSV = {
      "new_data_row": value.inputColumns
    }
    this.insertXmlCsvApi = await this.ListApiCall({
      contentType: 'application/json',
      method: 'PUT',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/update_csvfile?id=${this.state.file_Id}`,
      body: JSON.stringify(data_CSV),
      token:this.state.userToken
    })
  }
  async InsertXmlFile(value: any) {

    let data_XML = {
      "data":
      {
        "insert_data": true,
        "columns": this.state.columnNames,
        "value": value.inputColumns
      }
    }

    this.insertXmlCsvApi = await this.ListApiCall({
      contentType: 'application/json',
      method: 'PUT',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/${this.state.file_Id}`,
      body: JSON.stringify(data_XML),
      token:this.state.userToken
    })
  }

  async DeleteFile(file_Id: any) {

    this.deleteFileApi = await this.ListApiCall({
      method: 'DELETE',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/${file_Id}`,
      token:this.state.userToken
    })
  }

  parseCsv = (fileUrl: string) => {
    let xhrRequest = new XMLHttpRequest();

    xhrRequest.onreadystatechange = () => {
      if (xhrRequest.readyState === 4) {
        if (xhrRequest.status === 200) {
          const csvData = xhrRequest.responseText;
          const parsedData = Papa.parse(csvData, { header: true });
          const extractedColumnNames = parsedData.meta.fields ?? [];
          console.log(extractedColumnNames,typeof extractedColumnNames,"DE");
          this.setState({ columnNames: extractedColumnNames });
        } else {
          console.error('Error fetching CSV:', xhrRequest.statusText);
        }
      }
    };

    xhrRequest.open("GET", fileUrl, true);
    xhrRequest.send();

  };

  parseXml = (file: string) => {
    let xhrRequest = new XMLHttpRequest();

    xhrRequest.onreadystatechange = () => {
        if (xhrRequest.readyState === 4) {
            if (xhrRequest.status === 200) {
                const xmlData = xhrRequest.responseText;
                parseString(xmlData, (error, result: any) => {
                    if (error) {
                        console.error(error);
                        return;
                    }
                    if (result.XML && result.XML.row) {
                        const columnHeaders = result.XML.row.flatMap((row: any) =>                         
                            row.column.map((column: any) => Object.keys(column))
                        );
                        this.setState({ columnNames: columnHeaders.flat() });
                    } else {
                        console.error('Parsed XML structure is not as expected:', result);
                    }
                });
            } else {
                console.error('Error fetching XML:', xhrRequest.statusText);
            }
        }
    };

    xhrRequest.open("GET", file, true);
    xhrRequest.send();
};


  ListApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token
    };
    const listingRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    listingRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    listingRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    listingRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      listingRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(listingRequestMessage.id, listingRequestMessage);
    return listingRequestMessage.messageId;
  };

}
// Customizable Area End