//@ts-nocheck
// Customizable Area Start
import React from "react";
import {
	Box,
	Button,
	Container,
	Grid,
	Typography,
	Tabs,
	Tab,
	TextField,
	InputAdornment
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from '@material-ui/icons/Edit';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NotesController from "./NotesController.web"
import './Notes.css'
import TabPanel from '../../../components/src/TabPanel.tsx'
import MUIRichTextEditor from 'mui-rte'
import moment from 'moment';



const theme = createTheme({
	palette: {
		primary: {
			main: "#2d6f8f",
			contrastText: "#fff",
		},
	},
});
export class Notes extends NotesController {
	constructor(props: any) {
		super(props);
	}

	componentDidMount(): any {
		this.showNote();
	}

	render() {
		const { value } = this.state;
		return (
			<div>
				<ThemeProvider theme={theme}>
					<Container maxWidth={"xl"} style={{ padding: "0", overflowX: "hidden" }}>
						<Grid container component="main" className="mainContainer">

							{/* code is here  */}
							<Box className="CreateNew">
								<Button variant="contained" className="CreateNewButton" onClick={() => { this.setState({ textEditor: true }) }}>
									<EditIcon />
									<span>Create New</span>
								</Button>
								<TextField
									className="searchField"
									type="search"
									id="search"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										style: { borderRadius: "1.875rem", height: "44px" },
									}}
									placeholder="Search"
									variant="outlined"
									style={{
										width: "25rem",
										borderRadius: "98px",
										border: "1px solid #CBD5E1",
									}}
								// value={this.state.searchedRows}
								// onChange={(searchedRows) => this.requestSearch(searchedRows)}
								/>
							</Box>
							{/* end */}
							<Box className="left-side-tabs"
								sx={{
									flexGrow: 1,
									bgcolor: "background.paper",
									display: "flex",
									gap: '12px',
									minHeight: 224,
									marginBottom: "15px",
								}}
							>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={value}
									onChange={this.tabs_Change}
									aria-label="Vertical tabs example"
									sx={{ width: "33.3%" }}
									className="tabsWrapper"
								>
									{/* HEADING OF TABS */}
									{this.state.NotesData.map((tab: any, index: any) => (
										<Tab
											key={index}
											label={
												<>
													{tab.attributes.name}
													<br />

													<span className="tabs-subheadings">{moment(tab.attributes.created_at).format('h:mmA, MMM D YYYY')}{tab.attributes.name}</span>
													<span></span>
												</>

											}
											{...this.a11yProps(index)}
										/>
									))}
								</Tabs>
								{/* ITEMS INSIDE TABS SHOW IN RIGHT SIDE */}
								{!this.state.textEditor && this.state.NotesData.map((tab: any, index: any) => {
									return (
										value === index &&
										<TabPanel
											key={index}
											value={value}
											index={index}
										>
											<Box className="content-txt">
												<Typography variant="h6">
													<Typography variant="h6">
														{tab.attributes.name}
													</Typography>
												</Typography>
												<Typography>
													{tab.attributes.description}
												</Typography>
											</Box>
										</TabPanel>
									)
								})}
								{this.state.textEditor &&
									<Box className="notesEditorBox">
										<MUIRichTextEditor
											className="MUIRichTextEditor"
											label="Type something here..."
											inlineToolbar={true}
											onChange={this.editor_Change}
											controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "numberList", "bulletList"]}

										/>

										<TextField
											className="notesField"
											type="text"
											id="notes"
											placeholder="Note Title"
											variant="outlined"
											name="name"
											onChange={this.title_Change}
										/>
										<Box className="savenotesWrapper">
											<Button className="savenotes" onClick={this.createNote}>
												Save
											</Button>
										</Box>
									</Box>
								}
							</Box>
						</Grid>
					</Container>
				</ThemeProvider >
			</div>
		);
	}
}

export default Notes;
// Customizable Area End
