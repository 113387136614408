import React, { useEffect, useState } from 'react'
import { optionFrequency, optionStatus } from "./Selected";
import { Formik, useField } from 'formik';
import * as Yup from "yup";
import Select from "react-select";
import {
	Button,
	Box,
	Grid,
	Dialog,
	OutlinedInput,
	Typography,
	TextField
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';


export const CreateProjectForm = ({
	toggleForm,
	handleClose,
	createProjects,
	createSubTask,
	model,
	formType,
	namesArray,
	editProject,
	userList
}: any) => {

	const Fields = namesArray;

	const initialValueCreteForm = {
		user_email: "",
		project_name: "",
		start_date: "",
		end_date: "",
		status: "",
		description: "",
		assignee_email: [],
		frequency: "",
		custom_columns: {
			text: "",
			email: "",
			file: "",
			numbers: "",
			phone: "",
			date: ""
		}
	}
	const validationSchemaCreateForm = Yup.object().shape({
		project_name: Yup.string()
			.min(1, "Title is too short")
			.max(20, "Title is too long")
			.matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Title is invalid')
			.required("Title is required."),
		start_date: Yup.date()
			.min(new Date(new Date().setHours(0, 0, 0, 0)), "Start date cannot be in the past")
			.required('Start date is required'),
		end_date: Yup.date()
			.min(Yup.ref('start_date'), 'Due date should be after start date')
			.required('Due date is required'),
		assignee_email: Yup.string()
			.nullable()
			.required("Email is required.")
			.min(1, "Email is too short")
			.max(50, "Email is too long")
			.email('Invalid email'),

		frequency: Yup.string().required('Select an Frequency'),
		status: Yup.string().required('Select an status'),

		custom_columns: Yup.object().shape({
			date: Fields.includes('date')
				? Yup.date().min(new Date(), "Start date cannot be in the past")
					.required('Start date is required')
				: Yup.string(),
			text: Fields.includes('text')
				? Yup.string().required('Text is required')
				: Yup.string(),
			email: Fields.includes('email')
				? Yup.string().min(1, "Email is too short")
					.email('Invalid email')
					.required('email is required')
				: Yup.string(),
			file: Fields.includes('file')
				? Yup.string().required('File is required')
				: Yup.string(),
			numbers: Fields.includes('numbers')
				? Yup.string().required('Numbers is required')
				: Yup.string(),
			phone: Fields.includes('phone')
				? Yup.string().required('Phone is required')
				: Yup.string(),
		})
	});

	const customStyles: any = {
		//@ts-ignore
		menu: (provided, state) => ({
			...provided,
			color: 'white',
		}),
		//@ts-ignore
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected ? '#F6F2EF' : 'white',
			color: state.isSelected ? 'black' : 'black',
			"&:hover": { backgroundColor: '#F6F2EF' }
		}),
		//@ts-ignore
		control: (provided) => ({
			...provided,
			borderColor: 'black',
		}),
	};

	const [userListData, setuserListData] = useState([]);

	useEffect(() => {
		setuserListData(userList)
	}, [userList])

	return (
		<>
			{!toggleForm ?
				<Box>
					<Dialog className="add-subtask"
						data-test-id="add-subtask-model"
						// onClose={() => handleClose()}
						//@ts-ignore
						open={model}
					>

						<div className="modal-dailog">
							<div className="modal-header">
								<h2 style={{ margin: 0 }} className="modal-title">{formType === "EDIT_PROJECT" ? "Edit Project" : "Create Project"}</h2>
								<Button style={{ margin: 0 }} data-test-id="model-open" onClick={() => handleClose()} className="modal-title">X</Button>
							</div>
							<div className="modal-body">
								<Formik
									initialValues={initialValueCreteForm}
									validationSchema={validationSchemaCreateForm}
									validateOnChange={false}
									validateOnMount={true}
									validateOnBlur={true}
									onSubmit={(values) => {
										formType === "EDIT_PROJECT" ? editProject(values) : createProjects(values);
									}}
								>
									{({
										handleSubmit,
										touched,
										handleBlur,
										errors,
										values,
										handleChange,
										setFieldValue
									}) => (<>
										<form className="subtask-form" onSubmit={handleSubmit}>
											<Grid container spacing={2}>

												<Grid item xs={12}>
													<label className="subtask-label">User Email</label>
													<Autocomplete
														disablePortal
														className="subtask-field-new"
														id="combo-box-demo"
														value={values.user_email}
														options={userListData.map((user: any) => user?.attributes?.email)}
														getOptionLabel={(user: any) => user || ''}
														onChange={(event, newValue) => handleChange({ target: { name: 'user_email', value: newValue } })}
														renderInput={(params: any) => (
															<TextField
																{...params}
																fullWidth
																name="user_email"
																id="user_email"
																placeholder="Enter User Email"
																onBlur={handleBlur}
																value={values.user_email}
																onChange={handleChange}
															/>
														)}
													/>
												</Grid>


												<Grid item xs={12}>
													<label className="subtask-label">Title</label>
													<OutlinedInput
														className="subtask-field"
														fullWidth
														data-test-id="projectName"
														name="project_name"
														id="project_name"
														value={values.project_name}
														onBlur={handleBlur}
														onChange={handleChange}
														placeholder="Enter title"
													/>
													{touched.project_name && errors.project_name && (
														<Typography color="error" data-test-id="project_name_error">{errors.project_name}</Typography>
													)}

												</Grid>

												<Grid item xs={12}>
													<label className="subtask-label">Status:-</label>
													<Select
														styles={customStyles}
														data-test-id="status"
														options={optionStatus}
														// value={optionStatus.find((option: any) => option.value == Number(values.status))}
														name="status"
														onChange={(option: any) =>
															setFieldValue('status', option?.value)
														}
													/>
													{touched.status && errors.status && (
														<Typography color="error" >{errors.status}</Typography>
													)}
												</Grid>

												<Grid item xs={6} >
													<label className="subtask-label">Start date</label>
													<TextFieldControl
														className="subtask-field"
														data-test-id="startDate"
														id={`start_date`}

														name={`start_date`}
														type={"date"}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{touched.start_date && errors.start_date && (
														<Typography color="error" >{errors.start_date}</Typography>
													)}
												</Grid>

												<Grid item xs={6} >
													<label className="subtask-label">Due date</label>
													<TextFieldControl
														className="subtask-field"
														data-test-id="endDate"
														id={`end_date`}
														name={`end_date`}
														type={"date"}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{touched.end_date && errors.end_date && (
														<Typography color="error" >{errors.end_date}</Typography>
													)}
												</Grid>

												<Grid item xs={12}>
													<label className="subtask-label">Frequency</label>
													<Select
														styles={customStyles}
														name="frequency"
														data-test-id="frequency"
														options={optionFrequency}
														// value={optionFrequency.find((option: any) => option.value === Number(values.frequency))}
														onChange={(option: any) =>
															setFieldValue('frequency', option?.value)
														}
													/>
													{touched.frequency && errors.frequency && (
														<Typography color="error" >{errors.frequency}</Typography>
													)}
												</Grid>

												<Grid item xs={12}>
													<label className="subtask-label">Assignee</label>
													<OutlinedInput
														className="subtask-field"
														fullWidth
														data-test-id="assigneeEmail"
														name="assignee_email"
														id="assignee_email"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.assignee_email}
														placeholder="Enter your email ID"
														error={touched.assignee_email && Boolean(errors.assignee_email)}
													/>
													{touched.assignee_email && errors.assignee_email && (
														<Typography color="error" >{errors.assignee_email}</Typography>
													)}

												</Grid>
												{Fields.includes("text") && (
													<Grid item xs={12}>
														<label className="subtask-label">Text</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.text"
															id="text"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.text}
															placeholder="Enter text"
															error={touched.custom_columns?.text && Boolean(errors.custom_columns?.text)}
														/>
														{touched.custom_columns?.text && errors.custom_columns?.text && (
															<Typography color="error" >{errors.custom_columns?.text}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("email") && (
													<Grid item xs={12}>
														<label className="subtask-label">Email</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.email"
															id="email"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.email}
															placeholder="Enter email"
															error={touched.custom_columns?.email && Boolean(errors.custom_columns?.email)}
														/>
														{touched.custom_columns?.email && errors.custom_columns?.email && (
															<Typography color="error" >{errors.custom_columns?.email}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("file") && (
													<Grid item xs={12}>
														<label className="subtask-label">File</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.file"
															id="file"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.file}
															placeholder="Enter file"
															error={touched.custom_columns?.file && Boolean(errors.custom_columns?.file)}
														/>
														{touched.custom_columns?.file && errors.custom_columns?.file && (
															<Typography color="error" >{errors.custom_columns?.file}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("numbers") && (
													<Grid item xs={12}>
														<label className="subtask-label">numbers</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.numbers"
															id="numbers"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.numbers}
															placeholder="Enter numbers"
															error={touched.custom_columns?.numbers && Boolean(errors.custom_columns?.numbers)}
														/>
														{touched.custom_columns?.numbers && errors.custom_columns?.numbers && (
															<Typography color="error" >{errors.custom_columns?.numbers}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("phone") && (
													<Grid item xs={12}>
														<label className="subtask-label">Phone</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.phone"
															id="phone"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.phone}
															placeholder="Enter phone"
															error={touched.custom_columns?.phone && Boolean(errors.custom_columns?.phone)}
														/>
														{touched.custom_columns?.phone && errors.custom_columns?.phone && (
															<Typography color="error" >{errors.custom_columns?.phone}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("date") && (
													<Grid item xs={12}>
														<label className="subtask-label">Date</label>
														<OutlinedInput
															className="subtask-field"
															type={"date"}
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.date"
															id="date"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.date}
															placeholder="Enter date"
															error={touched.custom_columns?.date && Boolean(errors.custom_columns?.date)}
														/>
														{touched.custom_columns?.date && errors.custom_columns?.date && (
															<Typography color="error" >{errors.custom_columns?.date}</Typography>
														)}
													</Grid>
												)}

											</Grid>
											<Box className="create-subtask-btn-wrapper">
												<button
													style={{
														color: '#fff',
														width: "105px",
														height: "56px",
														fontSize: "16px",
														fontWeight: 700,
														padding: "10px",
														borderRadius: "8px",
														backgroundColor: "E57727",
														border: '0px'
													}}
													type='submit'
												>
													{formType === "EDIT_PROJECT" ? "Save" : "Create"}
												</button>
											</Box>
										</form>
									</>
									)}
								</Formik>
							</div>
						</div>
					</Dialog>
				</Box>
				:
				<Box>
					<Dialog className="add-subtask"
						data-test-id="add-subtask-model"
						// onClose={() => handleClose()}
						//@ts-ignore
						open={model}
					>

						<div className="modal-dailog">
							<div className="modal-header">
								<h2 style={{ margin: 0 }} className="modal-title">{formType === "EDIT_PROJECT" ? "Edit Subtask" : "Create Subtask"}</h2>
								<Button style={{ margin: 0 }} data-test-id="model-openSQ" onClick={() => handleClose()} className="modal-title">X</Button>
							</div>
							<div className="modal-body">
								<Formik
									initialValues={initialValueCreteForm}
									validationSchema={validationSchemaCreateForm}
									validateOnChange={false}
									validateOnMount={true}
									validateOnBlur={true}
									onSubmit={(values, actions) => {
										createSubTask(values)
									}}
								>
									{({
										handleSubmit,
										touched,
										handleBlur,
										errors,
										values,
										handleChange,
										setFieldValue
									}) => (<>
										<form className="subtask-form" onSubmit={handleSubmit}>
											<Grid container spacing={2}>

												<Grid item xs={12}>
													<label className="subtask-label">Title</label>
													<OutlinedInput
														className="subtask-field"
														fullWidth
														data-test-id="projectName"
														name="project_name"
														id="project_name"
														value={values.project_name}
														onBlur={handleBlur}
														onChange={handleChange}
														placeholder="Enter title"
													/>
													{touched.project_name && errors.project_name && (
														<Typography color="error" >{errors.project_name}</Typography>
													)}

												</Grid>

												<Grid item xs={12}>
													<label className="subtask-label">Status:-</label>
													<Select
														styles={customStyles}
														data-test-id="status"
														options={optionStatus}
														// value={optionStatus.find((option: any) => option.value == Number(values.status))}
														name="status"
														onChange={(option: any) =>
															setFieldValue('status', option?.value)
														}
													/>
													{touched.status && errors.status && (
														<Typography color="error" >{errors.status}</Typography>
													)}
												</Grid>

												<Grid item xs={6} >
													<label className="subtask-label">Start date</label>
													<TextFieldControl
														className="subtask-field"
														data-test-id="startDate"
														id={`start_date`}

														name={`start_date`}
														type={"date"}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{touched.start_date && errors.start_date && (
														<Typography color="error" >{errors.start_date}</Typography>
													)}
												</Grid>

												<Grid item xs={6} >
													<label className="subtask-label">Due date</label>
													<TextFieldControl
														className="subtask-field"
														data-test-id="endDate"
														id={`end_date`}
														name={`end_date`}
														type={"date"}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{touched.end_date && errors.end_date && (
														<Typography color="error" >{errors.end_date}</Typography>
													)}
												</Grid>

												<Grid item xs={12}>
													<label className="subtask-label">Frequency</label>
													<Select
														styles={customStyles}
														name="frequency"
														data-test-id="frequency"
														options={optionFrequency}
														// value={optionFrequency.find((option: any) => option.value === Number(values.frequency))}
														onChange={(option: any) =>
															setFieldValue('frequency', option?.value)
														}
													/>
													{touched.frequency && errors.frequency && (
														<Typography color="error" >{errors.frequency}</Typography>
													)}
												</Grid>

												<Grid item xs={12}>
													<label className="subtask-label">Assignee</label>
													<OutlinedInput
														className="subtask-field"
														fullWidth
														data-test-id="assigneeEmail"
														name="assignee_email"
														id="assignee_email"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.assignee_email}
														placeholder="Enter email"
														error={touched.assignee_email && Boolean(errors.assignee_email)}
													/>
													{touched.assignee_email && errors.assignee_email && (
														<Typography color="error" >{errors.assignee_email}</Typography>
													)}

												</Grid>

												{Fields.includes("text") && (
													<Grid item xs={12}>
														<label className="subtask-label">Text</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.text"
															id="text"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.text}
															placeholder="Enter text"
															error={touched.custom_columns?.text && Boolean(errors.
																custom_columns?.text)}
														/>
														{touched.custom_columns?.text && errors.custom_columns?.text && (
															<Typography color="error" >{errors.custom_columns?.text}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("email") && (
													<Grid item xs={12}>
														<label className="subtask-label">Email</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.email"
															id="email"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.email}
															placeholder="Enter email"
															error={touched.custom_columns?.email && Boolean(errors.custom_columns?.email)}
														/>
														{touched.custom_columns?.email && errors.custom_columns?.email && (
															<Typography color="error" >{errors.custom_columns?.email}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("file") && (
													<Grid item xs={12}>
														<label className="subtask-label">File</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.file"
															id="file"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.file}
															placeholder="Enter file"
															error={touched.custom_columns?.file && Boolean(errors.custom_columns?.file)}
														/>
														{touched.custom_columns?.file && errors.custom_columns?.file && (
															<Typography color="error" >{errors.custom_columns?.file}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("numbers") && (
													<Grid item xs={12}>
														<label className="subtask-label">numbers</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.numbers"
															id="numbers"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.numbers}
															placeholder="Enter numbers"
															error={touched.custom_columns?.numbers && Boolean(errors.custom_columns?.numbers)}
														/>
														{touched.custom_columns?.numbers && errors.custom_columns?.numbers && (
															<Typography color="error" >{errors.custom_columns?.numbers}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("phone") && (
													<Grid item xs={12}>
														<label className="subtask-label">Phone</label>
														<OutlinedInput
															className="subtask-field"
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.phone"
															id="phone"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.phone}
															placeholder="Enter phone"
															error={touched.custom_columns?.phone && Boolean(errors.custom_columns?.phone)}
														/>
														{touched.custom_columns?.phone && errors.custom_columns?.phone && (
															<Typography color="error" >{errors.custom_columns?.phone}</Typography>
														)}
													</Grid>
												)}
												{Fields.includes("date") && (
													<Grid item xs={12}>
														<label className="subtask-label">Date</label>
														<OutlinedInput
															className="subtask-field"
															type={"date"}
															fullWidth
															data-test-id="assigneeEmail"
															name="custom_columns.date"
															id="date"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.custom_columns.date}
															placeholder="Enter date"
															error={touched.custom_columns?.date && Boolean(errors.custom_columns?.date)}
														/>
														{touched.custom_columns?.date && errors.custom_columns?.date && (
															<Typography color="error" >{errors.custom_columns?.date}</Typography>
														)}
													</Grid>
												)}
											</Grid>
											<Box className="create-subtask-btn-wrapper">
												<button
													style={{
														color: '#fff',
														width: "105px",
														height: "56px",
														fontSize: "16px",
														fontWeight: 700,
														padding: "10px",
														borderRadius: "8px",
														backgroundColor: "E57727",
														border: '0px'
													}}
													type='submit'
												>{formType === "EDIT_PROJECT" ? "Edit" : "Create"}</button>
											</Box>
										</form>
									</>)}
								</Formik>
							</div>
						</div>
					</Dialog>
				</Box>
			}
		</>
	)
};

const TextFieldControl = (props: any) => {
	const [field, meta] = useField(props);

	return (
		<OutlinedInput
			fullWidth
			type={props.type}
			{...field}
			{...props}
			error={meta.error && meta.touched}
		/>
	);
};