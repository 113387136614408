import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TableCell,
  TableRow,
  Table,
  TableBody,
  Collapse,
  Grid,
  Avatar,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  Popover
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import ChevronRight from '@material-ui/icons/ChevronRight';
import MoreArrow from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from "@material-ui/core/LinearProgress";
import { Selected, CustomTableRow, SubtaskTableRow, WorkspaceHeadGrid, optionStatus } from "../../../components/src/Selected";
import { SearchOutlined } from "@material-ui/icons";
import AddSubTask from "../../projectnotes/src/AddSubTask.web";
import "./projectnotes.css"
import { filter, imageUser, message, tick, dotIcon, plus } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import { GroupWorkspaceModel } from "../../../components/src/WorkspaceModel";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
    width: 113,
    marginTop: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#51BEBA",
  },
}))(LinearProgress);
// Customizable Area End

import ProjectnotesController, {
  Props,
  configJSON,
} from "./ProjectnotesController";

export default class Projectnotes extends ProjectnotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount():any  {
    this.getColumn();
  }
  // Customizable Area End

  render() {
    const { anchorEl, selectedItems } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" className="spaceWrap">
          <Box className="filterBox" marginBottom={2}>
            <Box >
              <Box className="filter-switch">
                <button className="active">All</button>
                <button>Today</button>
              </Box>
            </Box>

            <Box className="filter-right">
              <TextField
                style={{ fontFamily: "inter" }}
                type="search"
                placeholder="Search for documents, people, invoices, projects, tasks"
                id="search"
                variant="outlined"
                // onChange={(searchValue) => this.requestSearch(searchValue)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlined style={{ width: "1.5rem", height: "1.5rem" }} />
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: {
                      padding: 0, "fontSize": "16px", "lineHeight": "24px", "fontWeight": 400, "fontFamily": "inter",

                    } as React.CSSProperties,
                  },
                  style: {
                    "display": "flex", "width": "31.5rem", "height": "2.75rem", "alignItems": "center",
                    "flexShrink": 0, "borderRadius": "0.5rem", "background": "#FFF", padding: ".625rem 0.5rem .625rem 0.5rem"
                  },
                }}
                // value={this.state.searchText}
                InputLabelProps={{
                  style: {
                    fontSize: '1rem', fontWeight: 400, lineHeight: "1.5rem", fontFamily: "Inter", color: "#64748B",
                  },
                }}
              />
              <Button onClick={() => { this.setState({ model: !this.state.model }) }} data-test-id="searchbtn" className="filter-right-btn">
                Filter <span><img src={filter} style={{ paddingLeft: '5px' }} alt="" /></span>
              </Button>
            </Box>
          </Box>
          {this.state.groupData.length > 0 && this.state.groupData.map((item: any, groupIndex: any) => {
            return (
              <Box
                className={!this.state.openStates[groupIndex] ? 'spacebox' : 'spaceboxopen'}
                style={{
                  borderLeft: !this.state.openStates[groupIndex] ? `4px solid ${item.attributes.groups.color}` : '',
                }}
                data-test-id="toggle-class"
                key={groupIndex}
              >
                <Table>
                  <TableBody>
                    <CustomTableRow
                      data-test-id="toggle-table-row"
                      open={this.state.open}
                      data={this.rowData}
                      openStates={this.state.openStates}
                      gotoWorkspacePage={this.gotoWorkspacePage}
                      toggleOpenState={this.toggleGroupCollapse}
                      index={groupIndex}
                      item={item}
                    />
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={6} >
                        <Collapse in={this.state.openStates[groupIndex]} timeout="auto" unmountOnExit>
                          <Box key={groupIndex}>
                            <Box className="workspace-project-table">
                              <Grid container spacing={3} className="workspace-head">
                                <Grid item xs={4} className="workspace-project-name">Project name </Grid>
                                <Grid item xs={2} className="head">Payment status</Grid>
                                {this.state.objectByName.date && <Grid item xs={1} className="head">Date</Grid>}
                                <Grid item xs={2} className="head">Company name</Grid>
                                <Grid item xs={1} className="head">Team</Grid>
                                {this.state.objectByName.email && <Grid item xs={1} className="head">Email</Grid>}
                                {this.state.objectByName.numbers && <Grid item xs={1} className="head">Numbers</Grid>}
                                {this.state.objectByName.text && <Grid item xs={1} className="head">Text</Grid>}
                                {this.state.objectByName.phone && <Grid item xs={1} className="head">Phone</Grid>}
                                <Grid item xs={4} className="head">Status</Grid>
                              </Grid>
                              {item.attributes.groups.projects.map((items: any, projectIndex: any) => {
                                return (<React.Fragment key={projectIndex}>
                                  <Box>
                                    <Box>
                                      <Grid container spacing={3} style={{ borderBottom: '1px solid #ccc', height: '70px', margin: '10px -12px !important', flexWrap: 'nowrap' }}>
                                        <Grid
                                          item xs={4}
                                          className={true ? "workspace-project-name text-red" : "workspace-project-name text-black"}
                                        >
                                          <span
                                            data-test-id="span-tag"
                                            onClick={() => this.toggleProjectCollapse(groupIndex, projectIndex)}
                                          >
                                            {this.state.projectOpenStates[groupIndex] && this.state.projectOpenStates[groupIndex][projectIndex] ? <ChevronRight /> : <MoreArrow />}
                                          </span>
                                          {(items?.name)?.replace(/_/g, ' ').replace(/\b\w/g, (match: string) => match.toUpperCase())}
                                          <span className="count">(05)</span>
                                          <span className="msg-count">
                                            3<img style={{ verticalAlign: 'top', marginLeft: '5px' }} src={message}></img>
                                          </span>
                                        </Grid>

                                        <Grid item xs={2} className="head">Received</Grid>

                                        {this.state.objectByName.date && <Grid item xs={1} className="head">{items.start_date}</Grid>}

                                        <Grid item xs={2} className="head company-name"><span>Vincere tax</span></Grid>

                                        <Grid item xs={1} className="head">
                                          <Avatar><img src={imageUser} alt="" />	</Avatar>
                                          <Avatar className="profile" style={{ marginLeft: '-15px' }}>N</Avatar>
                                          <Avatar className="profile" style={{ marginLeft: '-15px' }}>OP</Avatar>
                                        </Grid>

                                        {this.state.objectByName.email && <Grid item xs={1} className="head">{items.email !== undefined ? items.email : ""}</Grid>}
                                        {this.state.objectByName.numbers && <Grid item xs={1} className="head">{items.numbers !== undefined ? items.numbers : ""}</Grid>}
                                        {this.state.objectByName.text && <Grid item xs={1} className="head">{items.text !== undefined ? items.text : ""}</Grid>}
                                        {this.state.objectByName.phone && <Grid item xs={1} className="head">{items.phone !== undefined ? items.phone : ""}</Grid>}

                                        <Grid item xs={4} style={{width: '100%',marginTop: '8px'}} className="select-status">
                                          <Selected  styles={{width: '200px'}}
                                            label="Age"
                                            name="age"
                                            id={`outlined-age-native-simple-${1}`}
                                            options={optionStatus}
                                            onChange={() => { }}
                                          />
                                        </Grid>

                                      </Grid>
                                    </Box>
                                    <Collapse
                                      in={this.state.projectOpenStates[groupIndex] &&
                                        this.state.projectOpenStates[groupIndex][projectIndex]
                                      }
                                      timeout="auto" unmountOnExit>
                                      <Box>
                                        <Box className="task-btns" paddingBottom={1} marginTop={3}>
                                          <button
                                            className="add-subtask-btn" data-test-id="add-subtask-btn"
                                            onClick={() => { this.gotoWorkspacePage(items?.project_id, "TASK") }}
                                          >
                                            Add subtask
                                          </button>
                                          <button
                                            className="add-subtask-btn" 
                                            data-test-id="add-subtask-btn"
                                          >
                                           Audit Trail
                                          </button>
                                          <button
                                            className="edit-subtask-btn" data-test-id="add-subtask-btn"
                                            onClick={() => {
                                              this.gotoWorkspacePage(items?.project_id, "EDIT_PROJECT", item.attributes.groups.id)
                                            }}
                                          >
                                            <EditIcon /> Edit Project
                                          </button>
                                        </Box>
                                        <Box padding={1}>
                                          <Table className="subtask-table">
                                            <SubtaskTableRow
                                             name={items.name}
                                              handleDrawer={this.handleDrawer}
                                              task={items?.tasks}
                                              tick={tick}
                                              message={message}
                                              Task_Id={this.state.Task_Id}
                                              projectId = {items.project_id}
                                            />
                                          </Table>
                                        </Box>
                                      </Box>
                                    </Collapse>
                                  </Box>
                                </React.Fragment>)
                              })}
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )
          })

          }
          <AddSubTask
          //@ts-ignore
            id=""
            navigation=""
            handleDrawer={this.handleDrawer}
            isDrawerOpen={this.state.isDrawerOpen}
            Task_Id={this.state.Task_Id}
            projectId ={this.state.project_id}
          />

          {/* Column feature */}

          <div>
            <Button
              className="popover-main-container"
              aria-describedby={id} variant="contained" color="primary" onClick={this.openPopover}>
              Open Popover
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={this.closePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              className="PopoverComponent"
            >
              <Grid container spacing={2}>
                {['Text', 'Email', 'Numbers', 'Files', 'Phone', 'Date',].map((item ,index) => (
                  <Grid item xs={6} key={index}>
                    <Typography
                      key={item}
                      className={`typography item-text ${selectedItems.includes(item) ? 'group-selected' : ''}`}
                      onClick={() => this.columnItemSelection(item)}
                    >
                      {item}
                    </Typography>
                  </Grid >
                ))}
                <Grid item xs={12} style={{ textAlign: "center", display: "flex", justifyContent: "center" }} >
                  <Button
                    key="HP"
                    className={`add-column-ButtonText addcolumnBtn`}
                    onClick={() => {
                      if (this.state.selectedItems.length > 0) {
                        this.createColumn();
                        setTimeout(() => {
                          this.closePopover();
                        }, 1000);
                      }
                    }}>
                    Add column
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>


          {/* Task Allocator */}
          <div >
            <Dialog
              open={false}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{minWidth:"600px"}}
            >
              <div style={useStyles.statusModalHeader} id="alert-dialog-title">
                <span style={{ width: '90%' }}>Task name </span>
                <CloseIcon />
              </div>
              <DialogContent style={useStyles.statusModalContent}>
                <div style={useStyles.statusDate}>
                  <span>21 Jan 2023</span>
                  <img src={dotIcon}></img>
                </div>
                <Typography style={{...useStyles.progressTitle,color:this.state.secondaryColor}}>
                  In progress
                </Typography>
                <div>
                  <BorderLinearProgress variant="determinate" value={50} />
                </div>
                <DialogContentText style={useStyles.alertDescription} id="alert-dialog-description">
                  Monotonectally drive unique platforms via covalent e-business. Proactively incubate alternative value for market-driven niches. Credibly integrate extensive initiatives rather than end-to-end.
                </DialogContentText>
              </DialogContent>
              <DialogContent >
                <Box style={useStyles.deuDateWrapper}>
                  <Typography>
                    <span style={useStyles.deuDateTitle}>Due Date :</span>
                    <span style={useStyles.deuDate}>22 Junuary 2024</span></Typography>
                </Box>
                <Box style={useStyles.assignWrapper} >
                  <Typography>
                    <span style={useStyles.deuDateTitle}>Assignee:</span>
                  </Typography>
                  <Box style={{ display: 'flex', gap: '20px', marginTop: '15px' }}>
                    <Avatar className="profile">H</Avatar>
                    <img src={plus} alt="+" />
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
          </div>

          {/* Task Tracking */}
          <div >
            <Dialog
              open={false}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{minWidth:"600px"}}
            >
              <div style={useStyles.statusModalHeader} id="alert-dialog-title">
                <span style={{ width: '90%' }}>Task name </span>
                <CloseIcon />
              </div>
              <DialogContent style={useStyles.statusModalContent}>
                <Box style={useStyles.subtitleWrapper}>
                  <Typography style={useStyles.subtitleName}>Subtask name</Typography>
                  <Typography style={useStyles.subTitleDetail}>Sketch out initial wireframes to explore layout and structure</Typography>
                  <div style={useStyles.statusDate}>
                    <span style={useStyles.deuDate}>Feb 11 - Feb 20</span>
                    <Avatar className="profile">H</Avatar>
                  </div>
                </Box>
              </DialogContent>
            </Dialog>
          </div>

          <GroupWorkspaceModel
            createGroup={this.createGroup}
            primaryColor={this.state.primaryColor}
            secondaryColor={this.state.secondaryColor}
          />
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const useStyles = ({
  statusModalHeader: {
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    color: "#0F172A",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    borderBottom: "1px solid #E2E8F0", background: "#FFF",
  },
  statusModalContent: {
    padding: '24px 40px',

  },
  statusDate: {
    color: "#64748B",
    justifyContent: 'space-between',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    display: 'flex',
    alignItems: 'center'
  },
  progressTitle: {
    color: "#E57727",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
  },
  alertDescription: {
    color: "#64748B",
    marginTop: "20px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  deuDateWrapper: {
    padding: "16px",
    display: "block",
    borderTop: "1px solid #E2E8F0"
  },
  deuDateTitle: {
    color: "#64748B",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    marginRight: '10px'
  },
  deuDate: {
    color: "#D97706",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "80px", background: "#FEF3C7",
    padding: '2px 8px',
    lineHeight: "18px",
    height: '20px'
  },
  assignWrapper: {

    padding: "16px",
    display: "block",
    paddingTop: "0"
  },
  subtitleName: {
    color: "#0F172A",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  subTitleDetail: {
    color: "#64748B",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    padding: "10px 0"
  },
  subtitleWrapper: {
    padding: "0 16px 16px 0",
    borderBottom: '1px solid #E2E8F0'
  }
});

// Customizable Area End
