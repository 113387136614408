//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Box, TextField, Typography, Button, InputAdornment, TableRow, TableCell, IconButton, TableHead, Table } from "@material-ui/core";
import { DeleteOutlineOutlined, MoreVert, Search, Sort } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { download, filter1, pdfDoc } from "./assets";
import InvoiceTemplateForm from "../../../components/src/InvoiceTemplateForm";
import AddService from "../../../components/src/AddService";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const startIndex = (this.state.pageNumber - 1) * this.state.dataPerPage;
    const endIndex = startIndex + this.state.dataPerPage;
    
    return (
      <Box sx={billingStyle.mainContentBox}>
          <Box sx={billingStyle.semiContentBox}>

              <Typography style={billingStyle.topHeader}>Invoices</Typography>
              {this.state.userType=="vincertax_employee" && <div style={billingStyle.btnBox}>

                  <Button 
                  onClick={this.handleGenerateInvoice}
                    data-test-id="formbtn" style={billingStyle.generateBtn}><Typography style={billingStyle.generateBtnText} >{configJSON.generateInvoice}</Typography></Button>

                  <Button variant="outlined" style={billingStyle.addServiceBtn}
                        onClick={this.handleCreateService}
                    >
                      Add service
                    </Button>


              </div>}

          </Box>
          
      <Box style={billingStyle.mainBoxStyle}>
        <Box sx={billingStyle.topbarBox}>
            <TextField
                type="search"
                id="search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                    style: {
                        borderRadius: "1.875rem", height: "44px", padding: '10px 8px'
                        , display: "flex", justifyContent: "center", alignItems: "center"
                    },
                }}
                placeholder="Search"
                variant="outlined"
                style={{
                    width: "600px", borderRadius: "98px",


                }}
                value={this.state.searchInvoice}
                onChange={(searchedRows) => this.SearchInvoices(searchedRows)}

            />

            <Box sx={{ display: "flex" }}>
                <Button data-test-id="filter" style={billingStyle.filterBtn}><img src={filter1} style={billingStyle.filterIcon} alt="" /><Typography style={billingStyle.filterSort}>Filter</Typography></Button>
                <Button data-test-id="sort" onClick={this.showSort}style={billingStyle.sortBtn}><Sort style={billingStyle.sortIcon} /><Typography style={billingStyle.filterSort}>Sort</Typography></Button>
                {this.state.showSortOptions ?
              <Box sx={billingStyle.filterBox} >
                <Button style={billingStyle.filtrBtn} onClick={()=>this.handleSortBy("name")}
                  startIcon={this.getStartIcon()} data-test-id="sortname"
                ><Typography style={billingStyle.filterText}>Name</Typography></Button>
                <Button data-test-id="sortcreate" style={billingStyle.filtrBtn} onClick={()=>this.handleSortBy("created_at")} startIcon={this.getIcon()}><Typography style={billingStyle.filterText}>Date created</Typography></Button>
                
              </Box> : null}
            </Box>

        </Box>

        <Box sx={billingStyle.contentBoxStyle}>
            <Box style={{ height: "528", overflowY: "auto" }}>
              <Table>
            <TableHead>
        <TableRow
          style={{
            height: "2.43rem",
            borderRadius: " 0.375rem",
          }}
        >
          <TableCell />
          <TableCell
            style={billingStyle.fileHeading}
          >
            Invoices
          </TableCell>
          <TableCell
            style={billingStyle.fileHeading}
          >
            Invoice ID
          </TableCell>
          <TableCell
            style={billingStyle.fileHeading}
          >
            Date
          </TableCell>
          <TableCell
            style={billingStyle.fileHeading}
          >
            Amount
          </TableCell>
          <TableCell
            style={billingStyle.fileHeading}
          >
            Status
          </TableCell>
        </TableRow>
      </TableHead>
            {this.state.invoices.map((item: any, index: number) => (
                  <React.Fragment key={item?.id}>
                    <TableRow
                      style={billingStyle.tableRowContent}
                      key={item?.id}
                      id="gotoDetails"
                      onClick={()=>this.gotoInvoiceDetails(item.id)}
                    >
                      <TableCell style={{
                        width: "4%",
                        padding: "0px 10px "
                      }}>
                        <IconButton aria-label="expand row" size="small">
                          <img src={pdfDoc} style={{ width: '44px', height: '44px' }}></img>
                        </IconButton>
                      </TableCell>
                      <TableCell
                        data-test-id={`invoice-${index}`}
                        style={{
                          width: "25%",
                          padding: "18px 12px",
                          ...billingStyle.tableCell
                        }}
                      >
                        {item.attributes?.invoice_items[0]?.name}
                      </TableCell>
                      <TableCell
                        data-test-id={`invoice-${index}`}
                        style={{
                          width: "15%",
                          padding: "12px",
                          ...billingStyle.tableCell
                        }}
                      >
                        {item.attributes.invoice_number}
                      </TableCell>
                      <TableCell
                        data-test-id={`invoice-${index}`}
                        style={{
                          width: "25%",
                          padding: "12px",
                          ...billingStyle.tableCell
                        }}
                      >
                        {this.formatDates(item.attributes.invoice_date)}
                      </TableCell>
                      <TableCell
                        data-test-id={`invoice-${index}`}
                        style={{
                          width: "15%",
                          padding: "12px",
                          ...billingStyle.tableCell
                        }}
                      >
                        ${item.attributes.total_amount}
                      </TableCell>
                      <TableCell
                        data-test-id={`invoice-${index}`}
                        style={{
                          width: "30%",
                          padding: "12px",
                          ...billingStyle.tableCell,
                          color: "green"
                        }}
                      >
                        {item.attributes.status}
                      </TableCell>
                      <TableCell
                        data-test-id={`invoice-${index}`}
                        style={{
                          width: "10%",
                          padding: "12px",
                          ...billingStyle.tableCell
                        }}
                      >
                        <MoreVert id="showDialog" 
                        style={{cursor:"pointer"}}
                        onClick={(e) => {e.stopPropagation(); this.handleShowModal(e, item?.id)}} 
                        />

                      </TableCell>
                    </TableRow>
                    {this.state.selectedInvoiceId === item?.id && this.state.isVisibleModal && (
                      <div style={{
                        ...billingStyle.boxMOdal,
                        position: "absolute",
                        top: this.state.modalPosition.top + "px",
                        left: this.state.modalPosition.left + "px",
                      }}>
                        <Button style={billingStyle.btnModal} id="viewProfilebtn" onClick={()=>{this.getFile(item.attributes.invoice_pdf != null ?item.attributes.invoice_pdf.url:item.attributes.manual_payment_link.url,item.attributes.invoice_pdf != null ?item.attributes.invoice_pdf.filename:item.attributes.manual_payment_link.filename)}}> <img src={download} style={{ marginRight: "8px" }}></img><Typography style={billingStyle.btnmodalText}>Download file</Typography></Button>
                        <Button style={billingStyle.btnModal} id="deteteInvoice" onClick={()=>this.deleteInvoice(item.id)}><DeleteOutlineOutlined style={{ marginRight: "8px",width:"18px",height:"18px" }} /> <Typography style={billingStyle.btnmodalText}>Delete file</Typography></Button>
                      </div>
                    )}

                  </React.Fragment>
                ))}
                </Table>
            </Box>

            <Box style={{
                borderTop: '1px solid #E2E8F0', background: '#fff', height: "4rem", position: "absolute", bottom: 0,
                width: "100%", display: "flex", justifyContent: "center", flexDirection: "column"
            }}>
                <Box sx={billingStyle.paginationBox}>
                    <select value={this.state.dataPerPage} onChange={this.handleItemsPerPage} style={{border:"none",background:"#fff"}} data-test-id="selectDocPerPage"   >
                        {this.dataPerPageOptions.map((option:any) => (
                            <option key={option} value={option}>
                                {option} Per Page
                            </option>
                        ))}
                    </select>
                    <Pagination style={billingStyle.paginationStyle} size="small" count={Math.ceil(this.totalData.length / this.state.dataPerPage)}
                        onChange={this.PageChange} page={this.state.pageNumber} data-test-id="pagination" />
                    <Typography style={billingStyle.RightPagintnTxt}>{startIndex + 1} - {endIndex} of {this.totalData.length} results</Typography>
                </Box>
            </Box>

        </Box>

    </Box>
    <InvoiceTemplateForm
          openDialog={this.state.openInvoiceForm}
          headingText="Generate Invoice"
          btnOkText="Generate Invoice"
          btnCancelText="Cancel"
          handleOk={this.createInvoice}
          teamEmails={this.state.teamEmails}
          handleCancel={this.handleGenerateInvoice}
          templateList={this.state.InvoiceTemplateList}
          serviceList={this.state.serviceList}
          invoiceBilling={true}
        />

    <AddService
      openDialog={this.state.openServiceForm}
      headingText= "Add service"
      btnCancelText="Cancel"
      btnOkText= "Add"
      handleOk= {this.createService}
      handleCancel={this.handleCreateService}
      primaryColor="#E57727"
      secondaryColor="#E57727"
      handleType= {this.handleRecurringService}
      TypeSelect={this.state.recurring}
    />
    </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const billingStyle: any = {
  topbarBox: {
      "display": "flex",
      "width": "calc(100% - 60px)",
      maxHeight: "1066px",
      "padding": "16px 24px",

      "alignItems": "center",
      "borderRadius": "8px",
      "border": "0.5px solid  #94A3B8",
      "background": "#FFF",
      height: "44px",
      "zIndex": "2",
      justifyContent: "space-between"

  },
  boxMOdal: {
    display: "flex",
    width: "194px",
    padding: "5px",
    flexDirection: "column" as const,
    alignItems: "center" as const,
    gap: "5px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    position: "absolute" as const,
    right: '103px',
    top: '50%'
  },
  btnModal: {
    display: "flex",
    height: "36px",
    padding: "9px 20px 9px 16px",
    alignItems: "center" as const,
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: "#FFF",
    justifyContent: 'flex-start'
  },
  btnmodalText: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: 'none' as const
  },
  contentBoxStyle: {
      "display": "flex",
      "width": "calc(100% - 12px)",
      "height": "684px",
      paddingTop: '1rem',
      "flexShrink": "0",

      "borderRadius": "8px",
      "border": "0.5px solid  #94A3B8",
      "position": "relative", 
      "zIndex": "1",
      flexDirection: "column"
  },
  tableRowContent:{
    borderRadius: "0.375rem",
    borderBottom: "1px solid #E2E8F0",
    padding: "0.625rem",
    height: "1.5rem",
  },
  filtrBtn: {
    "display": "flex",
    "width": "150px",
    "padding": "10px 0px 10px 25px",
    "alignItems": "center",
    "gap": "8px",
    justifyContent: 'flex-start'
  },
  filterSort: {
      "color": "#64748B",
      "fontFamily": "Inter",
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": "700",
      "lineHeight": "24px",
      textTransform: "none"
  },
  filterBox: {
    "display": "flex",
    "width": "9.25rem",
    // "height": "26.125rem",
    "paddingTop": "0.75rem",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "0.625rem",
    "flexShrink": "0",
    // backgroundColor:"red",
    position: "absolute",
    top: "10rem",
    right: '0.625rem',
    "zIndex": "2",
    "background": "#FFFFFF",
    "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)"
  },
  semiContentBox:{
    "display": "flex",
    "height": "32px", 
    "padding": "20px 1154px 20px 30px",
    "justifyContent": "space-between",
    "alignItems": "center", "flexShrink": 0,
    "borderBottom": "1px solid #CBD5E1", 
    width: "calc(100vw - 193px)",
    position: "sticky"
  },
  filterText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "0.875rem",
    "fontStyle": "normal",
    "fontWeight": 400,
    "lineHeight": "1.375rem",
    textTransform: "none"
  },
  tableCell:{
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  filterBtn: {
      "display": "flex",
      "width": "95px",
      "height": "44px",
      "padding": "10px 12px 10px 10px",
      "justifyContent": "center",
      "alignItems": "flex-start",
      "gap": "8px",
      "borderRadius": "8px",

  },
  mainContentBox:{ 
    display: "flex",
    flexDirection: "column", 
    width: "calc(100vw - 193px)", 
    position: 'fixed',
    top: "0px", right: "60px",
    alignItems:"flex-start" ,
    justifyContent:'center',
    height:"100vh"
  },
  sortBtn: {
      "display": "flex",
      "width": "88px",
      "height": "44px",
      "padding": "10px 12px 10px 10px",
      "justifyContent": "center",
      "alignItems": "flex-start",
      "gap": "8px",
      "borderRadius": "8px"
  },
  paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: '0px 16px'
  },
  paginationStyle: {
      "display": "flex",

      "height": "32px",
      "padding": "5px 0px",
      "justifyContent": "center",
      "alignItems": "center",
      "gap": "4px",
      "flexShrink": "0",
      "borderRadius": "100px",
      "background": "#FFF",
   
  },
  filterIcon:{ width: "1.5rem", height: '1.5rem'},
  sortIcon:{ width: "1.5rem", height: '1.5rem', color: "#64748B" },
  btnBox:{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "12px", height: "44px" },
  topHeader:{ "color": "#0F172A", "fontFamily": "Inter", "fontSize": "24px", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "32px", "letterSpacing": "-0.12px" },
  addServiceBtn:{
    color: "#E57727",
    borderColor: "#E57727",
    background: "#F6F2EF",
    display: "flex",
    padding: "7px 10px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize:"16px",
    width:"134px",
    gap: "10px", borderRadius: "8px", textTransform: "none" as const
  },
  generateBtn:{
    "display": "flex", "height": "2.75rem",
     "padding": ".625rem 1rem",
    "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
    "borderRadius": ".5rem", "background": "#E57727"
  },
  mainBoxStyle:{
    width: "100%", height: '974px', display: 'flex',
    flexDirection: "column", gap: "20px", alignItems: 'center', padding: "20px 30px", "overflowY": "auto"
},
  generateBtnText:{ "color": "#FFF",
   "fontFamily": "Inter",
   "fontSize": "1rem",
   "fontStyle": "normal",
    "fontWeight": 700,
    "lineHeight": "1.5rem",
    textTransform: "none", },
  RightPagintnTxt:{
      "color": "#64748B",
      "textAlign": "right",
      "fontFamily": "Inter",
      "fontSize": "12px",
      "fontStyle": "normal",
      "fontWeight": "400",
      "lineHeight": "18px"
    },
  fileHeading: {
      "color": "#64748B",
      "fontFamily": "Inter",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "700",
      "lineHeight": "22px"
  },

}
// Customizable Area End
